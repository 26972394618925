import axios from "axios";
import { createBrowserHistory } from "history";

// import React, { Component } from "react";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { Button } from "@material-ui/core";

const token = localStorage.getItem("token");
const userrole = localStorage.getItem("userrole");

localStorage.setItem("peer_insurer", "peer0.insurer-net");
const peer = localStorage.getItem("peer");
const peer_insurer = "peer0.insurer-net";
const peers = localStorage.getItem("peers");
const factor_peer = "peer0.factor-net";
const userid = localStorage.getItem("user_id");
const LimaPeer = "peer1.trustee.trurep.testing.emulya.com";
const role = "trustee";
const saludaPeer = localStorage.getItem("peer");
const ChannelName = localStorage.getItem("ChannelName");
export const history = createBrowserHistory();

// export const API = axios.create({
// 	baseURL: process.env.react_app_base_url_lima + 'backendapi/',
// });
export const API = axios.create({
  baseURL: process.env.react_app_base_url + "backendapiumb/",
});

API.interceptors.response.use(
  (response) => {
    console.log("response", response);
    if (response.data.token == -1) {
      alert("Token expired, please login again!");
      window.location.href = "/";
    } else if (response.status == 204) {
      alert("204 No Content");
      return null;
    } else {
      return response;
    }
  },
  (error) => {
    console.log("error", JSON.stringify(error));

    if (!error.response) {
      console.log("Interceptor - Server is not running");
      // window.location.href = "/server-down";
    } else if (error.response.status === 401) {
      console.log(
        "Interceptor - 401 - Unauthorized: Token Invalid, please login again"
      );
      localStorage.clear();
      window.location.assign("/");
    } else if (error.response.status === 400) {
      console.log("Interceptor - 400" + error.response.data.messages);
      alert("Missing Arguments!");
      return null;
    } else if (error.response.status === 503) {
      console.log("Interceptor - 503" + error.response.data.messages);
      alert("503 service unavailable");
      return null;
    } else if (error.response.status === 504) {
      console.log("Interceptor - 504" + error.response.data.messages);
      alert("504 Gateway Timeout");
      return null;
    } else if (error.response.status === 422) {
      console.log("Interceptor - 422" + error.response.data.messages);
      return error;
    } else if (error.response.status === 404) {
      console.log("Interceptor - 404" + error.response.data.messages);
      return error;
    } else if (error.response.status === 500) {
      console.log("Interceptor - 500" + error.response.data.messages);
      return error;
    } else {
      return Promise.reject(error);
    }
  }
);

// API.interceptors.response.use(function (response) {
//     console.log("response", response)
//     if (response.data.token == -1) {
//         alert("Token expired, please login again!")
//         // alert("review your answer");
//         window.location.href = "/";
//     } else {
//         return response;
//     }
// }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     console.log(error.response.data);
//     console.log(error.response.status);
//     console.log(error.response.headers);
//     if (!error.response) {
//         console.log("Interceptor - Server is not running");
//         window.location.assign('/server-down/');
//         confirmAlert({
//             customUI: ({ onClose }) => {
//                 return (
//                     <div className='custom-ui'>
//                         <h1>Down for Maintenance</h1>
//                         <p>Website is temporarily unavailable due to planned maintenance.</p>
//                         <Button variant="outlined" color="primary" onClick={onClose}>Ok</Button>
//                     </div>
//                 );
//             },
//             afterClose: () => {
//                 window.location.assign('/server-down/');
//             }
//         });
//     } else if (error.response.status === 401) {
//         console.log("Interceptor - 401 - Unauthorized: Token Invalid, please login again");
//         alert("401 - Unauthorized: Token Invalid, please login again")
//         confirmAlert({
//             customUI: ({ onClose }) => {
//                 return (
//                     <div className='custom-ui'>
//                         <h1>Token Expired</h1>
//                         <p>Unauthorized: Token expired, please login again</p>
//                         <Button variant="outlined" color="primary" onClick={onClose}>Ok</Button>
//                     </div>
//                 );
//             },
//             afterClose: () => {
//                 window.location.assign('/login/');
//             }
//         });
//     } else if (error.response.status === 400) {
//         alert()
//         console.log("Interceptor - 400" + error.response.data.messages);
//     } else if (error.response.status === 404) {
//         console.log("API not Found");
//         alert("API not Found!");
//     } else if (error.response.status === 503) {
//         console.log("Interceptor - 503");
//         alert("Error 503!");
//     } else {
//         return Promise.reject(error);
//     }
// });

//User

// export const authenticate = async (data) => {
// 	const response = await API.post(process.env.react_app_base_url + 'authenticate', data)
// 	return response;
// };
//----------------------------------------------------------UMB--VERIFICATION----------------------------------------------------------------

export const authenticate = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/login?username=" +
      data.username +
      "&password=" +
      data.password
  );
  return response;
};

export const jwtToken = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/JWT_TOKEN",
    data
  );
  return response;
};
export const ForgotPassword = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/forgotpassword",
    data
  );
  return response;
};
export const Resetpassword = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapiumb/resetpassword?token=" +
      `${data.token}`,
    data
  );
  return response;
};
export const logout = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/logout",
    data,
    {
      headers: {
        authorization: `Bearer ${data.token}`,
        userrole: `${userrole}`,
      },
    }
  );
  return response;
};

export const addUser = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/register",
    data
  );
  return response;
};

export const updateUser = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/updateuser",
    data
  );
  return response;
};

export const addProcessor = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/addprocessor",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const addPool = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/addpool",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const getAllProcessors = async (token) => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapiumb/getallprocessor",
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};
export const getallpools = async (token) => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapiumb/getallpools",
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const uploadlms = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/uploadlms",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const savelms = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/savelms?poolname=" +
      data.poolname +
      "&poolid=" +
      data.poolid +
      "&filetype=" +
      data.filetype,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const getallpoolsbyid = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/getallpoolsbyid",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const viewLoans = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapiumb/viewloans?poolid=" + data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const GetDealId = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapiumb/getdealid?poolid=" + data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};
// Deal NAme Popup
export const getUniqueDealNames = async (token) => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapiumb/getUniqueDealNames",
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

//Download LMS Data
export const DownloadLMSData = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/downloadexcel",
    data,
    {
      responseType: "arraybuffer",
      headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` },
    }
  );
  return response;
};

// export const compareData = async (data,token) => {
// 	const response = await API.get(process.env.react_app_base_url + 'backendapiumb/comparedata?dealid=' +data, { headers: {"authorization" : `Bearer ${token}`} })
// 	return response;
// };

export const UpdateData = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/updatedatas",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const ReviewStatus = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/updatebothstatus?dealid=" +
      data.dealid +
      "&poolid=" +
      data.poolid +
      "&poolname=" +
      data.poolname,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const ContractStatus = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/updatecontractstatus?poolid=" +
      data +
      "&status=" +
      "Uploaded",
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const LmsStatus = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/updatelmsstatus?poolid=" +
      data +
      "&status=" +
      "Uploaded",
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const ExportExcel = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/exportexcel",
    data,
    {
      responseType: "arraybuffer",
      headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` },
    }
  );
  return response;
};

export const DownloadFile = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/downloadfile?filename=" +
      data,
    {
      headers: {
        authorization: `Bearer ${token}`,
        userrole: `${userrole}`,
        responseType: "arraybuffer",
      },
    }
  );
  return response;
};

// export const compareData = async (dealidlist,token) => {
// 	const response = await API.post('https://ai-engine.umbtest.intainabs.com/api/validate_loan',dealidlist)
// 	return response;
// };
// export const compareData = async (data) => {
// 	const response = await API.post('https://indumb.umbtest.intainabs.com/api/validate_loan',data)
// 	return response;
// };

// export const uploadcontract = async (data) => {
// 	const response = await API.post('https://indumb.umbtest.intainabs.com/api/upload_documents', data)
// 	return response;
// };

export const compareData = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiai/api/view_loans",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const uploadcontract = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiai/api/upload_documents",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};
export const editLoans = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiai/api/get_loans",
    data
  );
  return response;
};

//.................FIELDS....................//

export const AddAttribute = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/addAttribute",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const GetAllAttributes = async (token) => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapiumb/getAllAttributes",
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};
//-------------------MapProcessor-----------------------------//

export const MapProcessor = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/mapProcessor",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

//-------------------MapFields-----------------------------//

export const MapAttribute = async (data, token) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapiumb/poolAttributeMapping",
    data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

export const GetAllAttributesPoolId = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/getAttributesByPoolId?poolid=" +
      data.poolid +
      "&poolname=" +
      data.poolname,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

//--------------------------------zip----------------------------//

export const GetFileList = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/getFileListByDealName?poolname=" +
      data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};
export const deletePool = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapiumb/deletePool?poolid=" + data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};

//-------------------------------------------------UMB VERIFICATION----------222UCC1------------------------------------------------------------

export const GetAttributeDetail = async (data, token) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapiumb/getAttributeDetailsByPoolId?poolid=" +
      data,
    { headers: { authorization: `Bearer ${token}`, userrole: `${userrole}` } }
  );
  return response;
};
// getAllUsers

export const getAllUsers = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "getAllUsers?token=" +
      token +
      "&peer=" +
      peer
  );
  return response;
};

export const getAllUserRoles = async () => {
  const response = await API.get(
    process.env.react_app_base_url + "getAllUserRoles"
  );
  return response;
};

export const approveUser = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "approveUser",
    data
  );
  return response;
};

export const addApprovedUserDetails = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "addApprovedUserDetails",
    data
  );
  return response;
};

export const getUser = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "getUser?token=" +
      token +
      "&peer=" +
      peer +
      "&userId=" +
      userid
  );
  return response;
};

export const GetAllDeals = async (DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/getalldeals?token=" +
        token +
        "&peer=" +
        LimaPeer
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/getalldeals?token=" +
        token +
        "&peer=" +
        saludaPeer +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const GetAllDealsLogin = async (DealType, token, peer, ChannelName) => {
  let response = null;

  response = await API.get(
    process.env.react_app_base_url_lima +
      "api/v1/trustee/getalldeals?token=" +
      token +
      "&peer=" +
      peer +
      "&channelname=" +
      ChannelName
  );
  return response;
};

// Lima

export const ReportStatusStrat = async (
  DealType,
  dealId,
  month,
  year,
  groupby,
  OrgName
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/reportstatusLimaStrat?DealID=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&role=" +
        OrgName +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/reportstatusSaludagradeStrat?DealID=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&role=" +
        OrgName +
        "&peer=" +
        saludaPeer +
        "&token=" +
        token +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const ViewLoanTapeData = async (
  DealType,
  dealId,
  month,
  year,
  groupby,
  OrgName
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "groupby?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&role=" +
        OrgName +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "groupbySaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&role=" +
        OrgName +
        "&peer=" +
        saludaPeer +
        "&token=" +
        token +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

// initial-setup
export const InitialSetup = async (data, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/initialsetupLima",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/initialsetupSaludagrade",
      data
    );
    return response;
  }
};

// add-new-report
export const ServicerData = async (data, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/servicerdataLima",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/servicerdataSaludagrade",
      data
    );
    return response;
  }
};

export const UploadServicerReport = async (newdata, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/uploadservicerreportLima",
      newdata
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/uploadservicerreportSaludagrade",
      newdata
    );
    return response;
  }
};

export const ServicerDataAddNewReport1 = async (newdata, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/uploadservicerreportLima",
      newdata
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/servicerdataSaludagrade1",
      newdata
    );
    return response;
  }
};

export const ServicerDataAddNewReport2 = async (newdata, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/uploadservicerreportLima",
      newdata
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/servicerdataSaludagrade2",
      newdata
    );
    return response;
  }
};

export const ServicerDataAddNewReport3 = async (newdata, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/uploadservicerreportLima",
      newdata
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/servicerdataSaludagrade3",
      newdata
    );
    return response;
  }
};

export const ServicerDataAddNewReport4 = async (newdata, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/uploadservicerreportLima",
      newdata
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/servicerdataSaludagrade4",
      newdata
    );
    return response;
  }
};

// view-servicer-mongodb-data
export const ServicerDataMongodb = async (
  DealType,
  dealId,
  month,
  year,
  channelname
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/servicerdataLima?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/servicerdataSaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&channelname=" +
        channelname
    );
    //
    return response;
  }
};

// validate-monthly-inputs

export const ValidateMonthlyInput = async (
  DealType,
  dealId,
  month,
  year,
  channelname
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/updateuiLima?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/updateuiSaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&channelname=" +
        channelname
    );
    return response;
  }
};

export const UpdateValidateMonthlyInput = async (DealType, data) => {
  let response = null;
  if (DealType == "Lima") {
    // response = await API.get(process.env.react_app_base_url + 'api/v1/trustee/onboardservicerdataLima?dealId=' + dealId + '&month=' + month + '&year=' + year)
    // return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/onboardservicerdataSaludagrade",
      data
    );
    return response;
  }
};

// view-servicer-blockchain-data
export const ServicerDataBlockchain = async (DealType, dealId, month, year) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/onchainservicerdataLima?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&token=" +
        token +
        "&peer=" +
        peer
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/onchainservicerdataSaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&token=" +
        token +
        "&peer=" +
        peer +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

//

// api/v1/trustee/invreportresponseLima

export const CustomizeReportAPI = async (DealType, dealId, month, year) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/invreportresponseLima?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&token=" +
        token +
        "&peer=" +
        peer
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/invreportresponseSaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&token=" +
        token +
        "&peer=" +
        peer +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const SaveCustomizeReportAPI = async (DealType, data) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/savecustomreportLima",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url +
        "api/v1/trustee/savecustomreportSaludagrade",
      data
    );
    return response;
  }
};

export const GetCustomizationReport = async (DealType, dealId, month, year) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/viewcustomreportLima?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token +
        "&role=" +
        role +
        "&userid=" +
        userid
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/viewcustomreportSaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&peer=" +
        saludaPeer +
        "&token=" +
        token +
        "&role=" +
        role +
        "&userid=" +
        userid +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const InvestorList = async (DealType, dealId, month, year) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/getallinvestors?peer=" +
        LimaPeer +
        "&token=" +
        token
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/getallinvestorsSaludagrade?peer=" +
        saludaPeer +
        "&token=" +
        token +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const AdjustmentMonth = async (DealType, dealId, month, year) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/getadjustmentmonth?dealId=" +
        dealId +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token
    );
    return response;
  }
};

export const Prompt = async (DealType, dealId, month, year, channelname) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/prompt?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&token=" +
        token +
        "&peer=" +
        peer
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url +
        "api/v1/trustee/prompt?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&token=" +
        token +
        "&peer=" +
        peer +
        "&channelname=" +
        channelname
    );
    return response;
  }
};
export const EnableDisable = async (data, DealType, dealId, month, year) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/enabledisable",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/enabledisable",
      data
    );
    return response;
  }
};

export const GeneratePDF = async (data, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/enabledisable",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url + "wsfs/invreportpdf",
      data
    );
    return response;
  }
};

export const GenerateLoanStratPDF = async (data, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/enabledisable",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url + "wsfs/loanstratpdf",
      data
    );
    return response;
  }
};

export const GenerateReport = async (data, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/chaincodeLima",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/chaincodeSaludagrade",
      data
    );
    return response;
  }
};

// api/v1/trustee/publishSaludagrade

export const PublishReport = async (data, DealType) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/publishSaludagrade",
      data
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.post(
      process.env.react_app_base_url + "api/v1/trustee/publishSaludagrade",
      data
    );
    return response;
  }
};

export const InvestorVersion = async (
  DealType,
  dealId,
  month,
  year,
  groupby,
  OrgName
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/version?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/invreporthistorySaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&peer=" +
        saludaPeer +
        "&token=" +
        token +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const InvestorReportStatus = async (
  DealType,
  dealId,
  month,
  year,
  version,
  data
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/reportstatusLima?DealID=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&version=" +
        version +
        "&data=" +
        data +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/reportstatusSaludagrade?DealID=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&version=" +
        version +
        "&data=" +
        data +
        "&peer=" +
        saludaPeer +
        "&token=" +
        token +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const ViewInvestorReport = async (
  DealType,
  dealId,
  month,
  year,
  version,
  role,
  userid
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/viewinvestorreportLima?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&version=" +
        version +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token +
        "&role=" +
        role +
        "&userid=" +
        userid
    );
    return response;
  } else if (DealType == "trusteedeal") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/viewinvestorreportSaludagrade?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&version=" +
        version +
        "&peer=" +
        saludaPeer +
        "&token=" +
        token +
        "&role=" +
        role +
        "&userid=" +
        userid +
        "&channelname=" +
        ChannelName
    );
    return response;
  }
};

export const invreporthistoryLima = async (
  DealType,
  dealId,
  month,
  year,
  version,
  role,
  userid
) => {
  let response = null;
  if (DealType == "Lima") {
    response = await API.get(
      process.env.react_app_base_url_lima +
        "api/v1/trustee/invreporthistoryLima?dealId=" +
        dealId +
        "&month=" +
        month +
        "&year=" +
        year +
        "&version=" +
        version +
        "&peer=" +
        LimaPeer +
        "&token=" +
        token +
        "&role=" +
        role +
        "&userid=" +
        userid
    );
    return response;
  }
};

export const PlatformCreate = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "createplatform",
    data
  );
  return response;
};

export const months = [
  {
    value: "1",
    label: "January",
  },
  {
    value: "2",
    label: "February",
  },
  {
    value: "3",
    label: "March",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "June",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "August",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];

export function generateYears() {
  let min = 2010;
  console.log("min", min);
  let max = new Date().getFullYear();
  console.log("max", max);
  let years = [];
  for (var i = min; i <= max; i++) {
    console.log("asdasd", i);
    years.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  let reversarray = years.reverse();
  return reversarray;
}

export const GroupBySaludaGrade = [
  {
    value: "Modificationdate",
    label: "Modification Date",
  },
  {
    value: "delinquencyStatus",
    label: "Deliquency Status",
  },
  {
    value: "PIF",
    label: "PIF",
  },
  {
    value: "BorrowerState",
    label: "Borrower State",
  },
  {
    value: "FirstLien",
    label: "First Lien",
  },
  {
    value: "PrincipalBalance",
    label: "Principal Balance",
  },
  {
    value: "PostLoanCLTV",
    label: "Post Loan CLTV",
  },
  {
    value: "CurrentRate",
    label: "Current Rate",
  },
  {
    value: "PropertyState",
    label: "Property State",
  },
  {
    value: "LoanPurpose",
    label: "Loan Purpose",
  },
  {
    value: "RemainingTermMonths",
    label: "Remaining Term Months",
  },
  {
    value: "PostLoanDTI",
    label: "Post Loan DTI",
  },
  {
    value: "FicoScore",
    label: "FICO Score",
  },
];

export const GroupByLima = [
  {
    value: "City",
    label: "City",
  },
  {
    value: "State",
    label: "State",
  },
  {
    value: "Loan Type",
    label: "Loan Type",
  },
  {
    value: "Current Maturity Date",
    label: "Current Maturity Date",
  },
  {
    value: "Loan Extended",
    label: "Loan Extended",
  },
  {
    value: "Remaining Term",
    label: "Remaining Term",
  },
  {
    value: "Investor Rate",
    label: "Investor Rate",
  },
  {
    value: "Ending Principal Balance",
    label: "Ending Principal Balance",
  },
  {
    value: "Delinquency Status",
    label: "Delinquency Status",
  },
];

export const LimaIndexData = [
  {
    title: "Delinquency Status",
    order_id: "1",
    anchore_link: "delinquencystatus",
  },
  { title: "PIF", order_id: "1", anchore_link: "pif" },
  { title: "State", order_id: "1", anchore_link: "state" },
  { title: "Loan Type", order_id: "1", anchore_link: "loantype" },
  {
    title: "Current Maturity Date",
    order_id: "1",
    anchore_link: "currentmaturitydate",
  },
  { title: "Loan Extended", order_id: "1", anchore_link: "loanextended" },
  { title: "Remaining Term", order_id: "1", anchore_link: "remainingterm" },
  { title: "Investor Rate", order_id: "1", anchore_link: "investorrate" },
  {
    title: "Ending Principle Balance",
    order_id: "1",
    anchore_link: "endingprincipalbalance",
  },
  { title: "City", order_id: "1", anchore_link: "city" },
  { title: "Definitions", order_id: "1", anchore_link: "definitions" },
];

export const SaludaIndexData = [
  { title: "borrower_state", order_id: "1", anchore_link: "borrower_state" },
  { title: "first_lien", order_id: "1", anchore_link: "first_lien" },
  { title: "property_state", order_id: "1", anchore_link: "property_state" },
  { title: "loan_purpose", order_id: "1", anchore_link: "loan_purpose" },
  { title: "current_rate", order_id: "1", anchore_link: "current_rate" },
  {
    title: "principal_balance",
    order_id: "1",
    anchore_link: "principal_balance",
  },
  { title: "post_loan_cltv", order_id: "1", anchore_link: "post_loan_cltv" },
  {
    title: "remaining_term_months",
    order_id: "1",
    anchore_link: "remaining_term_months",
  },
  { title: "post_loan_dti", order_id: "1", anchore_link: "post_loan_dti" },
  { title: "fico_score", order_id: "1", anchore_link: "fico_score" },
];

export const loanstraitdata = {
  DealContactInformation: {
    dealid: "ldeal10",
    distributiondate: "7/25/2020",
    reporttype: "Monthly Paying Agent",
    relationshipmanager: "Devon Almeida",
    address: "500 Delaware Ave, 11th Floor, Wilmington, DE 19801",
    email: "dalmeida@wsfsbank.com",
    websitereporting: "www.wsfsabsreporting.com",
    addtionalfirst: " ",
    additionallast: " ",
  },
  DelinquencyStatus: [
    {
      "Delinquency Status": "0 - 30",
      Count: 450,
      "$ Aggregate": "79154497.05",
      "% Aggregate": "99.08",
      "$ Average": "175898.88",
      "% Interest Rate": "7.26",
    },
    {
      "Delinquency Status": "30 - 60",
      Count: 3,
      "$ Aggregate": "734917.31",
      "% Aggregate": "0.92",
      "$ Average": "244972.44",
      "% Interest Rate": "8.70",
    },
    {
      "Delinquency Status": "Total:",
      Count: "453",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "420871.32",
      "% Interest Rate": "15.97",
    },
  ],
  PIF: [
    {
      "Loan ID": "107902",
      "Unpaid Principal Balance": "143470",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108254",
      "Unpaid Principal Balance": "168021.9",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103922",
      "Unpaid Principal Balance": "221177.13",
      "Pre-Modification": "10/12/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108256",
      "Unpaid Principal Balance": "166221.9",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105675",
      "Unpaid Principal Balance": "67600",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108332",
      "Unpaid Principal Balance": "108000",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "50477",
      "Unpaid Principal Balance": "227903",
      "Pre-Modification": "01/08/2018",
      "Post-Modification": "01/05/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104327",
      "Unpaid Principal Balance": "141350",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108443",
      "Unpaid Principal Balance": "74400",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108516",
      "Unpaid Principal Balance": "45600",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102292",
      "Unpaid Principal Balance": "226000",
      "Pre-Modification": "10/01/2021",
      "Post-Modification": "01/01/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107898",
      "Unpaid Principal Balance": "121075",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108524",
      "Unpaid Principal Balance": "44000",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107911",
      "Unpaid Principal Balance": "240000",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109043",
      "Unpaid Principal Balance": "158416.52",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109035",
      "Unpaid Principal Balance": "169821.9",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103055",
      "Unpaid Principal Balance": "48600",
      "Pre-Modification": "10/11/2020",
      "Post-Modification": "01/11/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107899",
      "Unpaid Principal Balance": "143420",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108255",
      "Unpaid Principal Balance": "170721.9",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103940",
      "Unpaid Principal Balance": "301387.1",
      "Pre-Modification": "10/12/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107913",
      "Unpaid Principal Balance": "167000",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108886",
      "Unpaid Principal Balance": "78159.2",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107900",
      "Unpaid Principal Balance": "143420",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108217",
      "Unpaid Principal Balance": "168654.59",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108523",
      "Unpaid Principal Balance": "60800",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108266",
      "Unpaid Principal Balance": "110201.4",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108475",
      "Unpaid Principal Balance": "108675",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108528",
      "Unpaid Principal Balance": "58942.4",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "101707",
      "Unpaid Principal Balance": "196550.77",
      "Pre-Modification": "10/10/2020",
      "Post-Modification": "01/10/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108358",
      "Unpaid Principal Balance": "184800",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107969",
      "Unpaid Principal Balance": "129475",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107833",
      "Unpaid Principal Balance": "200000",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108025",
      "Unpaid Principal Balance": "129475",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
  ],
  State: [
    {
      State: "PA",
      Count: 43,
      "$ Aggregate": "7426913.47",
      "% Aggregate": "9.30",
      "$ Average": "172718.92",
      "% Interest Rate": "6.97",
    },
    {
      State: "MO",
      Count: 9,
      "$ Aggregate": "816407.63",
      "% Aggregate": "1.02",
      "$ Average": "90711.96",
      "% Interest Rate": "7.21",
    },
    {
      State: "NC",
      Count: 26,
      "$ Aggregate": "5368344.10",
      "% Aggregate": "6.72",
      "$ Average": "206474.77",
      "% Interest Rate": "6.54",
    },
    {
      State: "IN",
      Count: 33,
      "$ Aggregate": "3177133.84",
      "% Aggregate": "3.98",
      "$ Average": "96276.78",
      "% Interest Rate": "7.56",
    },
    {
      State: "OH",
      Count: 44,
      "$ Aggregate": "4468670.81",
      "% Aggregate": "5.59",
      "$ Average": "101560.70",
      "% Interest Rate": "8.01",
    },
    {
      State: "WA",
      Count: 1,
      "$ Aggregate": "243308.09",
      "% Aggregate": "0.30",
      "$ Average": "243308.09",
      "% Interest Rate": "6.00",
    },
    {
      State: "MI",
      Count: 15,
      "$ Aggregate": "2759173.46",
      "% Aggregate": "3.45",
      "$ Average": "183944.90",
      "% Interest Rate": "6.60",
    },
    {
      State: "IL",
      Count: 29,
      "$ Aggregate": "3831579.72",
      "% Aggregate": "4.80",
      "$ Average": "132123.44",
      "% Interest Rate": "9.04",
    },
    {
      State: "SC",
      Count: 28,
      "$ Aggregate": "6776012.59",
      "% Aggregate": "8.48",
      "$ Average": "242000.45",
      "% Interest Rate": "6.44",
    },
    {
      State: "TX",
      Count: 14,
      "$ Aggregate": "1737258.36",
      "% Aggregate": "2.17",
      "$ Average": "124089.88",
      "% Interest Rate": "8.58",
    },
    {
      State: "NY",
      Count: 8,
      "$ Aggregate": "903081.55",
      "% Aggregate": "1.13",
      "$ Average": "112885.19",
      "% Interest Rate": "8.18",
    },
    {
      State: "FL",
      Count: 63,
      "$ Aggregate": "8809497.90",
      "% Aggregate": "11.03",
      "$ Average": "139833.30",
      "% Interest Rate": "7.67",
    },
    {
      State: "MD",
      Count: 32,
      "$ Aggregate": "7041022.67",
      "% Aggregate": "8.81",
      "$ Average": "220031.96",
      "% Interest Rate": "6.15",
    },
    {
      State: "NJ",
      Count: 23,
      "$ Aggregate": "3482691.79",
      "% Aggregate": "4.36",
      "$ Average": "151421.38",
      "% Interest Rate": "8.10",
    },
    {
      State: "AR",
      Count: 1,
      "$ Aggregate": "41818.50",
      "% Aggregate": "0.05",
      "$ Average": "41818.50",
      "% Interest Rate": "10.00",
    },
    {
      State: "VA",
      Count: 14,
      "$ Aggregate": "1661579.85",
      "% Aggregate": "2.08",
      "$ Average": "118684.28",
      "% Interest Rate": "8.98",
    },
    {
      State: "MS",
      Count: 4,
      "$ Aggregate": "394531.33",
      "% Aggregate": "0.49",
      "$ Average": "98632.83",
      "% Interest Rate": "6.12",
    },
    {
      State: "GA",
      Count: 41,
      "$ Aggregate": "8366120.91",
      "% Aggregate": "10.47",
      "$ Average": "204051.73",
      "% Interest Rate": "7.31",
    },
    {
      State: "CA",
      Count: 6,
      "$ Aggregate": "2250806.27",
      "% Aggregate": "2.82",
      "$ Average": "375134.38",
      "% Interest Rate": "7.27",
    },
    {
      State: "NM",
      Count: 2,
      "$ Aggregate": "219134.32",
      "% Aggregate": "0.27",
      "$ Average": "109567.16",
      "% Interest Rate": "11.27",
    },
    {
      State: "TN",
      Count: 7,
      "$ Aggregate": "1952859.13",
      "% Aggregate": "2.44",
      "$ Average": "278979.88",
      "% Interest Rate": "5.86",
    },
    {
      State: "N",
      Count: 5,
      "$ Aggregate": "508554.00",
      "% Aggregate": "0.64",
      "$ Average": "101710.80",
      "% Interest Rate": "9.12",
    },
    {
      State: "DC",
      Count: 2,
      "$ Aggregate": "1510425.00",
      "% Aggregate": "1.89",
      "$ Average": "755212.50",
      "% Interest Rate": "6.85",
    },
    {
      State: "CT",
      Count: 2,
      "$ Aggregate": "210741.97",
      "% Aggregate": "0.26",
      "$ Average": "105370.99",
      "% Interest Rate": "8.74",
    },
    {
      State: "KY",
      Count: 2,
      "$ Aggregate": "150788.36",
      "% Aggregate": "0.19",
      "$ Average": "75394.18",
      "% Interest Rate": "8.17",
    },
    {
      State: "us",
      Count: 1,
      "$ Aggregate": "255710.74",
      "% Aggregate": "0.32",
      "$ Average": "255710.74",
      "% Interest Rate": "4.75",
    },
    {
      State: "OK",
      Count: 3,
      "$ Aggregate": "299787.18",
      "% Aggregate": "0.38",
      "$ Average": "99929.06",
      "% Interest Rate": "10.08",
    },
    {
      State: "WI",
      Count: 7,
      "$ Aggregate": "377064.96",
      "% Aggregate": "0.47",
      "$ Average": "53866.42",
      "% Interest Rate": "8.06",
    },
    {
      State: "LA",
      Count: 5,
      "$ Aggregate": "467474.88",
      "% Aggregate": "0.59",
      "$ Average": "93494.98",
      "% Interest Rate": "9.43",
    },
    {
      State: "an",
      Count: 1,
      "$ Aggregate": "943580.00",
      "% Aggregate": "1.18",
      "$ Average": "943580.00",
      "% Interest Rate": "6.50",
    },
    {
      State: "AL",
      Count: 4,
      "$ Aggregate": "656392.50",
      "% Aggregate": "0.82",
      "$ Average": "164098.13",
      "% Interest Rate": "9.35",
    },
    {
      State: "AZ",
      Count: 2,
      "$ Aggregate": "334645.91",
      "% Aggregate": "0.42",
      "$ Average": "167322.96",
      "% Interest Rate": "6.74",
    },
    {
      State: "DE",
      Count: 3,
      "$ Aggregate": "315727.62",
      "% Aggregate": "0.40",
      "$ Average": "105242.54",
      "% Interest Rate": "7.17",
    },
    {
      State: "MN",
      Count: 2,
      "$ Aggregate": "374319.24",
      "% Aggregate": "0.47",
      "$ Average": "187159.62",
      "% Interest Rate": "6.68",
    },
    {
      State: "NV",
      Count: 1,
      "$ Aggregate": "1597000.00",
      "% Aggregate": "2.00",
      "$ Average": "1597000.00",
      "% Interest Rate": "6.25",
    },
    {
      State: "KS",
      Count: 2,
      "$ Aggregate": "100000.00",
      "% Aggregate": "0.13",
      "$ Average": "50000.00",
      "% Interest Rate": "8.25",
    },
    {
      State: "IA",
      Count: 1,
      "$ Aggregate": "59255.71",
      "% Aggregate": "0.07",
      "$ Average": "59255.71",
      "% Interest Rate": "6.55",
    },
    {
      State: "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "8258599.11",
      "% Interest Rate": "282.58",
    },
  ],
  LoanType: [
    {
      "Loan Type": "Fully Escrow",
      Count: 101,
      "$ Aggregate": "13581310.34",
      "% Aggregate": "17.00",
      "$ Average": "134468.42",
      "% Interest Rate": "10.37",
    },
    {
      "Loan Type": "Fixed R30",
      Count: 71,
      "$ Aggregate": "8862400.46",
      "% Aggregate": "11.09",
      "$ Average": "124822.54",
      "% Interest Rate": "6.16",
    },
    {
      "Loan Type": "5/1 R30",
      Count: 52,
      "$ Aggregate": "7090002.52",
      "% Aggregate": "8.87",
      "$ Average": "136346.20",
      "% Interest Rate": "5.60",
    },
    {
      "Loan Type": "10/1 R30",
      Count: 25,
      "$ Aggregate": "4269438.67",
      "% Aggregate": "5.34",
      "$ Average": "170777.55",
      "% Interest Rate": "5.80",
    },
    {
      "Loan Type": "Commit NF",
      Count: 77,
      "$ Aggregate": "11414969.82",
      "% Aggregate": "14.29",
      "$ Average": "148246.36",
      "% Interest Rate": "8.10",
    },
    {
      "Loan Type": "Commit NC NF",
      Count: 65,
      "$ Aggregate": "5485351.84",
      "% Aggregate": "6.87",
      "$ Average": "84390.03",
      "% Interest Rate": "8.40",
    },
    {
      "Loan Type": "Bridge+ PO",
      Count: 47,
      "$ Aggregate": "4896753.90",
      "% Aggregate": "6.13",
      "$ Average": "104186.25",
      "% Interest Rate": "7.70",
    },
    {
      "Loan Type": "FNF Cash Out",
      Count: 5,
      "$ Aggregate": "150000.00",
      "% Aggregate": "0.19",
      "$ Average": "30000.00",
      "% Interest Rate": "8.18",
    },
    {
      "Loan Type": "F2R Commit NF",
      Count: 5,
      "$ Aggregate": "298041.50",
      "% Aggregate": "0.37",
      "$ Average": "59608.30",
      "% Interest Rate": "9.06",
    },
    {
      "Loan Type": "Commit",
      Count: 3,
      "$ Aggregate": "971826.50",
      "% Aggregate": "1.22",
      "$ Average": "323942.17",
      "% Interest Rate": "8.10",
    },
    {
      "Loan Type": "2+1",
      Count: 12,
      "$ Aggregate": "2166600.00",
      "% Aggregate": "2.71",
      "$ Average": "180550.00",
      "% Interest Rate": "7.74",
    },
    {
      "Loan Type": "Fixed R30 Prem",
      Count: 11,
      "$ Aggregate": "15128286.32",
      "% Aggregate": "18.94",
      "$ Average": "1375298.76",
      "% Interest Rate": "5.00",
    },
    {
      "Loan Type": "Fully Escrow NC",
      Count: 5,
      "$ Aggregate": "1258782.18",
      "% Aggregate": "1.58",
      "$ Average": "251756.44",
      "% Interest Rate": "10.43",
    },
    {
      "Loan Type": "Commit MF NF",
      Count: 3,
      "$ Aggregate": "3893407.77",
      "% Aggregate": "4.87",
      "$ Average": "1297802.59",
      "% Interest Rate": "6.49",
    },
    {
      "Loan Type": "F2R Fixed R30",
      Count: 2,
      "$ Aggregate": "181078.54",
      "% Aggregate": "0.23",
      "$ Average": "90539.27",
      "% Interest Rate": "6.35",
    },
    {
      "Loan Type": "F2R 10/1 R30",
      Count: 1,
      "$ Aggregate": "108509.48",
      "% Aggregate": "0.14",
      "$ Average": "108509.48",
      "% Interest Rate": "5.28",
    },
    {
      "Loan Type": "CRL Fixed R30",
      Count: 1,
      "$ Aggregate": "132654.52",
      "% Aggregate": "0.17",
      "$ Average": "132654.52",
      "% Interest Rate": "6.25",
    },
    {
      "Loan Type": "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "4753898.88",
      "% Interest Rate": "125.00",
    },
  ],
  CurrentMaturityDate: [
    {
      "Current Maturity Date": 2021,
      Count: 263,
      "$ Aggregate": "30948232.92",
      "% Aggregate": "38.74",
      "$ Average": "117673.89",
      "% Interest Rate": "8.75",
    },
    {
      "Current Maturity Date": 2050,
      Count: 158,
      "$ Aggregate": "33689870.11",
      "% Aggregate": "42.17",
      "$ Average": "213227.03",
      "% Interest Rate": "5.51",
    },
    {
      "Current Maturity Date": 2020,
      Count: 49,
      "$ Aggregate": "7576074.06",
      "% Aggregate": "9.48",
      "$ Average": "154613.76",
      "% Interest Rate": "9.82",
    },
    {
      "Current Maturity Date": 2022,
      Count: 14,
      "$ Aggregate": "5927707.77",
      "% Aggregate": "7.42",
      "$ Average": "423407.70",
      "% Interest Rate": "6.88",
    },
    {
      "Current Maturity Date": 2019,
      Count: 1,
      "$ Aggregate": "89995.50",
      "% Aggregate": "0.11",
      "$ Average": "89995.50",
      "% Interest Rate": "10.50",
    },
    {
      "Current Maturity Date": 2030,
      Count: 1,
      "$ Aggregate": "1657534.00",
      "% Aggregate": "2.07",
      "$ Average": "1657534.00",
      "% Interest Rate": "5.50",
    },
    {
      "Current Maturity Date": "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "2656451.88",
      "% Interest Rate": "46.95",
    },
  ],
  LoanExtended: [
    {
      "Loan Extended": "No",
      Count: 379,
      "$ Aggregate": "48218421.81",
      "% Aggregate": "60.36",
      "$ Average": "127225.39",
      "% Interest Rate": "8.13",
    },
    {
      "Loan Extended": "Yes",
      Count: 107,
      "$ Aggregate": "31670992.55",
      "% Aggregate": "39.64",
      "$ Average": "295990.58",
      "% Interest Rate": "5.98",
    },
    {
      "Loan Extended": "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "423215.97",
      "% Interest Rate": "14.11",
    },
  ],
  RemainingTerm: [
    {
      "Remaining Term months": "< 0",
      Count: 5,
      "$ Aggregate": "862008.75",
      "% Aggregate": "1.08",
      "$ Average": "172401.75",
      "% Interest Rate": "10.00",
    },
    {
      "Remaining Term months": "0 - 100",
      Count: 323,
      "$ Aggregate": "43680001.50",
      "% Aggregate": "54.68",
      "$ Average": "135232.20",
      "% Interest Rate": "8.66",
    },
    {
      "Remaining Term months": "100 - 200",
      Count: 1,
      "$ Aggregate": "1657534.00",
      "% Aggregate": "2.07",
      "$ Average": "1657534.00",
      "% Interest Rate": "5.50",
    },
    {
      "Remaining Term months": "300 - 400",
      Count: 157,
      "$ Aggregate": "33689870.11",
      "% Aggregate": "42.17",
      "$ Average": "214585.16",
      "% Interest Rate": "5.51",
    },
    {
      "Remaining Term months": "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "2179753.11",
      "% Interest Rate": "29.67",
    },
  ],
  InvestorRate: [
    {
      "Investor Rate": "0 - 10",
      Count: 486,
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "164381.51",
      "% Interest Rate": "7.28",
    },
    {
      "Investor Rate": "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "164381.51",
      "% Interest Rate": "7.28",
    },
  ],
  EndingPrincipalBalance: [
    {
      "Ending Principal Balance": "< 0",
      Count: 9,
      "$ Aggregate": "-133187.65",
      "% Aggregate": "0.17",
      "$ Average": "-14798.63",
      "% Interest Rate": "8.22",
    },
    {
      "Ending Principal Balance": "0 - 10000",
      Count: 39,
      "$ Aggregate": "36243.69",
      "% Aggregate": "0.05",
      "$ Average": "929.33",
      "% Interest Rate": "8.50",
    },
    {
      "Ending Principal Balance": "10000 - 50000",
      Count: 26,
      "$ Aggregate": "983145.48",
      "% Aggregate": "1.23",
      "$ Average": "37813.29",
      "% Interest Rate": "8.60",
    },
    {
      "Ending Principal Balance": "50000 - 100000",
      Count: 156,
      "$ Aggregate": "11608127.03",
      "% Aggregate": "14.53",
      "$ Average": "74411.07",
      "% Interest Rate": "7.89",
    },
    {
      "Ending Principal Balance": "100000 - 500000",
      Count: 235,
      "$ Aggregate": "42143326.10",
      "% Aggregate": "52.75",
      "$ Average": "179333.30",
      "% Interest Rate": "7.95",
    },
    {
      "Ending Principal Balance": "500000 - 1000000",
      Count: 12,
      "$ Aggregate": "8507424.19",
      "% Aggregate": "10.65",
      "$ Average": "708952.02",
      "% Interest Rate": "6.78",
    },
    {
      "Ending Principal Balance": "1000000 +",
      Count: 9,
      "$ Aggregate": "16744335.52",
      "% Aggregate": "20.96",
      "$ Average": "1860481.72",
      "% Interest Rate": "5.35",
    },
    {
      "Ending Principal Balance": "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "2847122.10",
      "% Interest Rate": "53.28",
    },
  ],
  City: [
    {
      City: "Philadelphia",
      Count: 29,
      "$ Aggregate": "5527294.15",
      "% Aggregate": "6.92",
      "$ Average": "190596.35",
      "% Interest Rate": "6.74",
    },
    {
      City: "Belton",
      Count: 1,
      "$ Aggregate": "61216.01",
      "% Aggregate": "0.08",
      "$ Average": "61216.01",
      "% Interest Rate": "5.80",
    },
    {
      City: "Dallas",
      Count: 2,
      "$ Aggregate": "530720.50",
      "% Aggregate": "0.66",
      "$ Average": "265360.25",
      "% Interest Rate": "7.24",
    },
    {
      City: "Anderson",
      Count: 2,
      "$ Aggregate": "189530.22",
      "% Aggregate": "0.24",
      "$ Average": "94765.11",
      "% Interest Rate": "9.77",
    },
    {
      City: "Bedford",
      Count: 1,
      "$ Aggregate": "61444.62",
      "% Aggregate": "0.08",
      "$ Average": "61444.62",
      "% Interest Rate": "6.38",
    },
    {
      City: "Bremerton",
      Count: 1,
      "$ Aggregate": "243308.09",
      "% Aggregate": "0.30",
      "$ Average": "243308.09",
      "% Interest Rate": "6.00",
    },
    {
      City: "Cleveland Heights",
      Count: 1,
      "$ Aggregate": "131629.50",
      "% Aggregate": "0.16",
      "$ Average": "131629.50",
      "% Interest Rate": "10.00",
    },
    {
      City: "Pontiac",
      Count: 2,
      "$ Aggregate": "262713.98",
      "% Aggregate": "0.33",
      "$ Average": "131356.99",
      "% Interest Rate": "6.39",
    },
    {
      City: "Columbus",
      Count: 22,
      "$ Aggregate": "1865344.72",
      "% Aggregate": "2.33",
      "$ Average": "84788.40",
      "% Interest Rate": "7.94",
    },
    {
      City: "Chicago",
      Count: 19,
      "$ Aggregate": "2659461.71",
      "% Aggregate": "3.33",
      "$ Average": "139971.67",
      "% Interest Rate": "9.17",
    },
    {
      City: "Inman",
      Count: 2,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "North Charleston",
      Count: 1,
      "$ Aggregate": "90225.00",
      "% Aggregate": "0.11",
      "$ Average": "90225.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Fairborn",
      Count: 2,
      "$ Aggregate": "135167.48",
      "% Aggregate": "0.17",
      "$ Average": "67583.74",
      "% Interest Rate": "8.20",
    },
    {
      City: "South Bend",
      Count: 2,
      "$ Aggregate": "158608.10",
      "% Aggregate": "0.20",
      "$ Average": "79304.05",
      "% Interest Rate": "5.92",
    },
    {
      City: "Angleton",
      Count: 1,
      "$ Aggregate": "142787.99",
      "% Aggregate": "0.18",
      "$ Average": "142787.99",
      "% Interest Rate": "10.00",
    },
    {
      City: "Peekskill",
      Count: 1,
      "$ Aggregate": "267996.89",
      "% Aggregate": "0.34",
      "$ Average": "267996.89",
      "% Interest Rate": "6.38",
    },
    {
      City: "Kiamesha Lake",
      Count: 1,
      "$ Aggregate": "128216.70",
      "% Aggregate": "0.16",
      "$ Average": "128216.70",
      "% Interest Rate": "10.00",
    },
    {
      City: "Palm Bay",
      Count: 7,
      "$ Aggregate": "189701.00",
      "% Aggregate": "0.24",
      "$ Average": "27100.14",
      "% Interest Rate": "8.49",
    },
    {
      City: "Fort Myers",
      Count: 6,
      "$ Aggregate": "806193.00",
      "% Aggregate": "1.01",
      "$ Average": "134365.50",
      "% Interest Rate": "8.43",
    },
    {
      City: "VERO BEACH",
      Count: 1,
      "$ Aggregate": "284000.00",
      "% Aggregate": "0.36",
      "$ Average": "284000.00",
      "% Interest Rate": "6.38",
    },
    {
      City: "Baltimore",
      Count: 28,
      "$ Aggregate": "4706468.00",
      "% Aggregate": "5.89",
      "$ Average": "168088.14",
      "% Interest Rate": "6.42",
    },
    {
      City: "Woodbury",
      Count: 1,
      "$ Aggregate": "66298.37",
      "% Aggregate": "0.08",
      "$ Average": "66298.37",
      "% Interest Rate": "5.43",
    },
    {
      City: "Jacksonville",
      Count: 5,
      "$ Aggregate": "583822.07",
      "% Aggregate": "0.73",
      "$ Average": "116764.41",
      "% Interest Rate": "7.68",
    },
    {
      City: "Raleigh",
      Count: 2,
      "$ Aggregate": "462213.84",
      "% Aggregate": "0.58",
      "$ Average": "231106.92",
      "% Interest Rate": "6.18",
    },
    {
      City: "Indianapolis",
      Count: 19,
      "$ Aggregate": "1667679.92",
      "% Aggregate": "2.09",
      "$ Average": "87772.63",
      "% Interest Rate": "7.76",
    },
    {
      City: "Petersburg",
      Count: 1,
      "$ Aggregate": "93798.80",
      "% Aggregate": "0.12",
      "$ Average": "93798.80",
      "% Interest Rate": "10.00",
    },
    {
      City: "Jackson",
      Count: 1,
      "$ Aggregate": "82186.93",
      "% Aggregate": "0.10",
      "$ Average": "82186.93",
      "% Interest Rate": "6.95",
    },
    {
      City: "Euclid",
      Count: 1,
      "$ Aggregate": "180364.46",
      "% Aggregate": "0.23",
      "$ Average": "180364.46",
      "% Interest Rate": "5.15",
    },
    {
      City: "Toledo",
      Count: 1,
      "$ Aggregate": "63632.17",
      "% Aggregate": "0.08",
      "$ Average": "63632.17",
      "% Interest Rate": "5.90",
    },
    {
      City: "Picatinny Arsenal",
      Count: 1,
      "$ Aggregate": "775050.00",
      "% Aggregate": "0.97",
      "$ Average": "775050.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Cordova",
      Count: 2,
      "$ Aggregate": "121948.29",
      "% Aggregate": "0.15",
      "$ Average": "60974.14",
      "% Interest Rate": "7.46",
    },
    {
      City: "Cayce",
      Count: 1,
      "$ Aggregate": "87345.00",
      "% Aggregate": "0.11",
      "$ Average": "87345.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Newark",
      Count: 3,
      "$ Aggregate": "497160.97",
      "% Aggregate": "0.62",
      "$ Average": "165720.32",
      "% Interest Rate": "8.83",
    },
    {
      City: "Port St. Lucie",
      Count: 6,
      "$ Aggregate": "269275.50",
      "% Aggregate": "0.34",
      "$ Average": "44879.25",
      "% Interest Rate": "8.25",
    },
    {
      City: "Lansing",
      Count: 1,
      "$ Aggregate": "266235.10",
      "% Aggregate": "0.33",
      "$ Average": "266235.10",
      "% Interest Rate": "5.67",
    },
    {
      City: "Tinley Park",
      Count: 1,
      "$ Aggregate": "52391.46",
      "% Aggregate": "0.07",
      "$ Average": "52391.46",
      "% Interest Rate": "7.40",
    },
    {
      City: "Akron",
      Count: 2,
      "$ Aggregate": "314124.51",
      "% Aggregate": "0.39",
      "$ Average": "157062.26",
      "% Interest Rate": "9.03",
    },
    {
      City: "East Dublin",
      Count: 1,
      "$ Aggregate": "46399.50",
      "% Aggregate": "0.06",
      "$ Average": "46399.50",
      "% Interest Rate": "8.75",
    },
    {
      City: "Hampton",
      Count: 1,
      "$ Aggregate": "146998.75",
      "% Aggregate": "0.18",
      "$ Average": "146998.75",
      "% Interest Rate": "10.00",
    },
    {
      City: "Alpharetta",
      Count: 2,
      "$ Aggregate": "1135523.86",
      "% Aggregate": "1.42",
      "$ Average": "567761.93",
      "% Interest Rate": "9.68",
    },
    {
      City: "Dolton",
      Count: 1,
      "$ Aggregate": "56920.00",
      "% Aggregate": "0.07",
      "$ Average": "56920.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Atlanta",
      Count: 13,
      "$ Aggregate": "2740914.54",
      "% Aggregate": "3.43",
      "$ Average": "210839.58",
      "% Interest Rate": "7.29",
    },
    {
      City: "Hammond",
      Count: 3,
      "$ Aggregate": "246572.71",
      "% Aggregate": "0.31",
      "$ Average": "82190.90",
      "% Interest Rate": "9.28",
    },
    {
      City: "San Jose",
      Count: 1,
      "$ Aggregate": "1012000.00",
      "% Aggregate": "1.27",
      "$ Average": "1012000.00",
      "% Interest Rate": "6.50",
    },
    {
      City: "Williamsport",
      Count: 1,
      "$ Aggregate": "89534.00",
      "% Aggregate": "0.11",
      "$ Average": "89534.00",
      "% Interest Rate": "9.00",
    },
    {
      City: "Owings Mills",
      Count: 1,
      "$ Aggregate": "120800.00",
      "% Aggregate": "0.15",
      "$ Average": "120800.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Scranton",
      Count: 4,
      "$ Aggregate": "323763.10",
      "% Aggregate": "0.41",
      "$ Average": "80940.77",
      "% Interest Rate": "7.99",
    },
    {
      City: "College Park",
      Count: 2,
      "$ Aggregate": "492295.01",
      "% Aggregate": "0.62",
      "$ Average": "246147.51",
      "% Interest Rate": "7.28",
    },
    {
      City: "Schwenksville",
      Count: 1,
      "$ Aggregate": "121193.90",
      "% Aggregate": "0.15",
      "$ Average": "121193.90",
      "% Interest Rate": "7.50",
    },
    {
      City: "Rio Rancho",
      Count: 1,
      "$ Aggregate": "117648.50",
      "% Aggregate": "0.15",
      "$ Average": "117648.50",
      "% Interest Rate": "11.50",
    },
    {
      City: "Millington",
      Count: 1,
      "$ Aggregate": "55841.31",
      "% Aggregate": "0.07",
      "$ Average": "55841.31",
      "% Interest Rate": "5.80",
    },
    {
      City: "Memphis",
      Count: 3,
      "$ Aggregate": "1577820.78",
      "% Aggregate": "1.98",
      "$ Average": "525940.26",
      "% Interest Rate": "5.60",
    },
    {
      City: "Killeen",
      Count: 3,
      "$ Aggregate": "332073.46",
      "% Aggregate": "0.42",
      "$ Average": "110691.15",
      "% Interest Rate": "7.75",
    },
    {
      City: "Charlotte",
      Count: 7,
      "$ Aggregate": "883641.23",
      "% Aggregate": "1.11",
      "$ Average": "126234.46",
      "% Interest Rate": "7.60",
    },
    {
      City: "Orange City",
      Count: 1,
      "$ Aggregate": "69200.00",
      "% Aggregate": "0.09",
      "$ Average": "69200.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Los Angeles",
      Count: 1,
      "$ Aggregate": "430541.90",
      "% Aggregate": "0.54",
      "$ Average": "430541.90",
      "% Interest Rate": "8.50",
    },
    {
      City: "Wyoming",
      Count: 1,
      "$ Aggregate": "102560.07",
      "% Aggregate": "0.13",
      "$ Average": "102560.07",
      "% Interest Rate": "5.90",
    },
    {
      City: "Deltona",
      Count: 4,
      "$ Aggregate": "400638.53",
      "% Aggregate": "0.50",
      "$ Average": "100159.63",
      "% Interest Rate": "7.26",
    },
    {
      City: "Manahawkin",
      Count: 3,
      "$ Aggregate": "179154.00",
      "% Aggregate": "0.22",
      "$ Average": "59718.00",
      "% Interest Rate": "10.49",
    },
    {
      City: "Richmond",
      Count: 3,
      "$ Aggregate": "351834.21",
      "% Aggregate": "0.44",
      "$ Average": "117278.07",
      "% Interest Rate": "9.17",
    },
    {
      City: "Washington",
      Count: 2,
      "$ Aggregate": "1510425.00",
      "% Aggregate": "1.89",
      "$ Average": "755212.50",
      "% Interest Rate": "6.85",
    },
    {
      City: "Port Saint Lucie",
      Count: 6,
      "$ Aggregate": "240869.83",
      "% Aggregate": "0.30",
      "$ Average": "40144.97",
      "% Interest Rate": "8.25",
    },
    {
      City: "Logan Township",
      Count: 1,
      "$ Aggregate": "139523.25",
      "% Aggregate": "0.17",
      "$ Average": "139523.25",
      "% Interest Rate": "9.25",
    },
    {
      City: "Saint Louis",
      Count: 4,
      "$ Aggregate": "228187.97",
      "% Aggregate": "0.29",
      "$ Average": "57046.99",
      "% Interest Rate": "8.03",
    },
    {
      City: "Long Key",
      Count: 1,
      "$ Aggregate": "374895.00",
      "% Aggregate": "0.47",
      "$ Average": "374895.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Beverly Hills",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Ashville",
      Count: 1,
      "$ Aggregate": "136492.13",
      "% Aggregate": "0.17",
      "$ Average": "136492.13",
      "% Interest Rate": "10.00",
    },
    {
      City: "Tucker",
      Count: 1,
      "$ Aggregate": "189937.97",
      "% Aggregate": "0.24",
      "$ Average": "189937.97",
      "% Interest Rate": "8.75",
    },
    {
      City: "Boiling Springs",
      Count: 3,
      "$ Aggregate": "129475.01",
      "% Aggregate": "0.16",
      "$ Average": "43158.34",
      "% Interest Rate": "7.50",
    },
    {
      City: "Trenton",
      Count: 1,
      "$ Aggregate": "59392.13",
      "% Aggregate": "0.07",
      "$ Average": "59392.13",
      "% Interest Rate": "6.00",
    },
    {
      City: "Cape Coral",
      Count: 2,
      "$ Aggregate": "265750.99",
      "% Aggregate": "0.33",
      "$ Average": "132875.49",
      "% Interest Rate": "10.22",
    },
    {
      City: "Fort Washington",
      Count: 1,
      "$ Aggregate": "186822.77",
      "% Aggregate": "0.23",
      "$ Average": "186822.77",
      "% Interest Rate": "5.45",
    },
    {
      City: "Detroit",
      Count: 4,
      "$ Aggregate": "1055710.58",
      "% Aggregate": "1.32",
      "$ Average": "263927.65",
      "% Interest Rate": "6.02",
    },
    {
      City: "Dayton",
      Count: 2,
      "$ Aggregate": "235748.81",
      "% Aggregate": "0.30",
      "$ Average": "117874.40",
      "% Interest Rate": "5.29",
    },
    {
      City: "Bridgeport",
      Count: 1,
      "$ Aggregate": "116250.00",
      "% Aggregate": "0.15",
      "$ Average": "116250.00",
      "% Interest Rate": "6.91",
    },
    {
      City: "Fort Wayne",
      Count: 1,
      "$ Aggregate": "72135.00",
      "% Aggregate": "0.09",
      "$ Average": "72135.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Imperial",
      Count: 1,
      "$ Aggregate": "117557.40",
      "% Aggregate": "0.15",
      "$ Average": "117557.40",
      "% Interest Rate": "10.00",
    },
    {
      City: "Pennsauken",
      Count: 1,
      "$ Aggregate": "128945.00",
      "% Aggregate": "0.16",
      "$ Average": "128945.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "Conover",
      Count: 1,
      "$ Aggregate": "78366.51",
      "% Aggregate": "0.10",
      "$ Average": "78366.51",
      "% Interest Rate": "7.75",
    },
    {
      City: "Excelsior Springs",
      Count: 1,
      "$ Aggregate": "64589.02",
      "% Aggregate": "0.08",
      "$ Average": "64589.02",
      "% Interest Rate": "5.08",
    },
    {
      City: "Carnegie",
      Count: 1,
      "$ Aggregate": "67697.68",
      "% Aggregate": "0.08",
      "$ Average": "67697.68",
      "% Interest Rate": "6.38",
    },
    {
      City: "Fort Walton Beach",
      Count: 1,
      "$ Aggregate": "835209.66",
      "% Aggregate": "1.05",
      "$ Average": "835209.66",
      "% Interest Rate": "4.95",
    },
    {
      City: "Pensacola",
      Count: 1,
      "$ Aggregate": "80097.97",
      "% Aggregate": "0.10",
      "$ Average": "80097.97",
      "% Interest Rate": "5.42",
    },
    {
      City: "Mesquite",
      Count: 1,
      "$ Aggregate": "110227.35",
      "% Aggregate": "0.14",
      "$ Average": "110227.35",
      "% Interest Rate": "4.37",
    },
    {
      City: "Destin",
      Count: 1,
      "$ Aggregate": "167200.00",
      "% Aggregate": "0.21",
      "$ Average": "167200.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Louisville",
      Count: 3,
      "$ Aggregate": "342788.36",
      "% Aggregate": "0.43",
      "$ Average": "114262.79",
      "% Interest Rate": "8.49",
    },
    {
      City: "Titusville",
      Count: 1,
      "$ Aggregate": "104390.00",
      "% Aggregate": "0.13",
      "$ Average": "104390.00",
      "% Interest Rate": "8.75",
    },
    {
      City: "Houston",
      Count: 2,
      "$ Aggregate": "421151.98",
      "% Aggregate": "0.53",
      "$ Average": "210575.99",
      "% Interest Rate": "9.43",
    },
    {
      City: "Augusta",
      Count: 1,
      "$ Aggregate": "255710.74",
      "% Aggregate": "0.32",
      "$ Average": "255710.74",
      "% Interest Rate": "4.75",
    },
    {
      City: "Yucca Valley",
      Count: 1,
      "$ Aggregate": "52250.73",
      "% Aggregate": "0.07",
      "$ Average": "52250.73",
      "% Interest Rate": "5.80",
    },
    {
      City: "Auburn",
      Count: 1,
      "$ Aggregate": "23897.50",
      "% Aggregate": "0.03",
      "$ Average": "23897.50",
      "% Interest Rate": "9.00",
    },
    {
      City: "Kansas City",
      Count: 3,
      "$ Aggregate": "158640.00",
      "% Aggregate": "0.20",
      "$ Average": "52880.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Muskegon",
      Count: 1,
      "$ Aggregate": "88345.69",
      "% Aggregate": "0.11",
      "$ Average": "88345.69",
      "% Interest Rate": "6.20",
    },
    {
      City: "St Petersburg",
      Count: 1,
      "$ Aggregate": "83936.64",
      "% Aggregate": "0.11",
      "$ Average": "83936.64",
      "% Interest Rate": "6.95",
    },
    {
      City: "North Chesterfield",
      Count: 1,
      "$ Aggregate": "304382.50",
      "% Aggregate": "0.38",
      "$ Average": "304382.50",
      "% Interest Rate": "9.25",
    },
    {
      City: "Saint Petersburg",
      Count: 1,
      "$ Aggregate": "188556.41",
      "% Aggregate": "0.24",
      "$ Average": "188556.41",
      "% Interest Rate": "9.75",
    },
    {
      City: "Brookhaven",
      Count: 1,
      "$ Aggregate": "399000.00",
      "% Aggregate": "0.50",
      "$ Average": "399000.00",
      "% Interest Rate": "7.40",
    },
    {
      City: "Hanahan",
      Count: 1,
      "$ Aggregate": "150495.80",
      "% Aggregate": "0.19",
      "$ Average": "150495.80",
      "% Interest Rate": "10.00",
    },
    {
      City: "Oklahoma City",
      Count: 2,
      "$ Aggregate": "205988.55",
      "% Aggregate": "0.26",
      "$ Average": "102994.27",
      "% Interest Rate": "10.12",
    },
    {
      City: "Leesburg",
      Count: 1,
      "$ Aggregate": "621796.97",
      "% Aggregate": "0.78",
      "$ Average": "621796.97",
      "% Interest Rate": "4.67",
    },
    {
      City: "Panama City",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Milwaukee",
      Count: 3,
      "$ Aggregate": "250374.09",
      "% Aggregate": "0.31",
      "$ Average": "83458.03",
      "% Interest Rate": "6.83",
    },
    {
      City: "Baton Rouge",
      Count: 2,
      "$ Aggregate": "141588.75",
      "% Aggregate": "0.18",
      "$ Average": "70794.38",
      "% Interest Rate": "11.27",
    },
    {
      City: "Hamilton",
      Count: 1,
      "$ Aggregate": "105293.76",
      "% Aggregate": "0.13",
      "$ Average": "105293.76",
      "% Interest Rate": "4.52",
    },
    {
      City: "East Point",
      Count: 2,
      "$ Aggregate": "88090.00",
      "% Aggregate": "0.11",
      "$ Average": "44045.00",
      "% Interest Rate": "7.75",
    },
    {
      City: "San Antonio",
      Count: 2,
      "$ Aggregate": "46000.00",
      "% Aggregate": "0.06",
      "$ Average": "23000.00",
      "% Interest Rate": "9.25",
    },
    {
      City: "Marcus Hook",
      Count: 1,
      "$ Aggregate": "91695.09",
      "% Aggregate": "0.11",
      "$ Average": "91695.09",
      "% Interest Rate": "5.60",
    },
    {
      City: "North Syracuse",
      Count: 1,
      "$ Aggregate": "67200.00",
      "% Aggregate": "0.08",
      "$ Average": "67200.00",
      "% Interest Rate": "7.17",
    },
    {
      City: "West Allis",
      Count: 1,
      "$ Aggregate": "126690.87",
      "% Aggregate": "0.16",
      "$ Average": "126690.87",
      "% Interest Rate": "10.50",
    },
    {
      City: "Orlando",
      Count: 1,
      "$ Aggregate": "447920.88",
      "% Aggregate": "0.56",
      "$ Average": "447920.88",
      "% Interest Rate": "9.50",
    },
    {
      City: "Lake Wales",
      Count: 1,
      "$ Aggregate": "104774.97",
      "% Aggregate": "0.13",
      "$ Average": "104774.97",
      "% Interest Rate": "5.13",
    },
    {
      City: "Vero Beach",
      Count: 1,
      "$ Aggregate": "108280.00",
      "% Aggregate": "0.14",
      "$ Average": "108280.00",
      "% Interest Rate": "8.75",
    },
    {
      City: "Hephzibah",
      Count: 1,
      "$ Aggregate": "39500.00",
      "% Aggregate": "0.05",
      "$ Average": "39500.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "High Point",
      Count: 1,
      "$ Aggregate": "52644.80",
      "% Aggregate": "0.07",
      "$ Average": "52644.80",
      "% Interest Rate": "11.00",
    },
    {
      City: "Lafayette",
      Count: 1,
      "$ Aggregate": "76500.00",
      "% Aggregate": "0.10",
      "$ Average": "76500.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Pittsburgh",
      Count: 1,
      "$ Aggregate": "55241.55",
      "% Aggregate": "0.07",
      "$ Average": "55241.55",
      "% Interest Rate": "11.50",
    },
    {
      City: "N. Charleston",
      Count: 1,
      "$ Aggregate": "146420.51",
      "% Aggregate": "0.18",
      "$ Average": "146420.51",
      "% Interest Rate": "6.75",
    },
    {
      City: "Savannah",
      Count: 2,
      "$ Aggregate": "339067.70",
      "% Aggregate": "0.42",
      "$ Average": "169533.85",
      "% Interest Rate": "5.47",
    },
    {
      City: "Jersey City",
      Count: 1,
      "$ Aggregate": "362268.53",
      "% Aggregate": "0.45",
      "$ Average": "362268.53",
      "% Interest Rate": "5.45",
    },
    {
      City: "Allentown",
      Count: 1,
      "$ Aggregate": "411909.93",
      "% Aggregate": "0.52",
      "$ Average": "411909.93",
      "% Interest Rate": "7.20",
    },
    {
      City: "Cleveland",
      Count: 3,
      "$ Aggregate": "311665.24",
      "% Aggregate": "0.39",
      "$ Average": "103888.41",
      "% Interest Rate": "8.00",
    },
    {
      City: "Center Point",
      Count: 1,
      "$ Aggregate": "57420.00",
      "% Aggregate": "0.07",
      "$ Average": "57420.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Fayetteville",
      Count: 3,
      "$ Aggregate": "291790.56",
      "% Aggregate": "0.37",
      "$ Average": "97263.52",
      "% Interest Rate": "5.58",
    },
    {
      City: "Easley",
      Count: 3,
      "$ Aggregate": "219268.55",
      "% Aggregate": "0.27",
      "$ Average": "73089.52",
      "% Interest Rate": "8.87",
    },
    {
      City: "Jamesville",
      Count: 1,
      "$ Aggregate": "115941.38",
      "% Aggregate": "0.15",
      "$ Average": "115941.38",
      "% Interest Rate": "9.25",
    },
    {
      City: "Irwin",
      Count: 1,
      "$ Aggregate": "122496.87",
      "% Aggregate": "0.15",
      "$ Average": "122496.87",
      "% Interest Rate": "10.00",
    },
    {
      City: "Cedar Lake",
      Count: 1,
      "$ Aggregate": "423574.25",
      "% Aggregate": "0.53",
      "$ Average": "423574.25",
      "% Interest Rate": "5.10",
    },
    {
      City: "Berwyn",
      Count: 2,
      "$ Aggregate": "225998.68",
      "% Aggregate": "0.28",
      "$ Average": "112999.34",
      "% Interest Rate": "9.33",
    },
    {
      City: "Midwest City",
      Count: 1,
      "$ Aggregate": "93798.63",
      "% Aggregate": "0.12",
      "$ Average": "93798.63",
      "% Interest Rate": "10.00",
    },
    {
      City: "Jonesboro",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Spartanburg",
      Count: 4,
      "$ Aggregate": "231947.39",
      "% Aggregate": "0.29",
      "$ Average": "57986.85",
      "% Interest Rate": "6.67",
    },
    {
      City: "Decatur",
      Count: 2,
      "$ Aggregate": "596738.70",
      "% Aggregate": "0.75",
      "$ Average": "298369.35",
      "% Interest Rate": "8.28",
    },
    {
      City: "Southport",
      Count: 1,
      "$ Aggregate": "284000.00",
      "% Aggregate": "0.36",
      "$ Average": "284000.00",
      "% Interest Rate": "7.25",
    },
    {
      City: "Roswell",
      Count: 1,
      "$ Aggregate": "297060.00",
      "% Aggregate": "0.37",
      "$ Average": "297060.00",
      "% Interest Rate": "9.49",
    },
    {
      City: "Mount Holly",
      Count: 2,
      "$ Aggregate": "199570.35",
      "% Aggregate": "0.25",
      "$ Average": "99785.18",
      "% Interest Rate": "6.05",
    },
    {
      City: "Conley",
      Count: 1,
      "$ Aggregate": "577897.06",
      "% Aggregate": "0.72",
      "$ Average": "577897.06",
      "% Interest Rate": "5.08",
    },
    {
      City: "Poinciana",
      Count: 2,
      "$ Aggregate": "329975.02",
      "% Aggregate": "0.41",
      "$ Average": "164987.51",
      "% Interest Rate": "7.50",
    },
    {
      City: "Scottdale",
      Count: 2,
      "$ Aggregate": "-24485.20",
      "% Aggregate": "-0.03",
      "$ Average": "-12242.60",
      "% Interest Rate": "11.16",
    },
    {
      City: "St. Joseph",
      Count: 1,
      "$ Aggregate": "139350.73",
      "% Aggregate": "0.17",
      "$ Average": "139350.73",
      "% Interest Rate": "5.08",
    },
    {
      City: "Northbrook",
      Count: 1,
      "$ Aggregate": "486870.00",
      "% Aggregate": "0.61",
      "$ Average": "486870.00",
      "% Interest Rate": "8.50",
    },
    {
      City: "Pine Hill",
      Count: 1,
      "$ Aggregate": "82880.00",
      "% Aggregate": "0.10",
      "$ Average": "82880.00",
      "% Interest Rate": "9.49",
    },
    {
      City: "Debary",
      Count: 1,
      "$ Aggregate": "188615.50",
      "% Aggregate": "0.24",
      "$ Average": "188615.50",
      "% Interest Rate": "5.40",
    },
    {
      City: "Pleasant Prairie",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Garfield Heights",
      Count: 1,
      "$ Aggregate": "66600.00",
      "% Aggregate": "0.08",
      "$ Average": "66600.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "Florence",
      Count: 1,
      "$ Aggregate": "671288.74",
      "% Aggregate": "0.84",
      "$ Average": "671288.74",
      "% Interest Rate": "5.83",
    },
    {
      City: "Newburgh Heights",
      Count: 1,
      "$ Aggregate": "111682.59",
      "% Aggregate": "0.14",
      "$ Average": "111682.59",
      "% Interest Rate": "5.80",
    },
    {
      City: "Lincoln Park",
      Count: 1,
      "$ Aggregate": "252000.00",
      "% Aggregate": "0.32",
      "$ Average": "252000.00",
      "% Interest Rate": "7.88",
    },
    {
      City: "Mableton",
      Count: 1,
      "$ Aggregate": "115258.24",
      "% Aggregate": "0.14",
      "$ Average": "115258.24",
      "% Interest Rate": "5.25",
    },
    {
      City: "Riverdale",
      Count: 1,
      "$ Aggregate": "27120.00",
      "% Aggregate": "0.03",
      "$ Average": "27120.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Orange",
      Count: 1,
      "$ Aggregate": "105570.00",
      "% Aggregate": "0.13",
      "$ Average": "105570.00",
      "% Interest Rate": "11.50",
    },
    {
      City: "N Charleston",
      Count: 1,
      "$ Aggregate": "172250.00",
      "% Aggregate": "0.22",
      "$ Average": "172250.00",
      "% Interest Rate": "8.72",
    },
    {
      City: "Phoenix",
      Count: 1,
      "$ Aggregate": "125845.91",
      "% Aggregate": "0.16",
      "$ Average": "125845.91",
      "% Interest Rate": "5.45",
    },
    {
      City: "Wilmington",
      Count: 3,
      "$ Aggregate": "315727.62",
      "% Aggregate": "0.40",
      "$ Average": "105242.54",
      "% Interest Rate": "7.17",
    },
    {
      City: "Oak Park",
      Count: 1,
      "$ Aggregate": "125463.50",
      "% Aggregate": "0.16",
      "$ Average": "125463.50",
      "% Interest Rate": "9.25",
    },
    {
      City: "Hillside",
      Count: 1,
      "$ Aggregate": "125377.92",
      "% Aggregate": "0.16",
      "$ Average": "125377.92",
      "% Interest Rate": "9.00",
    },
    {
      City: "Rochester",
      Count: 2,
      "$ Aggregate": "120792.96",
      "% Aggregate": "0.15",
      "$ Average": "60396.48",
      "% Interest Rate": "10.00",
    },
    {
      City: "Loves Park",
      Count: 1,
      "$ Aggregate": "85384.65",
      "% Aggregate": "0.11",
      "$ Average": "85384.65",
      "% Interest Rate": "11.00",
    },
    {
      City: "Fountain Inn",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Somers Point",
      Count: 1,
      "$ Aggregate": "91100.00",
      "% Aggregate": "0.11",
      "$ Average": "91100.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Powell",
      Count: 1,
      "$ Aggregate": "149691.93",
      "% Aggregate": "0.19",
      "$ Average": "149691.93",
      "% Interest Rate": "5.35",
    },
    {
      City: "Abilene",
      Count: 1,
      "$ Aggregate": "87789.33",
      "% Aggregate": "0.11",
      "$ Average": "87789.33",
      "% Interest Rate": "6.68",
    },
    {
      City: "Hendersonville",
      Count: 2,
      "$ Aggregate": "501424.82",
      "% Aggregate": "0.63",
      "$ Average": "250712.41",
      "% Interest Rate": "4.95",
    },
    {
      City: "Camden",
      Count: 1,
      "$ Aggregate": "86827.90",
      "% Aggregate": "0.11",
      "$ Average": "86827.90",
      "% Interest Rate": "5.53",
    },
    {
      City: "Pequot Lakes",
      Count: 1,
      "$ Aggregate": "119937.54",
      "% Aggregate": "0.15",
      "$ Average": "119937.54",
      "% Interest Rate": "6.90",
    },
    {
      City: "Fresno",
      Count: 1,
      "$ Aggregate": "195523.64",
      "% Aggregate": "0.24",
      "$ Average": "195523.64",
      "% Interest Rate": "4.45",
    },
    {
      City: "Las Vegas",
      Count: 1,
      "$ Aggregate": "1597000.00",
      "% Aggregate": "2.00",
      "$ Average": "1597000.00",
      "% Interest Rate": "6.25",
    },
    {
      City: "Goose Creek",
      Count: 1,
      "$ Aggregate": "161850.00",
      "% Aggregate": "0.20",
      "$ Average": "161850.00",
      "% Interest Rate": "7.52",
    },
    {
      City: "Montgomery",
      Count: 1,
      "$ Aggregate": "77355.00",
      "% Aggregate": "0.10",
      "$ Average": "77355.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Trussville",
      Count: 1,
      "$ Aggregate": "466897.50",
      "% Aggregate": "0.58",
      "$ Average": "466897.50",
      "% Interest Rate": "9.49",
    },
    {
      City: "Cockeysville",
      Count: 1,
      "$ Aggregate": "369397.90",
      "% Aggregate": "0.46",
      "$ Average": "369397.90",
      "% Interest Rate": "5.53",
    },
    {
      City: "Live Oak",
      Count: 1,
      "$ Aggregate": "133527.71",
      "% Aggregate": "0.17",
      "$ Average": "133527.71",
      "% Interest Rate": "4.63",
    },
    {
      City: "Portsmouth",
      Count: 3,
      "$ Aggregate": "234519.30",
      "% Aggregate": "0.29",
      "$ Average": "78173.10",
      "% Interest Rate": "9.02",
    },
    {
      City: "Stockbridge",
      Count: 1,
      "$ Aggregate": "86010.23",
      "% Aggregate": "0.11",
      "$ Average": "86010.23",
      "% Interest Rate": "5.90",
    },
    {
      City: "Cincinnati",
      Count: 2,
      "$ Aggregate": "334243.80",
      "% Aggregate": "0.42",
      "$ Average": "167121.90",
      "% Interest Rate": "11.49",
    },
    {
      City: "Mishawaka",
      Count: 2,
      "$ Aggregate": "162190.31",
      "% Aggregate": "0.20",
      "$ Average": "81095.15",
      "% Interest Rate": "6.64",
    },
    {
      City: "Front Royal",
      Count: 2,
      "$ Aggregate": "221600.00",
      "% Aggregate": "0.28",
      "$ Average": "110800.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Grandview",
      Count: 1,
      "$ Aggregate": "286217.23",
      "% Aggregate": "0.36",
      "$ Average": "286217.23",
      "% Interest Rate": "5.97",
    },
    {
      City: "Texas City",
      Count: 1,
      "$ Aggregate": "64054.50",
      "% Aggregate": "0.08",
      "$ Average": "64054.50",
      "% Interest Rate": "7.25",
    },
    {
      City: "Lake Charles",
      Count: 2,
      "$ Aggregate": "161706.12",
      "% Aggregate": "0.20",
      "$ Average": "80853.06",
      "% Interest Rate": "8.51",
    },
    {
      City: "Santa Rosa",
      Count: 1,
      "$ Aggregate": "162690.00",
      "% Aggregate": "0.20",
      "$ Average": "162690.00",
      "% Interest Rate": "7.25",
    },
    {
      City: "EASTPOINTE",
      Count: 1,
      "$ Aggregate": "370792.36",
      "% Aggregate": "0.46",
      "$ Average": "370792.36",
      "% Interest Rate": "6.53",
    },
    {
      City: "Radford",
      Count: 1,
      "$ Aggregate": "52532.29",
      "% Aggregate": "0.07",
      "$ Average": "52532.29",
      "% Interest Rate": "10.25",
    },
    {
      City: "Port Jervis",
      Count: 1,
      "$ Aggregate": "196000.00",
      "% Aggregate": "0.25",
      "$ Average": "196000.00",
      "% Interest Rate": "7.67",
    },
    {
      City: "Birmingham",
      Count: 1,
      "$ Aggregate": "54720.00",
      "% Aggregate": "0.07",
      "$ Average": "54720.00",
      "% Interest Rate": "9.00",
    },
    {
      City: "Waynesville",
      Count: 1,
      "$ Aggregate": "48994.00",
      "% Aggregate": "0.06",
      "$ Average": "48994.00",
      "% Interest Rate": "8.75",
    },
    {
      City: "Hartford",
      Count: 2,
      "$ Aggregate": "94491.97",
      "% Aggregate": "0.12",
      "$ Average": "47245.99",
      "% Interest Rate": "11.00",
    },
    {
      City: "Spring Hill",
      Count: 1,
      "$ Aggregate": "55096.36",
      "% Aggregate": "0.07",
      "$ Average": "55096.36",
      "% Interest Rate": "5.88",
    },
    {
      City: "Venice",
      Count: 1,
      "$ Aggregate": "196500.00",
      "% Aggregate": "0.25",
      "$ Average": "196500.00",
      "% Interest Rate": "8.03",
    },
    {
      City: "Senoia",
      Count: 1,
      "$ Aggregate": "93530.41",
      "% Aggregate": "0.12",
      "$ Average": "93530.41",
      "% Interest Rate": "4.65",
    },
    {
      City: "Carlsbad",
      Count: 1,
      "$ Aggregate": "101485.82",
      "% Aggregate": "0.13",
      "$ Average": "101485.82",
      "% Interest Rate": "11.00",
    },
    {
      City: "Phoneix",
      Count: 1,
      "$ Aggregate": "208800.00",
      "% Aggregate": "0.26",
      "$ Average": "208800.00",
      "% Interest Rate": "7.52",
    },
    {
      City: "Cincinatti",
      Count: 1,
      "$ Aggregate": "108464.76",
      "% Aggregate": "0.14",
      "$ Average": "108464.76",
      "% Interest Rate": "6.20",
    },
    {
      City: "Monroe",
      Count: 1,
      "$ Aggregate": "2164107.77",
      "% Aggregate": "2.71",
      "$ Average": "2164107.77",
      "% Interest Rate": "6.49",
    },
    {
      City: "Kannapolis",
      Count: 1,
      "$ Aggregate": "62497.80",
      "% Aggregate": "0.08",
      "$ Average": "62497.80",
      "% Interest Rate": "11.00",
    },
    {
      City: "Pendleton",
      Count: 1,
      "$ Aggregate": "138040.00",
      "% Aggregate": "0.17",
      "$ Average": "138040.00",
      "% Interest Rate": "11.50",
    },
    {
      City: "Greenville",
      Count: 2,
      "$ Aggregate": "3893423.32",
      "% Aggregate": "4.87",
      "$ Average": "1946711.66",
      "% Interest Rate": "5.29",
    },
    {
      City: "Parkville",
      Count: 1,
      "$ Aggregate": "1657534.00",
      "% Aggregate": "2.07",
      "$ Average": "1657534.00",
      "% Interest Rate": "5.50",
    },
    {
      City: "Miami Gardens",
      Count: 1,
      "$ Aggregate": "213492.60",
      "% Aggregate": "0.27",
      "$ Average": "213492.60",
      "% Interest Rate": "9.00",
    },
    {
      City: "New Kensington",
      Count: 1,
      "$ Aggregate": "137212.20",
      "% Aggregate": "0.17",
      "$ Average": "137212.20",
      "% Interest Rate": "5.25",
    },
    {
      City: "Gastonia",
      Count: 2,
      "$ Aggregate": "185634.96",
      "% Aggregate": "0.23",
      "$ Average": "92817.48",
      "% Interest Rate": "5.83",
    },
    {
      City: "Hopewell",
      Count: 1,
      "$ Aggregate": "92250.00",
      "% Aggregate": "0.12",
      "$ Average": "92250.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Ellenwood",
      Count: 1,
      "$ Aggregate": "156960.00",
      "% Aggregate": "0.20",
      "$ Average": "156960.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "Marietta",
      Count: 1,
      "$ Aggregate": "317536.20",
      "% Aggregate": "0.40",
      "$ Average": "317536.20",
      "% Interest Rate": "11.50",
    },
    {
      City: "Holt",
      Count: 1,
      "$ Aggregate": "113400.00",
      "% Aggregate": "0.14",
      "$ Average": "113400.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Madison",
      Count: 2,
      "$ Aggregate": "277293.90",
      "% Aggregate": "0.35",
      "$ Average": "138646.95",
      "% Interest Rate": "5.26",
    },
    {
      City: "Prairie View",
      Count: 1,
      "$ Aggregate": "96993.76",
      "% Aggregate": "0.12",
      "$ Average": "96993.76",
      "% Interest Rate": "5.25",
    },
    {
      City: "Des moines",
      Count: 1,
      "$ Aggregate": "59255.71",
      "% Aggregate": "0.07",
      "$ Average": "59255.71",
      "% Interest Rate": "6.55",
    },
    {
      City: "Florida City",
      Count: 1,
      "$ Aggregate": "153550.00",
      "% Aggregate": "0.19",
      "$ Average": "153550.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Monrovia",
      Count: 1,
      "$ Aggregate": "125685.00",
      "% Aggregate": "0.16",
      "$ Average": "125685.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "North Augusta",
      Count: 1,
      "$ Aggregate": "133879.18",
      "% Aggregate": "0.17",
      "$ Average": "133879.18",
      "% Interest Rate": "5.73",
    },
    {
      City: "Tampa",
      Count: 1,
      "$ Aggregate": "278370.00",
      "% Aggregate": "0.35",
      "$ Average": "278370.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Penns Grove",
      Count: 1,
      "$ Aggregate": "58641.26",
      "% Aggregate": "0.07",
      "$ Average": "58641.26",
      "% Interest Rate": "5.90",
    },
    {
      City: "Zion",
      Count: 1,
      "$ Aggregate": "69894.72",
      "% Aggregate": "0.09",
      "$ Average": "69894.72",
      "% Interest Rate": "6.95",
    },
    {
      City: "Gloucester City",
      Count: 1,
      "$ Aggregate": "77400.00",
      "% Aggregate": "0.10",
      "$ Average": "77400.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Youngstown",
      Count: 1,
      "$ Aggregate": "107035.19",
      "% Aggregate": "0.13",
      "$ Average": "107035.19",
      "% Interest Rate": "5.48",
    },
    {
      City: "Madison Heights",
      Count: 2,
      "$ Aggregate": "217172.02",
      "% Aggregate": "0.27",
      "$ Average": "108586.01",
      "% Interest Rate": "8.75",
    },
    {
      City: "East Stroudsburg",
      Count: 1,
      "$ Aggregate": "79875.00",
      "% Aggregate": "0.10",
      "$ Average": "79875.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "St. Paul",
      Count: 1,
      "$ Aggregate": "254381.70",
      "% Aggregate": "0.32",
      "$ Average": "254381.70",
      "% Interest Rate": "6.58",
    },
    {
      City: "Miami",
      Count: 1,
      "$ Aggregate": "247000.00",
      "% Aggregate": "0.31",
      "$ Average": "247000.00",
      "% Interest Rate": "7.73",
    },
    {
      City: "Fenton",
      Count: 1,
      "$ Aggregate": "142892.93",
      "% Aggregate": "0.18",
      "$ Average": "142892.93",
      "% Interest Rate": "9.50",
    },
    {
      City: "Irvington",
      Count: 2,
      "$ Aggregate": "241507.50",
      "% Aggregate": "0.30",
      "$ Average": "120753.75",
      "% Interest Rate": "8.66",
    },
    {
      City: "Evansville",
      Count: 1,
      "$ Aggregate": "136131.37",
      "% Aggregate": "0.17",
      "$ Average": "136131.37",
      "% Interest Rate": "6.05",
    },
    {
      City: "Runnemade",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Sicklerville",
      Count: 1,
      "$ Aggregate": "111988.48",
      "% Aggregate": "0.14",
      "$ Average": "111988.48",
      "% Interest Rate": "10.00",
    },
    {
      City: "Chatsworth",
      Count: 1,
      "$ Aggregate": "397800.00",
      "% Aggregate": "0.50",
      "$ Average": "397800.00",
      "% Interest Rate": "9.50",
    },
    {
      City: "Fairburn",
      Count: 1,
      "$ Aggregate": "1653222.23",
      "% Aggregate": "2.07",
      "$ Average": "1653222.23",
      "% Interest Rate": "4.45",
    },
    {
      City: "Smithfield",
      Count: 1,
      "$ Aggregate": "49050.00",
      "% Aggregate": "0.06",
      "$ Average": "49050.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Stephens City",
      Count: 1,
      "$ Aggregate": "163664.00",
      "% Aggregate": "0.20",
      "$ Average": "163664.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Sevierville",
      Count: 1,
      "$ Aggregate": "-22494.89",
      "% Aggregate": "-0.03",
      "$ Average": "-22494.89",
      "% Interest Rate": "9.25",
    },
    {
      City: "Greensboro",
      Count: 1,
      "$ Aggregate": "119773.73",
      "% Aggregate": "0.15",
      "$ Average": "119773.73",
      "% Interest Rate": "7.10",
    },
    {
      City: "Waterford",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Paterson",
      Count: 1,
      "$ Aggregate": "234945.00",
      "% Aggregate": "0.29",
      "$ Average": "234945.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "McDonough",
      Count: 1,
      "$ Aggregate": "83850.65",
      "% Aggregate": "0.10",
      "$ Average": "83850.65",
      "% Interest Rate": "6.10",
    },
    {
      City: "Willow Springs",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Palmetto",
      Count: 1,
      "$ Aggregate": "129498.43",
      "% Aggregate": "0.16",
      "$ Average": "129498.43",
      "% Interest Rate": "10.00",
    },
    {
      City: "Sunman",
      Count: 1,
      "$ Aggregate": "-6573.04",
      "% Aggregate": "-0.01",
      "$ Average": "-6573.04",
      "% Interest Rate": "8.25",
    },
    {
      City: "Maple Heights",
      Count: 1,
      "$ Aggregate": "93390.35",
      "% Aggregate": "0.12",
      "$ Average": "93390.35",
      "% Interest Rate": "5.05",
    },
    {
      City: "Kingwood",
      Count: 1,
      "$ Aggregate": "228868.94",
      "% Aggregate": "0.29",
      "$ Average": "228868.94",
      "% Interest Rate": "10.00",
    },
    {
      City: "Lithonia",
      Count: 1,
      "$ Aggregate": "2125.00",
      "% Aggregate": "0.00",
      "$ Average": "2125.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Richmond Heights",
      Count: 1,
      "$ Aggregate": "168673.60",
      "% Aggregate": "0.21",
      "$ Average": "168673.60",
      "% Interest Rate": "11.25",
    },
    {
      City: "Phillipsburg",
      Count: 1,
      "$ Aggregate": "115645.13",
      "% Aggregate": "0.14",
      "$ Average": "115645.13",
      "% Interest Rate": "5.40",
    },
    {
      City: "Columbia",
      Count: 1,
      "$ Aggregate": "310771.54",
      "% Aggregate": "0.39",
      "$ Average": "310771.54",
      "% Interest Rate": "10.00",
    },
    {
      City: "Kissimmee",
      Count: 1,
      "$ Aggregate": "128800.00",
      "% Aggregate": "0.16",
      "$ Average": "128800.00",
      "% Interest Rate": "8.08",
    },
    {
      City: "Liverpool",
      Count: 1,
      "$ Aggregate": "98977.50",
      "% Aggregate": "0.12",
      "$ Average": "98977.50",
      "% Interest Rate": "10.00",
    },
    {
      City: "Dunedin",
      Count: 1,
      "$ Aggregate": "335700.00",
      "% Aggregate": "0.42",
      "$ Average": "335700.00",
      "% Interest Rate": "11.49",
    },
    {
      City: "New Orleans",
      Count: 1,
      "$ Aggregate": "164180.01",
      "% Aggregate": "0.21",
      "$ Average": "164180.01",
      "% Interest Rate": "8.75",
    },
    {
      City: "Pompano Beach",
      Count: 1,
      "$ Aggregate": "158027.04",
      "% Aggregate": "0.20",
      "$ Average": "158027.04",
      "% Interest Rate": "6.75",
    },
    {
      City: "Rock Hill",
      Count: 1,
      "$ Aggregate": "118984.26",
      "% Aggregate": "0.15",
      "$ Average": "118984.26",
      "% Interest Rate": "11.25",
    },
    {
      City: "Markham",
      Count: 1,
      "$ Aggregate": "42075.00",
      "% Aggregate": "0.05",
      "$ Average": "42075.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Total:",
      Count: "486",
      "$ Aggregate": "79889414.36",
      "% Aggregate": "100.00",
      "$ Average": "46416230.20",
      "% Interest Rate": "1892.22",
    },
  ],
  tableData: [],
};
export const LimaInvestorIndexData = [
  { title: "Date", order_id: "1", anchore_link: "Date" },
  { title: "Payment Summary", order_id: "1", anchore_link: "PaymentSummary" },
  {
    title: "Principal Payments",
    order_id: "1",
    anchore_link: "PrincipalPayments",
  },
  {
    title: "Interest Payments",
    order_id: "1",
    anchore_link: "InterestPayments",
  },
  {
    title: "Special Adjustments",
    order_id: "1",
    anchore_link: "SpecialAdjustments",
  },
  { title: "Factors Per $1,000", order_id: "1", anchore_link: "ClassFactors" },
  {
    title: "Account Statements",
    order_id: "1",
    anchore_link: "AccountStatements",
  },
  {
    title: "Collateral Summary",
    order_id: "1",
    anchore_link: "CollateralSummary",
  },
  {
    title: "Prepayments And Default Rates",
    order_id: "1",
    anchore_link: "PrePaymentsAndDefaultRates",
  },
  {
    title: "Deal Fees And Expenses",
    order_id: "1",
    anchore_link: "DealFeesAndExpenses",
  },
  { title: "Deal Events", order_id: "1", anchore_link: "DealEvents" },
  {
    title: "Performance Details",
    order_id: "1",
    anchore_link: "PerformanceDetails",
  },
  {
    title: "Priority Of Payments",
    order_id: "1",
    anchore_link: "PriorityOfPayments",
  },
];

export const SaludaInvestorIndexData = [
  { title: "Date", order_id: "1", anchore_link: "Date" },
  { title: "Payment Summary", order_id: "1", anchore_link: "PaymentSummary" },
  {
    title: "Principal Payments",
    order_id: "1",
    anchore_link: "PrincipalPayments",
  },
  {
    title: "Interest Payments",
    order_id: "1",
    anchore_link: "InterestPayments",
  },
  {
    title: "Account Statements",
    order_id: "1",
    anchore_link: "AccountStatements",
  },
  { title: "Class Factors", order_id: "1", anchore_link: "ClassFactors" },
  {
    title: "Collateral Summary",
    order_id: "1",
    anchore_link: "CollateralSummary",
  },
  {
    title: "Acquisition Criteria",
    order_id: "1",
    anchore_link: "AcquisitionCriteria",
  },
  {
    title: "PrePayments And DefaultRates",
    order_id: "1",
    anchore_link: "PrePaymentsAndDefaultRates",
  },
  {
    title: "DealFees And Expenses",
    order_id: "1",
    anchore_link: "DealFeesAndExpenses",
  },
  { title: "Deal Events", order_id: "1", anchore_link: "DealEvents" },
  {
    title: "Priority Of Payments",
    order_id: "1",
    anchore_link: "PriorityOfPayments",
  },
  {
    title: "Loan Delinquencies",
    order_id: "1",
    anchore_link: "LoanDelinquencies",
  },
  {
    title: "Loan Modifications",
    order_id: "1",
    anchore_link: "LoanModifications",
  },
  {
    title: "Loans Transferred to Special Servicing",
    order_id: "1",
    anchore_link: "LoansTransferredtoSpecialServicing",
  },
];

export const dummydata = {
  DealContactInformation: {
    dealid: "L1C 2020-1, LLC",
    distributiondate: "9/25/2020",
    reporttype: "Monthly Paying Agent",
    relationshipmanager: "Devon Almeida",
    address: "500 Delaware Ave, 11th Floor, Wilmington, DE 19801",
    email: "dalmeida@wsfsbank.com",
    websitereporting: "www.wsfsabsreporting.com",
    addtionalfirst: " ",
    additionallast: " ",
  },
  DelinquencyStatus: [
    {
      "Delinquency Status": "0 - 30",
      Count: "0",
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      "Delinquency Status": "30 - 60",
      Count: 2,
      "$ Aggregate": "691330.21",
      "% Aggregate": "60.68",
      "$ Average": "345665.10",
      "% Interest Rate": "7.14",
    },
    {
      "Delinquency Status": "60 - 90",
      Count: 1,
      "$ Aggregate": "447920.88",
      "% Aggregate": "39.32",
      "$ Average": "447920.88",
      "% Interest Rate": "9.50",
    },
    {
      "Delinquency Status": "90 - 120",
      Count: "0",
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      "Delinquency Status": "120 +",
      Count: "0",
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      "Delinquency Status": "Bankruptcy",
      Count: "0",
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      "Delinquency Status": "Foreclosure",
      Count: "0",
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      "Delinquency Status": "REO",
      Count: "0",
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      "Delinquency Status": "Total:",
      Count: "3",
      "$ Aggregate": "1139251.09",
      "% Aggregate": "100.00",
      "$ Average": "793585.98",
      "% Interest Rate": "16.64",
    },
  ],
  PIF: [
    {
      "Loan ID": "108970",
      "Unpaid Principal Balance": "155716.52",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108101",
      "Unpaid Principal Balance": "284000",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "10/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108150",
      "Unpaid Principal Balance": "56000",
      "Pre-Modification": "10/04/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104997",
      "Unpaid Principal Balance": "39500",
      "Pre-Modification": "01/02/2021",
      "Post-Modification": "01/02/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108412",
      "Unpaid Principal Balance": "320188.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108407",
      "Unpaid Principal Balance": "120120",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108045",
      "Unpaid Principal Balance": "88020",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108140",
      "Unpaid Principal Balance": "125994.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108724",
      "Unpaid Principal Balance": "93750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105760",
      "Unpaid Principal Balance": "157492.35",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108210",
      "Unpaid Principal Balance": "73818",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108826",
      "Unpaid Principal Balance": "40000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103614",
      "Unpaid Principal Balance": "80500",
      "Pre-Modification": "01/01/2050",
      "Post-Modification": "01/01/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108774",
      "Unpaid Principal Balance": "146998.75",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108196",
      "Unpaid Principal Balance": "191820",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108714",
      "Unpaid Principal Balance": "52155",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103474",
      "Unpaid Principal Balance": "108736",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108021",
      "Unpaid Principal Balance": "51570",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108024",
      "Unpaid Principal Balance": "116250",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102452",
      "Unpaid Principal Balance": "234945",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108782",
      "Unpaid Principal Balance": "113150",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108484",
      "Unpaid Principal Balance": "251995.82",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108451",
      "Unpaid Principal Balance": "99790",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107931",
      "Unpaid Principal Balance": "53600",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102446",
      "Unpaid Principal Balance": "117557.4",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108117",
      "Unpaid Principal Balance": "72100",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108395",
      "Unpaid Principal Balance": "93100",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "106676",
      "Unpaid Principal Balance": "61600",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108014",
      "Unpaid Principal Balance": "170805.92",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105652",
      "Unpaid Principal Balance": "118300",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105651",
      "Unpaid Principal Balance": "51100",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104502",
      "Unpaid Principal Balance": "67050",
      "Pre-Modification": "01/01/2050",
      "Post-Modification": "01/01/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108830",
      "Unpaid Principal Balance": "136492.13",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105182",
      "Unpaid Principal Balance": "2695000",
      "Pre-Modification": "01/02/2022",
      "Post-Modification": "01/02/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "58137",
      "Unpaid Principal Balance": "55241.55",
      "Pre-Modification": "01/10/2019",
      "Post-Modification": "01/10/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105707",
      "Unpaid Principal Balance": "59500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105584",
      "Unpaid Principal Balance": "838000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105794",
      "Unpaid Principal Balance": "109200",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107889",
      "Unpaid Principal Balance": "186200",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108271",
      "Unpaid Principal Balance": "94496.22",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109500",
      "Unpaid Principal Balance": "99450",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108026",
      "Unpaid Principal Balance": "64170",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108051",
      "Unpaid Principal Balance": "142875",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "59030",
      "Unpaid Principal Balance": "89995.5",
      "Pre-Modification": "01/12/2019",
      "Post-Modification": "01/12/2019",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104768",
      "Unpaid Principal Balance": "246000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105776",
      "Unpaid Principal Balance": "446832.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108732",
      "Unpaid Principal Balance": "121800",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108888",
      "Unpaid Principal Balance": "68000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108148",
      "Unpaid Principal Balance": "356715",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109513",
      "Unpaid Principal Balance": "425600",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108138",
      "Unpaid Principal Balance": "127800",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102873",
      "Unpaid Principal Balance": "168673.6",
      "Pre-Modification": "01/10/2020",
      "Post-Modification": "01/10/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109037",
      "Unpaid Principal Balance": "169371.9",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108579",
      "Unpaid Principal Balance": "139523.25",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108442",
      "Unpaid Principal Balance": "105075",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104879",
      "Unpaid Principal Balance": "59500",
      "Pre-Modification": "01/01/2050",
      "Post-Modification": "01/01/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108749",
      "Unpaid Principal Balance": "1002780",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107996",
      "Unpaid Principal Balance": "126000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103536",
      "Unpaid Principal Balance": "67200",
      "Pre-Modification": "01/02/2022",
      "Post-Modification": "01/02/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "307936",
      "Unpaid Principal Balance": "133000",
      "Pre-Modification": "",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "100901",
      "Unpaid Principal Balance": "150495.8",
      "Pre-Modification": "10/07/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107788",
      "Unpaid Principal Balance": "128216.7",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108522",
      "Unpaid Principal Balance": "82880",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102678",
      "Unpaid Principal Balance": "132525",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108469",
      "Unpaid Principal Balance": "187832.8",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108327",
      "Unpaid Principal Balance": "231840",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108630",
      "Unpaid Principal Balance": "370500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108481",
      "Unpaid Principal Balance": "111600",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108645",
      "Unpaid Principal Balance": "111988.48",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109531",
      "Unpaid Principal Balance": "425600",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108856",
      "Unpaid Principal Balance": "63697.5",
      "Pre-Modification": "10/06/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104095",
      "Unpaid Principal Balance": "108750",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105490",
      "Unpaid Principal Balance": "164180",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108370",
      "Unpaid Principal Balance": "111744",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108428",
      "Unpaid Principal Balance": "284000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108342",
      "Unpaid Principal Balance": "1663998",
      "Pre-Modification": "09/02/2030",
      "Post-Modification": "09/02/2030",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108998",
      "Unpaid Principal Balance": "46399.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109514",
      "Unpaid Principal Balance": "227375",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109054",
      "Unpaid Principal Balance": "104993.7",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108720",
      "Unpaid Principal Balance": "145305",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107990",
      "Unpaid Principal Balance": "53200",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105174",
      "Unpaid Principal Balance": "120000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108798",
      "Unpaid Principal Balance": "216000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105780",
      "Unpaid Principal Balance": "105700",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108664",
      "Unpaid Principal Balance": "59400",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104891",
      "Unpaid Principal Balance": "232800",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108262",
      "Unpaid Principal Balance": "90000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104558",
      "Unpaid Principal Balance": "196500",
      "Pre-Modification": "01/04/2022",
      "Post-Modification": "01/04/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104672",
      "Unpaid Principal Balance": "101485.82",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108999",
      "Unpaid Principal Balance": "159316.52",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108096",
      "Unpaid Principal Balance": "672550",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108057",
      "Unpaid Principal Balance": "76000",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105708",
      "Unpaid Principal Balance": "251550",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105748",
      "Unpaid Principal Balance": "139750",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108606",
      "Unpaid Principal Balance": "93625",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105446",
      "Unpaid Principal Balance": "79875",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108292",
      "Unpaid Principal Balance": "93798.8",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107907",
      "Unpaid Principal Balance": "72135",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105656",
      "Unpaid Principal Balance": "100400",
      "Pre-Modification": "01/02/2050",
      "Post-Modification": "01/02/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108885",
      "Unpaid Principal Balance": "58640",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102680",
      "Unpaid Principal Balance": "117648.5",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108195",
      "Unpaid Principal Balance": "146650",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108320",
      "Unpaid Principal Balance": "339500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108620",
      "Unpaid Principal Balance": "69480",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107848",
      "Unpaid Principal Balance": "62497.8",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107871",
      "Unpaid Principal Balance": "59200",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108515",
      "Unpaid Principal Balance": "150000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108530",
      "Unpaid Principal Balance": "70560.8",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104260",
      "Unpaid Principal Balance": "141525",
      "Pre-Modification": "01/12/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103551",
      "Unpaid Principal Balance": "125289",
      "Pre-Modification": "01/12/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108496",
      "Unpaid Principal Balance": "254700.18",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108536",
      "Unpaid Principal Balance": "213492.6",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103874",
      "Unpaid Principal Balance": "65600",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108239",
      "Unpaid Principal Balance": "196000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107998",
      "Unpaid Principal Balance": "59500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108920",
      "Unpaid Principal Balance": "187200",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104806",
      "Unpaid Principal Balance": "70000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102274",
      "Unpaid Principal Balance": "92250",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108357",
      "Unpaid Principal Balance": "131250",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105349",
      "Unpaid Principal Balance": "93800",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108767",
      "Unpaid Principal Balance": "49400",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108582",
      "Unpaid Principal Balance": "266750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108177",
      "Unpaid Principal Balance": "405463.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105118",
      "Unpaid Principal Balance": "163732.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105540",
      "Unpaid Principal Balance": "135000",
      "Pre-Modification": "01/02/2050",
      "Post-Modification": "01/02/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107984",
      "Unpaid Principal Balance": "180750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108850",
      "Unpaid Principal Balance": "123750",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108309",
      "Unpaid Principal Balance": "86250",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102569",
      "Unpaid Principal Balance": "146226.96",
      "Pre-Modification": "01/11/2020",
      "Post-Modification": "01/11/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108840",
      "Unpaid Principal Balance": "90000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108634",
      "Unpaid Principal Balance": "125685",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108565",
      "Unpaid Principal Balance": "133000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109376",
      "Unpaid Principal Balance": "84493.57",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105773",
      "Unpaid Principal Balance": "133000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108753",
      "Unpaid Principal Balance": "174999.74",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108512",
      "Unpaid Principal Balance": "75331.25",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108500",
      "Unpaid Principal Balance": "137880",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104920",
      "Unpaid Principal Balance": "172250",
      "Pre-Modification": "01/02/2022",
      "Post-Modification": "01/02/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108249",
      "Unpaid Principal Balance": "64800",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "50133",
      "Unpaid Principal Balance": "694216.5",
      "Pre-Modification": "01/07/2018",
      "Post-Modification": "01/07/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108378",
      "Unpaid Principal Balance": "61830",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104785",
      "Unpaid Principal Balance": "136500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105317",
      "Unpaid Principal Balance": "602950",
      "Pre-Modification": "09/03/2050",
      "Post-Modification": "09/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108479",
      "Unpaid Principal Balance": "130500",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104423",
      "Unpaid Principal Balance": "127757.19",
      "Pre-Modification": "01/01/2021",
      "Post-Modification": "01/01/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108010",
      "Unpaid Principal Balance": "55035",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108637",
      "Unpaid Principal Balance": "92790",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105201",
      "Unpaid Principal Balance": "252000",
      "Pre-Modification": "01/03/2022",
      "Post-Modification": "01/03/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107804",
      "Unpaid Principal Balance": "252000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108401",
      "Unpaid Principal Balance": "76500",
      "Pre-Modification": "10/04/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105359",
      "Unpaid Principal Balance": "70000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105705",
      "Unpaid Principal Balance": "424950",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108894",
      "Unpaid Principal Balance": "37600",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108066",
      "Unpaid Principal Balance": "125766.44",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103263",
      "Unpaid Principal Balance": "308000",
      "Pre-Modification": "01/11/2021",
      "Post-Modification": "01/11/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108584",
      "Unpaid Principal Balance": "110500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108795",
      "Unpaid Principal Balance": "54855",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108704",
      "Unpaid Principal Balance": "77400",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108531",
      "Unpaid Principal Balance": "60000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108374",
      "Unpaid Principal Balance": "128945",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104711",
      "Unpaid Principal Balance": "134400",
      "Pre-Modification": "01/02/2050",
      "Post-Modification": "01/02/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108306",
      "Unpaid Principal Balance": "98977.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107829",
      "Unpaid Principal Balance": "110550",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102879",
      "Unpaid Principal Balance": "317536.2",
      "Pre-Modification": "01/10/2020",
      "Post-Modification": "01/10/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108612",
      "Unpaid Principal Balance": "108500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108506",
      "Unpaid Principal Balance": "201775.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108456",
      "Unpaid Principal Balance": "131629.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107941",
      "Unpaid Principal Balance": "76990.21",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105347",
      "Unpaid Principal Balance": "196000",
      "Pre-Modification": "01/03/2022",
      "Post-Modification": "01/03/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105505",
      "Unpaid Principal Balance": "2209500",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108161",
      "Unpaid Principal Balance": "127350",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108279",
      "Unpaid Principal Balance": "113400",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102366",
      "Unpaid Principal Balance": "244985.89",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108803",
      "Unpaid Principal Balance": "106704",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108931",
      "Unpaid Principal Balance": "105750",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107805",
      "Unpaid Principal Balance": "84700",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108410",
      "Unpaid Principal Balance": "699930.05",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104722",
      "Unpaid Principal Balance": "107599.35",
      "Pre-Modification": "01/02/2021",
      "Post-Modification": "01/06/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "106685",
      "Unpaid Principal Balance": "65800",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108208",
      "Unpaid Principal Balance": "83997.02",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108863",
      "Unpaid Principal Balance": "41500",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108705",
      "Unpaid Principal Balance": "258995.97",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "58509",
      "Unpaid Principal Balance": "142998.24",
      "Pre-Modification": "01/10/2019",
      "Post-Modification": "01/07/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108600",
      "Unpaid Principal Balance": "53210",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104361",
      "Unpaid Principal Balance": "138740",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108532",
      "Unpaid Principal Balance": "50400",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108367",
      "Unpaid Principal Balance": "177173.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "10/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105590",
      "Unpaid Principal Balance": "71250",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104902",
      "Unpaid Principal Balance": "562454.01",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105297",
      "Unpaid Principal Balance": "91971",
      "Pre-Modification": "01/02/2021",
      "Post-Modification": "01/06/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105292",
      "Unpaid Principal Balance": "198750",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103363",
      "Unpaid Principal Balance": "118984.26",
      "Pre-Modification": "01/11/2020",
      "Post-Modification": "01/11/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108043",
      "Unpaid Principal Balance": "136440",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102273",
      "Unpaid Principal Balance": "258750",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107960",
      "Unpaid Principal Balance": "193690",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108770",
      "Unpaid Principal Balance": "189000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108394",
      "Unpaid Principal Balance": "109173.74",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105374",
      "Unpaid Principal Balance": "184500",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105605",
      "Unpaid Principal Balance": "412500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105569",
      "Unpaid Principal Balance": "474772.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108261",
      "Unpaid Principal Balance": "93798.63",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108591",
      "Unpaid Principal Balance": "182900",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108580",
      "Unpaid Principal Balance": "126100",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104643",
      "Unpaid Principal Balance": "105390",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108867",
      "Unpaid Principal Balance": "296838",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108847",
      "Unpaid Principal Balance": "120600",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105485",
      "Unpaid Principal Balance": "124000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108151",
      "Unpaid Principal Balance": "61390",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107841",
      "Unpaid Principal Balance": "115941.38",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "100569",
      "Unpaid Principal Balance": "35050.5",
      "Pre-Modification": "01/06/2020",
      "Post-Modification": "01/06/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108232",
      "Unpaid Principal Balance": "146300",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108549",
      "Unpaid Principal Balance": "157500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108075",
      "Unpaid Principal Balance": "750960",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108815",
      "Unpaid Principal Balance": "443468.7",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109073",
      "Unpaid Principal Balance": "167200",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108046",
      "Unpaid Principal Balance": "1897000",
      "Pre-Modification": "01/05/2022",
      "Post-Modification": "01/05/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108741",
      "Unpaid Principal Balance": "63750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105058",
      "Unpaid Principal Balance": "54589.86",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108594",
      "Unpaid Principal Balance": "78000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108187",
      "Unpaid Principal Balance": "255000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "20064",
      "Unpaid Principal Balance": "306360",
      "Pre-Modification": "",
      "Post-Modification": "01/07/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108202",
      "Unpaid Principal Balance": "125280",
      "Pre-Modification": "10/04/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108602",
      "Unpaid Principal Balance": "48996.43",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108347",
      "Unpaid Principal Balance": "75870",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108875",
      "Unpaid Principal Balance": "76500",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108174",
      "Unpaid Principal Balance": "77250",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108159",
      "Unpaid Principal Balance": "579150",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108439",
      "Unpaid Principal Balance": "105000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107853",
      "Unpaid Principal Balance": "134000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109572",
      "Unpaid Principal Balance": "2040500",
      "Pre-Modification": "01/03/2022",
      "Post-Modification": "01/03/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103529",
      "Unpaid Principal Balance": "142787.99",
      "Pre-Modification": "01/01/2021",
      "Post-Modification": "01/01/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108449",
      "Unpaid Principal Balance": "129498.43",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108656",
      "Unpaid Principal Balance": "105570",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104583",
      "Unpaid Principal Balance": "63517.5",
      "Pre-Modification": "01/01/2021",
      "Post-Modification": "01/01/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108868",
      "Unpaid Principal Balance": "296838",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105496",
      "Unpaid Principal Balance": "120800",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108671",
      "Unpaid Principal Balance": "192000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107794",
      "Unpaid Principal Balance": "406000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105140",
      "Unpaid Principal Balance": "153900",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108589",
      "Unpaid Principal Balance": "87000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109045",
      "Unpaid Principal Balance": "151580",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109033",
      "Unpaid Principal Balance": "158416.52",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104468",
      "Unpaid Principal Balance": "60200",
      "Pre-Modification": "01/03/2022",
      "Post-Modification": "01/03/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108685",
      "Unpaid Principal Balance": "109800",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102556",
      "Unpaid Principal Balance": "139995",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102543",
      "Unpaid Principal Balance": "69750",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108132",
      "Unpaid Principal Balance": "124650",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108657",
      "Unpaid Principal Balance": "101493.65",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102554",
      "Unpaid Principal Balance": "223269.76",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107928",
      "Unpaid Principal Balance": "74750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108361",
      "Unpaid Principal Balance": "87345",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "10/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104474",
      "Unpaid Principal Balance": "107250",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108489",
      "Unpaid Principal Balance": "334376.87",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108390",
      "Unpaid Principal Balance": "124653.18",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105114",
      "Unpaid Principal Balance": "179650",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108090",
      "Unpaid Principal Balance": "95200",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105680",
      "Unpaid Principal Balance": "84000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108784",
      "Unpaid Principal Balance": "135000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108994",
      "Unpaid Principal Balance": "105675",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105695",
      "Unpaid Principal Balance": "98246.54",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104645",
      "Unpaid Principal Balance": "137900.3",
      "Pre-Modification": "01/01/2021",
      "Post-Modification": "01/01/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108564",
      "Unpaid Principal Balance": "158400",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108540",
      "Unpaid Principal Balance": "194975.2",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "10/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104538",
      "Unpaid Principal Balance": "139988.55",
      "Pre-Modification": "01/01/2021",
      "Post-Modification": "01/01/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108315",
      "Unpaid Principal Balance": "2485000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104876",
      "Unpaid Principal Balance": "161000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108827",
      "Unpaid Principal Balance": "123070",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "59440",
      "Unpaid Principal Balance": "108099",
      "Pre-Modification": "01/02/2020",
      "Post-Modification": "01/11/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108038",
      "Unpaid Principal Balance": "69300",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108419",
      "Unpaid Principal Balance": "62400",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105649",
      "Unpaid Principal Balance": "82500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103710",
      "Unpaid Principal Balance": "332090.1",
      "Pre-Modification": "01/12/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108396",
      "Unpaid Principal Balance": "178482.9",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107948",
      "Unpaid Principal Balance": "397800",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105562",
      "Unpaid Principal Balance": "122400",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108276",
      "Unpaid Principal Balance": "1012000",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103923",
      "Unpaid Principal Balance": "290831.89",
      "Pre-Modification": "01/12/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107800",
      "Unpaid Principal Balance": "66500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108418",
      "Unpaid Principal Balance": "122496.87",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108590",
      "Unpaid Principal Balance": "268450",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104546",
      "Unpaid Principal Balance": "52500",
      "Pre-Modification": "01/01/2050",
      "Post-Modification": "01/01/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108498",
      "Unpaid Principal Balance": "195962.3",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108474",
      "Unpaid Principal Balance": "67121.7",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105173",
      "Unpaid Principal Balance": "1430800",
      "Pre-Modification": "09/03/2050",
      "Post-Modification": "09/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108968",
      "Unpaid Principal Balance": "335700",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107947",
      "Unpaid Principal Balance": "88000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108792",
      "Unpaid Principal Balance": "75021.3",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102323",
      "Unpaid Principal Balance": "83290.72",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108429",
      "Unpaid Principal Balance": "208800",
      "Pre-Modification": "01/03/2022",
      "Post-Modification": "01/03/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103674",
      "Unpaid Principal Balance": "98422.5",
      "Pre-Modification": "01/12/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107940",
      "Unpaid Principal Balance": "116000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105689",
      "Unpaid Principal Balance": "61701.6",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107961",
      "Unpaid Principal Balance": "236000",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108212",
      "Unpaid Principal Balance": "1164750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108270",
      "Unpaid Principal Balance": "623250",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108230",
      "Unpaid Principal Balance": "66000",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108773",
      "Unpaid Principal Balance": "769500",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108118",
      "Unpaid Principal Balance": "102800",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108654",
      "Unpaid Principal Balance": "2716000",
      "Pre-Modification": "09/03/2050",
      "Post-Modification": "09/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102399",
      "Unpaid Principal Balance": "85680",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105750",
      "Unpaid Principal Balance": "67870",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "58656",
      "Unpaid Principal Balance": "162351",
      "Pre-Modification": "10/12/2019",
      "Post-Modification": "01/11/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108441",
      "Unpaid Principal Balance": "90225",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105583",
      "Unpaid Principal Balance": "137500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107823",
      "Unpaid Principal Balance": "69200",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108805",
      "Unpaid Principal Balance": "100000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104571",
      "Unpaid Principal Balance": "82500",
      "Pre-Modification": "01/01/2050",
      "Post-Modification": "01/01/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105473",
      "Unpaid Principal Balance": "324450",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108510",
      "Unpaid Principal Balance": "66257.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108812",
      "Unpaid Principal Balance": "156960",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105264",
      "Unpaid Principal Balance": "52500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108575",
      "Unpaid Principal Balance": "371400",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108631",
      "Unpaid Principal Balance": "609367.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108495",
      "Unpaid Principal Balance": "278370",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105647",
      "Unpaid Principal Balance": "73500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109198",
      "Unpaid Principal Balance": "131250",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108466",
      "Unpaid Principal Balance": "266225.97",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108105",
      "Unpaid Principal Balance": "94500",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108170",
      "Unpaid Principal Balance": "55250",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105392",
      "Unpaid Principal Balance": "1657250",
      "Pre-Modification": "09/03/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102666",
      "Unpaid Principal Balance": "66600",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102303",
      "Unpaid Principal Balance": "310771.54",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108244",
      "Unpaid Principal Balance": "128800",
      "Pre-Modification": "01/04/2022",
      "Post-Modification": "01/04/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109516",
      "Unpaid Principal Balance": "110250",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108246",
      "Unpaid Principal Balance": "91875",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108551",
      "Unpaid Principal Balance": "53200",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107904",
      "Unpaid Principal Balance": "399000",
      "Pre-Modification": "01/04/2022",
      "Post-Modification": "01/04/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108872",
      "Unpaid Principal Balance": "304938",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102611",
      "Unpaid Principal Balance": "69990.69",
      "Pre-Modification": "01/10/2020",
      "Post-Modification": "01/10/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108058",
      "Unpaid Principal Balance": "125338.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108147",
      "Unpaid Principal Balance": "243750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108725",
      "Unpaid Principal Balance": "118680",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108259",
      "Unpaid Principal Balance": "169371.9",
      "Pre-Modification": "10/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104664",
      "Unpaid Principal Balance": "3379200",
      "Pre-Modification": "09/04/2050",
      "Post-Modification": "09/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107834",
      "Unpaid Principal Balance": "93892.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108895",
      "Unpaid Principal Balance": "64980",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108742",
      "Unpaid Principal Balance": "236234.25",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103137",
      "Unpaid Principal Balance": "118638",
      "Pre-Modification": "01/11/2020",
      "Post-Modification": "01/11/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108106",
      "Unpaid Principal Balance": "116000",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108629",
      "Unpaid Principal Balance": "543712.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108110",
      "Unpaid Principal Balance": "85384.65",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109140",
      "Unpaid Principal Balance": "143708.58",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108627",
      "Unpaid Principal Balance": "126365.4",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105758",
      "Unpaid Principal Balance": "287000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108459",
      "Unpaid Principal Balance": "154400",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108093",
      "Unpaid Principal Balance": "75780",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108678",
      "Unpaid Principal Balance": "42075",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109047",
      "Unpaid Principal Balance": "169146.9",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104551",
      "Unpaid Principal Balance": "1208340",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105533",
      "Unpaid Principal Balance": "115500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108158",
      "Unpaid Principal Balance": "472550",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108281",
      "Unpaid Principal Balance": "52644.8",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108771",
      "Unpaid Principal Balance": "77355",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105046",
      "Unpaid Principal Balance": "102300",
      "Pre-Modification": "01/02/2050",
      "Post-Modification": "01/02/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108310",
      "Unpaid Principal Balance": "105600",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107807",
      "Unpaid Principal Balance": "87000",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108642",
      "Unpaid Principal Balance": "84000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "106675",
      "Unpaid Principal Balance": "61600",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108002",
      "Unpaid Principal Balance": "77144",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108455",
      "Unpaid Principal Balance": "42500",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104275",
      "Unpaid Principal Balance": "185480.66",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105650",
      "Unpaid Principal Balance": "92400",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108504",
      "Unpaid Principal Balance": "144950",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108482",
      "Unpaid Principal Balance": "124200",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108625",
      "Unpaid Principal Balance": "60030",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "58875",
      "Unpaid Principal Balance": "126690.87",
      "Pre-Modification": "01/02/2020",
      "Post-Modification": "01/05/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108008",
      "Unpaid Principal Balance": "97300",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108034",
      "Unpaid Principal Balance": "161850",
      "Pre-Modification": "01/04/2022",
      "Post-Modification": "01/04/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107819",
      "Unpaid Principal Balance": "100800",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102645",
      "Unpaid Principal Balance": "371187",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/09/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108595",
      "Unpaid Principal Balance": "88500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108368",
      "Unpaid Principal Balance": "170802.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108468",
      "Unpaid Principal Balance": "138040",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105709",
      "Unpaid Principal Balance": "251550",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108973",
      "Unpaid Principal Balance": "168921.9",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103929",
      "Unpaid Principal Balance": "116059.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108427",
      "Unpaid Principal Balance": "132468.3",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108067",
      "Unpaid Principal Balance": "123003.88",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "106674",
      "Unpaid Principal Balance": "35575.24",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109074",
      "Unpaid Principal Balance": "165150",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108518",
      "Unpaid Principal Balance": "562498.86",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108607",
      "Unpaid Principal Balance": "1375515",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102978",
      "Unpaid Principal Balance": "228868.94",
      "Pre-Modification": "10/12/2020",
      "Post-Modification": "01/11/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108989",
      "Unpaid Principal Balance": "59500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108131",
      "Unpaid Principal Balance": "256300",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105620",
      "Unpaid Principal Balance": "111375",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108201",
      "Unpaid Principal Balance": "139750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109387",
      "Unpaid Principal Balance": "95540.08",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108578",
      "Unpaid Principal Balance": "59500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108688",
      "Unpaid Principal Balance": "90000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108497",
      "Unpaid Principal Balance": "181978.5",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "59725",
      "Unpaid Principal Balance": "87492.42",
      "Pre-Modification": "01/02/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105295",
      "Unpaid Principal Balance": "89250",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107992",
      "Unpaid Principal Balance": "247000",
      "Pre-Modification": "01/04/2022",
      "Post-Modification": "01/04/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108191",
      "Unpaid Principal Balance": "77000",
      "Pre-Modification": "01/04/2022",
      "Post-Modification": "01/04/2022",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107890",
      "Unpaid Principal Balance": "112000",
      "Pre-Modification": "10/04/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107973",
      "Unpaid Principal Balance": "520200",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108092",
      "Unpaid Principal Balance": "138750",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109055",
      "Unpaid Principal Balance": "363000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105658",
      "Unpaid Principal Balance": "1224720",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108845",
      "Unpaid Principal Balance": "119210",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108587",
      "Unpaid Principal Balance": "57420",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108707",
      "Unpaid Principal Balance": "148005",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108036",
      "Unpaid Principal Balance": "113445",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109441",
      "Unpaid Principal Balance": "103827.5",
      "Pre-Modification": "01/09/2021",
      "Post-Modification": "01/09/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108458",
      "Unpaid Principal Balance": "91000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "109200",
      "Unpaid Principal Balance": "146625",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108127",
      "Unpaid Principal Balance": "60718.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108352",
      "Unpaid Principal Balance": "136500",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104460",
      "Unpaid Principal Balance": "170811",
      "Pre-Modification": "01/03/2021",
      "Post-Modification": "01/03/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108572",
      "Unpaid Principal Balance": "351750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "59956",
      "Unpaid Principal Balance": "54720",
      "Pre-Modification": "01/02/2020",
      "Post-Modification": "01/08/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108638",
      "Unpaid Principal Balance": "268025.36",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108502",
      "Unpaid Principal Balance": "70000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107873",
      "Unpaid Principal Balance": "96000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105002",
      "Unpaid Principal Balance": "66203.1",
      "Pre-Modification": "10/05/2021",
      "Post-Modification": "10/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108296",
      "Unpaid Principal Balance": "66690",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108225",
      "Unpaid Principal Balance": "60000",
      "Pre-Modification": "10/04/2050",
      "Post-Modification": "10/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108691",
      "Unpaid Principal Balance": "102900",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104960",
      "Unpaid Principal Balance": "58860",
      "Pre-Modification": "01/02/2050",
      "Post-Modification": "01/02/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108321",
      "Unpaid Principal Balance": "133000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108480",
      "Unpaid Principal Balance": "545670",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105244",
      "Unpaid Principal Balance": "67850",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "10/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108294",
      "Unpaid Principal Balance": "256895.39",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108211",
      "Unpaid Principal Balance": "114092.55",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104734",
      "Unpaid Principal Balance": "93750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108417",
      "Unpaid Principal Balance": "78400",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107963",
      "Unpaid Principal Balance": "119052",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "107964",
      "Unpaid Principal Balance": "84000",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108119",
      "Unpaid Principal Balance": "568800",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108337",
      "Unpaid Principal Balance": "80600",
      "Pre-Modification": "01/03/2050",
      "Post-Modification": "01/03/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108702",
      "Unpaid Principal Balance": "172000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108689",
      "Unpaid Principal Balance": "102750",
      "Pre-Modification": "01/04/2050",
      "Post-Modification": "01/04/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "103484",
      "Unpaid Principal Balance": "120172.5",
      "Pre-Modification": "01/04/2021",
      "Post-Modification": "01/04/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108978",
      "Unpaid Principal Balance": "126000",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "108400",
      "Unpaid Principal Balance": "94491.97",
      "Pre-Modification": "01/05/2021",
      "Post-Modification": "01/05/2021",
      "Modification Type": "Extension",
    },
  ],
  State: [
    {
      State: "FL",
      Count: 46,
      "$ Aggregate": "6894981.25",
      "% Aggregate": "8.73",
      "$ Average": "149890.90",
      "% Interest Rate": "7.42",
    },
    {
      State: "TN",
      Count: 7,
      "$ Aggregate": "2009157.05",
      "% Aggregate": "2.54",
      "$ Average": "287022.44",
      "% Interest Rate": "5.96",
    },
    {
      State: "GA",
      Count: 45,
      "$ Aggregate": "9398957.72",
      "% Aggregate": "11.90",
      "$ Average": "208865.73",
      "% Interest Rate": "7.46",
    },
    {
      State: "IL",
      Count: 30,
      "$ Aggregate": "4074574.15",
      "% Aggregate": "5.16",
      "$ Average": "135819.14",
      "% Interest Rate": "9.00",
    },
    {
      State: "MN",
      Count: 2,
      "$ Aggregate": "373716.21",
      "% Aggregate": "0.47",
      "$ Average": "186858.11",
      "% Interest Rate": "6.68",
    },
    {
      State: "PA",
      Count: 37,
      "$ Aggregate": "6585531.28",
      "% Aggregate": "8.33",
      "$ Average": "177987.33",
      "% Interest Rate": "6.83",
    },
    {
      State: "SC",
      Count: 21,
      "$ Aggregate": "6450113.13",
      "% Aggregate": "8.16",
      "$ Average": "307148.24",
      "% Interest Rate": "6.38",
    },
    {
      State: "OH",
      Count: 43,
      "$ Aggregate": "4606803.18",
      "% Aggregate": "5.83",
      "$ Average": "107134.96",
      "% Interest Rate": "8.09",
    },
    {
      State: "VA",
      Count: 14,
      "$ Aggregate": "1600789.72",
      "% Aggregate": "2.03",
      "$ Average": "114342.12",
      "% Interest Rate": "9.14",
    },
    {
      State: "MD",
      Count: 30,
      "$ Aggregate": "6772823.36",
      "% Aggregate": "8.57",
      "$ Average": "225760.78",
      "% Interest Rate": "5.99",
    },
    {
      State: "NC",
      Count: 23,
      "$ Aggregate": "5443461.47",
      "% Aggregate": "6.89",
      "$ Average": "236672.24",
      "% Interest Rate": "6.53",
    },
    {
      State: "CT",
      Count: 2,
      "$ Aggregate": "210387.10",
      "% Aggregate": "0.27",
      "$ Average": "105193.55",
      "% Interest Rate": "8.75",
    },
    {
      State: "NJ",
      Count: 28,
      "$ Aggregate": "4254104.14",
      "% Aggregate": "5.38",
      "$ Average": "151932.29",
      "% Interest Rate": "8.35",
    },
    {
      State: "MO",
      Count: 8,
      "$ Aggregate": "697852.22",
      "% Aggregate": "0.88",
      "$ Average": "87231.53",
      "% Interest Rate": "6.74",
    },
    {
      State: "MI",
      Count: 14,
      "$ Aggregate": "2612090.70",
      "% Aggregate": "3.31",
      "$ Average": "186577.91",
      "% Interest Rate": "6.45",
    },
    {
      State: "IN",
      Count: 26,
      "$ Aggregate": "2973371.96",
      "% Aggregate": "3.76",
      "$ Average": "114360.46",
      "% Interest Rate": "7.51",
    },
    {
      State: "IA",
      Count: 1,
      "$ Aggregate": "59155.98",
      "% Aggregate": "0.07",
      "$ Average": "59155.98",
      "% Interest Rate": "6.55",
    },
    {
      State: "DE",
      Count: 3,
      "$ Aggregate": "315319.82",
      "% Aggregate": "0.40",
      "$ Average": "105106.61",
      "% Interest Rate": "7.17",
    },
    {
      State: "NY",
      Count: 8,
      "$ Aggregate": "925023.24",
      "% Aggregate": "1.17",
      "$ Average": "115627.90",
      "% Interest Rate": "8.23",
    },
    {
      State: "LA",
      Count: 5,
      "$ Aggregate": "477274.88",
      "% Aggregate": "0.60",
      "$ Average": "95454.98",
      "% Interest Rate": "9.40",
    },
    {
      State: "TX",
      Count: 12,
      "$ Aggregate": "1810629.49",
      "% Aggregate": "2.29",
      "$ Average": "150885.79",
      "% Interest Rate": "8.78",
    },
    {
      State: "NM",
      Count: 2,
      "$ Aggregate": "101485.82",
      "% Aggregate": "0.13",
      "$ Average": "50742.91",
      "% Interest Rate": "11.00",
    },
    {
      State: "CA",
      Count: 6,
      "$ Aggregate": "2556724.43",
      "% Aggregate": "3.24",
      "$ Average": "426120.74",
      "% Interest Rate": "7.28",
    },
    {
      State: "KY",
      Count: 2,
      "$ Aggregate": "69646.16",
      "% Aggregate": "0.09",
      "$ Average": "34823.08",
      "% Interest Rate": "7.20",
    },
    {
      State: "WI",
      Count: 4,
      "$ Aggregate": "339123.88",
      "% Aggregate": "0.43",
      "$ Average": "84780.97",
      "% Interest Rate": "8.12",
    },
    {
      State: "AL",
      Count: 4,
      "$ Aggregate": "660276.22",
      "% Aggregate": "0.84",
      "$ Average": "165069.05",
      "% Interest Rate": "9.35",
    },
    {
      State: "OK",
      Count: 3,
      "$ Aggregate": "299787.18",
      "% Aggregate": "0.38",
      "$ Average": "99929.06",
      "% Interest Rate": "10.08",
    },
    {
      State: "AZ",
      Count: 2,
      "$ Aggregate": "334389.29",
      "% Aggregate": "0.42",
      "$ Average": "167194.64",
      "% Interest Rate": "6.75",
    },
    {
      State: "MS",
      Count: 4,
      "$ Aggregate": "393816.68",
      "% Aggregate": "0.50",
      "$ Average": "98454.17",
      "% Interest Rate": "6.12",
    },
    {
      State: "DC",
      Count: 2,
      "$ Aggregate": "1544425.00",
      "% Aggregate": "1.95",
      "$ Average": "772212.50",
      "% Interest Rate": "6.86",
    },
    {
      State: "NV",
      Count: 1,
      "$ Aggregate": "1717918.82",
      "% Aggregate": "2.17",
      "$ Average": "1717918.82",
      "% Interest Rate": "6.70",
    },
    {
      State: "WA",
      Count: 2,
      "$ Aggregate": "2283361.38",
      "% Aggregate": "2.89",
      "$ Average": "1141680.69",
      "% Interest Rate": "8.46",
    },
    {
      State: "WV",
      Count: 1,
      "$ Aggregate": "67121.70",
      "% Aggregate": "0.08",
      "$ Average": "67121.70",
      "% Interest Rate": "10.50",
    },
    {
      State: "KS",
      Count: 1,
      "$ Aggregate": "100000.00",
      "% Aggregate": "0.13",
      "$ Average": "100000.00",
      "% Interest Rate": "8.25",
    },
    {
      State: "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "8235077.32",
      "% Interest Rate": "264.08",
    },
  ],
  LoanType: [
    {
      "Loan Type *": "Commit NC NF",
      Count: 42,
      "$ Aggregate": "5735501.44",
      "% Aggregate": "7.26",
      "$ Average": "136559.56",
      "% Interest Rate": "8.53",
    },
    {
      "Loan Type *": "Bridge+ PO",
      Count: 39,
      "$ Aggregate": "7411959.10",
      "% Aggregate": "9.38",
      "$ Average": "190050.23",
      "% Interest Rate": "8.26",
    },
    {
      "Loan Type *": "Fixed R30",
      Count: 71,
      "$ Aggregate": "8845547.41",
      "% Aggregate": "11.19",
      "$ Average": "124585.17",
      "% Interest Rate": "6.16",
    },
    {
      "Loan Type *": "Commit NF",
      Count: 70,
      "$ Aggregate": "9788230.44",
      "% Aggregate": "12.39",
      "$ Average": "139831.86",
      "% Interest Rate": "8.40",
    },
    {
      "Loan Type *": "Fully Escrow",
      Count: 97,
      "$ Aggregate": "11908985.25",
      "% Aggregate": "15.07",
      "$ Average": "122773.04",
      "% Interest Rate": "10.41",
    },
    {
      "Loan Type *": "5/1 R30",
      Count: 51,
      "$ Aggregate": "6893648.81",
      "% Aggregate": "8.72",
      "$ Average": "135169.58",
      "% Interest Rate": "5.58",
    },
    {
      "Loan Type *": "10/1 R30",
      Count: 25,
      "$ Aggregate": "4260041.68",
      "% Aggregate": "5.39",
      "$ Average": "170401.67",
      "% Interest Rate": "5.81",
    },
    {
      "Loan Type *": "F2R 10/1 R30",
      Count: 1,
      "$ Aggregate": "108165.59",
      "% Aggregate": "0.14",
      "$ Average": "108165.59",
      "% Interest Rate": "5.28",
    },
    {
      "Loan Type *": "Fully Escrow NC",
      Count: 5,
      "$ Aggregate": "1000032.18",
      "% Aggregate": "1.27",
      "$ Average": "200006.44",
      "% Interest Rate": "10.48",
    },
    {
      "Loan Type *": "Commit MF NF",
      Count: 3,
      "$ Aggregate": "4177959.45",
      "% Aggregate": "5.29",
      "$ Average": "1392653.15",
      "% Interest Rate": "6.70",
    },
    {
      "Loan Type *": "10/1 R30 Prem",
      Count: 4,
      "$ Aggregate": "6511008.43",
      "% Aggregate": "8.24",
      "$ Average": "1627752.11",
      "% Interest Rate": "5.04",
    },
    {
      "Loan Type *": "2+1",
      Count: 12,
      "$ Aggregate": "2166600.00",
      "% Aggregate": "2.74",
      "$ Average": "180550.00",
      "% Interest Rate": "7.74",
    },
    {
      "Loan Type *": "CRL Fixed R30",
      Count: 1,
      "$ Aggregate": "132420.95",
      "% Aggregate": "0.17",
      "$ Average": "132420.95",
      "% Interest Rate": "6.25",
    },
    {
      "Loan Type *": "Commit",
      Count: 2,
      "$ Aggregate": "971826.50",
      "% Aggregate": "1.23",
      "$ Average": "485913.25",
      "% Interest Rate": "8.10",
    },
    {
      "Loan Type *": "Fixed R30 Prem",
      Count: 4,
      "$ Aggregate": "3067292.98",
      "% Aggregate": "3.88",
      "$ Average": "766823.24",
      "% Interest Rate": "5.33",
    },
    {
      "Loan Type *": "FNF Cash Out",
      Count: 3,
      "$ Aggregate": "150000.00",
      "% Aggregate": "0.19",
      "$ Average": "50000.00",
      "% Interest Rate": "8.18",
    },
    {
      "Loan Type *": "F2R Fixed R30",
      Count: 2,
      "$ Aggregate": "180769.24",
      "% Aggregate": "0.23",
      "$ Average": "90384.62",
      "% Interest Rate": "6.35",
    },
    {
      "Loan Type *": "5/1 R30 Prem",
      Count: 3,
      "$ Aggregate": "5509738.66",
      "% Aggregate": "6.97",
      "$ Average": "1836579.55",
      "% Interest Rate": "4.79",
    },
    {
      "Loan Type *": "F2R Commit NF",
      Count: 4,
      "$ Aggregate": "194466.50",
      "% Aggregate": "0.25",
      "$ Average": "48616.63",
      "% Interest Rate": "9.22",
    },
    {
      "Loan Type *": "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "7939236.64",
      "% Interest Rate": "136.59",
    },
  ],
  CurrentMaturityDate: [
    {
      "Current Maturity Date": 2021,
      Count: 223,
      "$ Aggregate": "29395207.33",
      "% Aggregate": "37.20",
      "$ Average": "131817.07",
      "% Interest Rate": "8.97",
    },
    {
      "Current Maturity Date": 2050,
      Count: 160,
      "$ Aggregate": "33718891.46",
      "% Aggregate": "42.67",
      "$ Average": "210743.07",
      "% Interest Rate": "5.51",
    },
    {
      "Current Maturity Date": 2020,
      Count: 39,
      "$ Aggregate": "5956473.02",
      "% Aggregate": "7.54",
      "$ Average": "152730.08",
      "% Interest Rate": "9.73",
    },
    {
      "Current Maturity Date": 2022,
      Count: 15,
      "$ Aggregate": "8199959.45",
      "% Aggregate": "10.38",
      "$ Average": "546663.96",
      "% Interest Rate": "7.43",
    },
    {
      "Current Maturity Date": 2019,
      Count: 1,
      "$ Aggregate": "89995.50",
      "% Aggregate": "0.11",
      "$ Average": "89995.50",
      "% Interest Rate": "10.50",
    },
    {
      "Current Maturity Date": 2030,
      Count: 1,
      "$ Aggregate": "1653667.85",
      "% Aggregate": "2.09",
      "$ Average": "1653667.85",
      "% Interest Rate": "5.50",
    },
    {
      "Current Maturity Date": "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "2785617.53",
      "% Interest Rate": "47.64",
    },
  ],
  LoanExtended: [
    {
      "Loan Extended": "No",
      Count: 425,
      "$ Aggregate": "76306694.68",
      "% Aggregate": "96.57",
      "$ Average": "179545.16",
      "% Interest Rate": "7.25",
    },
    {
      "Loan Extended": "Yes",
      Count: 14,
      "$ Aggregate": "2707499.93",
      "% Aggregate": "3.43",
      "$ Average": "193392.85",
      "% Interest Rate": "9.25",
    },
    {
      "Loan Extended": "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "372938.01",
      "% Interest Rate": "16.50",
    },
  ],
  RemainingTerm: [
    {
      "Remaining Term months": "< 0",
      Count: 16,
      "$ Aggregate": "3206694.97",
      "% Aggregate": "4.06",
      "$ Average": "200418.44",
      "% Interest Rate": "9.30",
    },
    {
      "Remaining Term months": "0 - 100",
      Count: 263,
      "$ Aggregate": "40434940.33",
      "% Aggregate": "51.17",
      "$ Average": "153745.02",
      "% Interest Rate": "8.75",
    },
    {
      "Remaining Term months": "100 - 200",
      Count: 1,
      "$ Aggregate": "1653667.85",
      "% Aggregate": "2.09",
      "$ Average": "1653667.85",
      "% Interest Rate": "5.50",
    },
    {
      "Remaining Term months": "300 - 400",
      Count: 159,
      "$ Aggregate": "33718891.46",
      "% Aggregate": "42.67",
      "$ Average": "212068.50",
      "% Interest Rate": "5.51",
    },
    {
      "Remaining Term months": "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "2219899.81",
      "% Interest Rate": "29.06",
    },
  ],
  InvestorRate: [
    {
      "Investor Rate": "0 - 10",
      Count: 439,
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "179986.78",
      "% Interest Rate": "7.32",
    },
    {
      "Investor Rate": "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "179986.78",
      "% Interest Rate": "7.32",
    },
  ],
  EndingPrincipalBalance: [
    {
      "Ending Principal Balance": "< 0",
      Count: 8,
      "$ Aggregate": "-110692.76",
      "% Aggregate": "0.14",
      "$ Average": "-13836.59",
      "% Interest Rate": "8.01",
    },
    {
      "Ending Principal Balance": "0 - 10000",
      Count: 25,
      "$ Aggregate": "2125.00",
      "% Aggregate": "0.00",
      "$ Average": "85.00",
      "% Interest Rate": "10.00",
    },
    {
      "Ending Principal Balance": "10000 - 50000",
      Count: 24,
      "$ Aggregate": "952216.36",
      "% Aggregate": "1.21",
      "$ Average": "39675.68",
      "% Interest Rate": "8.68",
    },
    {
      "Ending Principal Balance": "50000 - 100000",
      Count: 147,
      "$ Aggregate": "11016259.57",
      "% Aggregate": "13.94",
      "$ Average": "74940.54",
      "% Interest Rate": "7.89",
    },
    {
      "Ending Principal Balance": "100000 - 500000",
      Count: 212,
      "$ Aggregate": "39203520.21",
      "% Aggregate": "49.62",
      "$ Average": "184922.27",
      "% Interest Rate": "7.95",
    },
    {
      "Ending Principal Balance": "500000 - 1000000",
      Count: 13,
      "$ Aggregate": "8967746.36",
      "% Aggregate": "11.35",
      "$ Average": "689826.64",
      "% Interest Rate": "6.97",
    },
    {
      "Ending Principal Balance": "1000000 +",
      Count: 10,
      "$ Aggregate": "18983019.87",
      "% Aggregate": "24.02",
      "$ Average": "1898301.99",
      "% Interest Rate": "5.77",
    },
    {
      "Ending Principal Balance": "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "2873915.53",
      "% Interest Rate": "55.28",
    },
  ],
  City: [
    {
      City: "Palm Bay",
      Count: 5,
      "$ Aggregate": "208662.48",
      "% Aggregate": "0.26",
      "$ Average": "41732.50",
      "% Interest Rate": "9.53",
    },
    {
      City: "Southport",
      Count: 1,
      "$ Aggregate": "284000.00",
      "% Aggregate": "0.36",
      "$ Average": "284000.00",
      "% Interest Rate": "7.25",
    },
    {
      City: "Millington",
      Count: 1,
      "$ Aggregate": "55734.13",
      "% Aggregate": "0.07",
      "$ Average": "55734.13",
      "% Interest Rate": "5.80",
    },
    {
      City: "Hephzibah",
      Count: 1,
      "$ Aggregate": "39500.00",
      "% Aggregate": "0.05",
      "$ Average": "39500.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "Berwyn",
      Count: 2,
      "$ Aggregate": "303398.68",
      "% Aggregate": "0.38",
      "$ Average": "151699.34",
      "% Interest Rate": "9.40",
    },
    {
      City: "Pequot Lakes",
      Count: 1,
      "$ Aggregate": "119752.82",
      "% Aggregate": "0.15",
      "$ Average": "119752.82",
      "% Interest Rate": "6.90",
    },
    {
      City: "Philadelphia",
      Count: 24,
      "$ Aggregate": "4808848.99",
      "% Aggregate": "6.09",
      "$ Average": "200368.71",
      "% Interest Rate": "6.52",
    },
    {
      City: "Senoia",
      Count: 1,
      "$ Aggregate": "93308.94",
      "% Aggregate": "0.12",
      "$ Average": "93308.94",
      "% Interest Rate": "4.65",
    },
    {
      City: "Scranton",
      Count: 4,
      "$ Aggregate": "323508.19",
      "% Aggregate": "0.41",
      "$ Average": "80877.05",
      "% Interest Rate": "7.99",
    },
    {
      City: "Easley",
      Count: 3,
      "$ Aggregate": "235668.55",
      "% Aggregate": "0.30",
      "$ Average": "78556.18",
      "% Interest Rate": "8.88",
    },
    {
      City: "Columbus",
      Count: 20,
      "$ Aggregate": "1952856.24",
      "% Aggregate": "2.47",
      "$ Average": "97642.81",
      "% Interest Rate": "8.01",
    },
    {
      City: "Pensacola",
      Count: 1,
      "$ Aggregate": "79934.35",
      "% Aggregate": "0.10",
      "$ Average": "79934.35",
      "% Interest Rate": "5.45",
    },
    {
      City: "Hampton",
      Count: 2,
      "$ Aggregate": "231492.32",
      "% Aggregate": "0.29",
      "$ Average": "115746.16",
      "% Interest Rate": "10.27",
    },
    {
      City: "Baltimore",
      Count: 26,
      "$ Aggregate": "4443259.82",
      "% Aggregate": "5.62",
      "$ Average": "170894.61",
      "% Interest Rate": "6.20",
    },
    {
      City: "Greenville",
      Count: 4,
      "$ Aggregate": "4059548.19",
      "% Aggregate": "5.14",
      "$ Average": "1014887.05",
      "% Interest Rate": "5.50",
    },
    {
      City: "Gastonia",
      Count: 2,
      "$ Aggregate": "185165.07",
      "% Aggregate": "0.23",
      "$ Average": "92582.54",
      "% Interest Rate": "5.83",
    },
    {
      City: "Riverdale",
      Count: 2,
      "$ Aggregate": "105635.08",
      "% Aggregate": "0.13",
      "$ Average": "52817.54",
      "% Interest Rate": "9.55",
    },
    {
      City: "Bridgeport",
      Count: 1,
      "$ Aggregate": "115895.13",
      "% Aggregate": "0.15",
      "$ Average": "115895.13",
      "% Interest Rate": "6.91",
    },
    {
      City: "Paterson",
      Count: 1,
      "$ Aggregate": "234945.00",
      "% Aggregate": "0.30",
      "$ Average": "234945.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Chicago",
      Count: 20,
      "$ Aggregate": "2810120.94",
      "% Aggregate": "3.56",
      "$ Average": "140506.05",
      "% Interest Rate": "9.17",
    },
    {
      City: "Charlotte",
      Count: 6,
      "$ Aggregate": "883209.04",
      "% Aggregate": "1.12",
      "$ Average": "147201.51",
      "% Interest Rate": "7.60",
    },
    {
      City: "Imperial",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Bedford",
      Count: 1,
      "$ Aggregate": "61339.54",
      "% Aggregate": "0.08",
      "$ Average": "61339.54",
      "% Interest Rate": "6.38",
    },
    {
      City: "Hillside",
      Count: 1,
      "$ Aggregate": "154977.92",
      "% Aggregate": "0.20",
      "$ Average": "154977.92",
      "% Interest Rate": "9.00",
    },
    {
      City: "Pontiac",
      Count: 2,
      "$ Aggregate": "262264.22",
      "% Aggregate": "0.33",
      "$ Average": "131132.11",
      "% Interest Rate": "6.39",
    },
    {
      City: "Cleveland",
      Count: 3,
      "$ Aggregate": "311260.18",
      "% Aggregate": "0.39",
      "$ Average": "103753.39",
      "% Interest Rate": "8.00",
    },
    {
      City: "Hammond",
      Count: 2,
      "$ Aggregate": "154169.57",
      "% Aggregate": "0.20",
      "$ Average": "77084.79",
      "% Interest Rate": "9.70",
    },
    {
      City: "Ashville",
      Count: 1,
      "$ Aggregate": "136492.13",
      "% Aggregate": "0.17",
      "$ Average": "136492.13",
      "% Interest Rate": "10.00",
    },
    {
      City: "Monroe",
      Count: 1,
      "$ Aggregate": "2274940.63",
      "% Aggregate": "2.88",
      "$ Average": "2274940.63",
      "% Interest Rate": "6.49",
    },
    {
      City: "Pittsburgh",
      Count: 1,
      "$ Aggregate": "55241.55",
      "% Aggregate": "0.07",
      "$ Average": "55241.55",
      "% Interest Rate": "11.50",
    },
    {
      City: "Trenton",
      Count: 1,
      "$ Aggregate": "59283.09",
      "% Aggregate": "0.08",
      "$ Average": "59283.09",
      "% Interest Rate": "6.00",
    },
    {
      City: "Fort Walton Beach",
      Count: 1,
      "$ Aggregate": "833328.24",
      "% Aggregate": "1.05",
      "$ Average": "833328.24",
      "% Interest Rate": "4.95",
    },
    {
      City: "Atlanta",
      Count: 12,
      "$ Aggregate": "1746761.98",
      "% Aggregate": "2.21",
      "$ Average": "145563.50",
      "% Interest Rate": "7.60",
    },
    {
      City: "Dolton",
      Count: 1,
      "$ Aggregate": "56920.00",
      "% Aggregate": "0.07",
      "$ Average": "56920.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Lithonia",
      Count: 1,
      "$ Aggregate": "2125.00",
      "% Aggregate": "0.00",
      "$ Average": "2125.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Savannah",
      Count: 2,
      "$ Aggregate": "338378.61",
      "% Aggregate": "0.43",
      "$ Average": "169189.30",
      "% Interest Rate": "5.47",
    },
    {
      City: "North Chesterfield",
      Count: 1,
      "$ Aggregate": "424632.50",
      "% Aggregate": "0.54",
      "$ Average": "424632.50",
      "% Interest Rate": "9.25",
    },
    {
      City: "Detroit",
      Count: 4,
      "$ Aggregate": "1053759.86",
      "% Aggregate": "1.33",
      "$ Average": "263439.97",
      "% Interest Rate": "6.02",
    },
    {
      City: "Newark",
      Count: 3,
      "$ Aggregate": "553160.97",
      "% Aggregate": "0.70",
      "$ Average": "184386.99",
      "% Interest Rate": "8.85",
    },
    {
      City: "Athens",
      Count: 2,
      "$ Aggregate": "851200.00",
      "% Aggregate": "1.08",
      "$ Average": "425600.00",
      "% Interest Rate": "9.90",
    },
    {
      City: "Richmond Heights",
      Count: 1,
      "$ Aggregate": "168673.60",
      "% Aggregate": "0.21",
      "$ Average": "168673.60",
      "% Interest Rate": "11.25",
    },
    {
      City: "Port Saint Lucie",
      Count: 4,
      "$ Aggregate": "244540.80",
      "% Aggregate": "0.31",
      "$ Average": "61135.20",
      "% Interest Rate": "8.48",
    },
    {
      City: "Logan Township",
      Count: 1,
      "$ Aggregate": "139523.25",
      "% Aggregate": "0.18",
      "$ Average": "139523.25",
      "% Interest Rate": "9.25",
    },
    {
      City: "Des moines",
      Count: 1,
      "$ Aggregate": "59155.98",
      "% Aggregate": "0.07",
      "$ Average": "59155.98",
      "% Interest Rate": "6.55",
    },
    {
      City: "Decatur",
      Count: 3,
      "$ Aggregate": "139995.00",
      "% Aggregate": "0.18",
      "$ Average": "46665.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Wilmington",
      Count: 3,
      "$ Aggregate": "315319.82",
      "% Aggregate": "0.40",
      "$ Average": "105106.61",
      "% Interest Rate": "7.17",
    },
    {
      City: "North Syracuse",
      Count: 1,
      "$ Aggregate": "67200.00",
      "% Aggregate": "0.09",
      "$ Average": "67200.00",
      "% Interest Rate": "7.18",
    },
    {
      City: "Jacksonville",
      Count: 4,
      "$ Aggregate": "541470.61",
      "% Aggregate": "0.69",
      "$ Average": "135367.65",
      "% Interest Rate": "7.50",
    },
    {
      City: "Hanahan",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Kiamesha Lake",
      Count: 1,
      "$ Aggregate": "128216.70",
      "% Aggregate": "0.16",
      "$ Average": "128216.70",
      "% Interest Rate": "10.00",
    },
    {
      City: "Pine Hill",
      Count: 1,
      "$ Aggregate": "82880.00",
      "% Aggregate": "0.10",
      "$ Average": "82880.00",
      "% Interest Rate": "9.49",
    },
    {
      City: "Fort Myers",
      Count: 6,
      "$ Aggregate": "806193.00",
      "% Aggregate": "1.02",
      "$ Average": "134365.50",
      "% Interest Rate": "8.43",
    },
    {
      City: "Cockeysville",
      Count: 1,
      "$ Aggregate": "368653.90",
      "% Aggregate": "0.47",
      "$ Average": "368653.90",
      "% Interest Rate": "5.53",
    },
    {
      City: "Sicklerville",
      Count: 1,
      "$ Aggregate": "111988.48",
      "% Aggregate": "0.14",
      "$ Average": "111988.48",
      "% Interest Rate": "10.00",
    },
    {
      City: "Auburn",
      Count: 1,
      "$ Aggregate": "46297.50",
      "% Aggregate": "0.06",
      "$ Average": "46297.50",
      "% Interest Rate": "9.00",
    },
    {
      City: "Cincinatti",
      Count: 1,
      "$ Aggregate": "108271.93",
      "% Aggregate": "0.14",
      "$ Average": "108271.93",
      "% Interest Rate": "6.20",
    },
    {
      City: "New Orleans",
      Count: 1,
      "$ Aggregate": "164180.01",
      "% Aggregate": "0.21",
      "$ Average": "164180.01",
      "% Interest Rate": "8.75",
    },
    {
      City: "Williamsport",
      Count: 1,
      "$ Aggregate": "89534.00",
      "% Aggregate": "0.11",
      "$ Average": "89534.00",
      "% Interest Rate": "9.00",
    },
    {
      City: "VERO BEACH",
      Count: 1,
      "$ Aggregate": "283037.38",
      "% Aggregate": "0.36",
      "$ Average": "283037.38",
      "% Interest Rate": "6.38",
    },
    {
      City: "Parkville",
      Count: 1,
      "$ Aggregate": "1653667.85",
      "% Aggregate": "2.09",
      "$ Average": "1653667.85",
      "% Interest Rate": "5.50",
    },
    {
      City: "East Dublin",
      Count: 1,
      "$ Aggregate": "46399.50",
      "% Aggregate": "0.06",
      "$ Average": "46399.50",
      "% Interest Rate": "8.75",
    },
    {
      City: "Branchville",
      Count: 1,
      "$ Aggregate": "117875.00",
      "% Aggregate": "0.15",
      "$ Average": "117875.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "South Bend",
      Count: 2,
      "$ Aggregate": "158313.07",
      "% Aggregate": "0.20",
      "$ Average": "79156.54",
      "% Interest Rate": "5.92",
    },
    {
      City: "Greensboro",
      Count: 1,
      "$ Aggregate": "119697.35",
      "% Aggregate": "0.15",
      "$ Average": "119697.35",
      "% Interest Rate": "7.10",
    },
    {
      City: "San Antonio",
      Count: 1,
      "$ Aggregate": "46000.00",
      "% Aggregate": "0.06",
      "$ Average": "46000.00",
      "% Interest Rate": "9.25",
    },
    {
      City: "Venice",
      Count: 1,
      "$ Aggregate": "196500.00",
      "% Aggregate": "0.25",
      "$ Average": "196500.00",
      "% Interest Rate": "8.03",
    },
    {
      City: "Carlsbad",
      Count: 1,
      "$ Aggregate": "101485.82",
      "% Aggregate": "0.13",
      "$ Average": "101485.82",
      "% Interest Rate": "11.00",
    },
    {
      City: "Florence",
      Count: 1,
      "$ Aggregate": "670014.15",
      "% Aggregate": "0.85",
      "$ Average": "670014.15",
      "% Interest Rate": "5.83",
    },
    {
      City: "Hendersonville",
      Count: 2,
      "$ Aggregate": "500295.30",
      "% Aggregate": "0.63",
      "$ Average": "250147.65",
      "% Interest Rate": "4.95",
    },
    {
      City: "St. Joseph",
      Count: 1,
      "$ Aggregate": "139044.36",
      "% Aggregate": "0.18",
      "$ Average": "139044.36",
      "% Interest Rate": "5.08",
    },
    {
      City: "East Stroudsburg",
      Count: 1,
      "$ Aggregate": "79875.00",
      "% Aggregate": "0.10",
      "$ Average": "79875.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Petersburg",
      Count: 1,
      "$ Aggregate": "93798.80",
      "% Aggregate": "0.12",
      "$ Average": "93798.80",
      "% Interest Rate": "10.00",
    },
    {
      City: "Fort Wayne",
      Count: 1,
      "$ Aggregate": "72135.00",
      "% Aggregate": "0.09",
      "$ Average": "72135.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Kansas City",
      Count: 2,
      "$ Aggregate": "158640.00",
      "% Aggregate": "0.20",
      "$ Average": "79320.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Rio Rancho",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "N. Charleston",
      Count: 1,
      "$ Aggregate": "146188.23",
      "% Aggregate": "0.19",
      "$ Average": "146188.23",
      "% Interest Rate": "6.75",
    },
    {
      City: "Raleigh",
      Count: 2,
      "$ Aggregate": "461496.40",
      "% Aggregate": "0.58",
      "$ Average": "230748.20",
      "% Interest Rate": "6.18",
    },
    {
      City: "Fairborn",
      Count: 2,
      "$ Aggregate": "135053.68",
      "% Aggregate": "0.17",
      "$ Average": "67526.84",
      "% Interest Rate": "8.20",
    },
    {
      City: "Kannapolis",
      Count: 1,
      "$ Aggregate": "62497.80",
      "% Aggregate": "0.08",
      "$ Average": "62497.80",
      "% Interest Rate": "11.00",
    },
    {
      City: "Powell",
      Count: 1,
      "$ Aggregate": "149380.85",
      "% Aggregate": "0.19",
      "$ Average": "149380.85",
      "% Interest Rate": "5.35",
    },
    {
      City: "Indianapolis",
      Count: 13,
      "$ Aggregate": "1558114.15",
      "% Aggregate": "1.97",
      "$ Average": "119854.93",
      "% Interest Rate": "7.74",
    },
    {
      City: "Richmond",
      Count: 3,
      "$ Aggregate": "210140.02",
      "% Aggregate": "0.27",
      "$ Average": "70046.67",
      "% Interest Rate": "8.28",
    },
    {
      City: "Miami Gardens",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Fresno",
      Count: 1,
      "$ Aggregate": "195043.35",
      "% Aggregate": "0.25",
      "$ Average": "195043.35",
      "% Interest Rate": "4.45",
    },
    {
      City: "Fort Washington",
      Count: 1,
      "$ Aggregate": "186441.79",
      "% Aggregate": "0.24",
      "$ Average": "186441.79",
      "% Interest Rate": "5.45",
    },
    {
      City: "Zion",
      Count: 1,
      "$ Aggregate": "69788.13",
      "% Aggregate": "0.09",
      "$ Average": "69788.13",
      "% Interest Rate": "6.95",
    },
    {
      City: "Hopewell",
      Count: 1,
      "$ Aggregate": "92250.00",
      "% Aggregate": "0.12",
      "$ Average": "92250.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Maple Heights",
      Count: 1,
      "$ Aggregate": "93286.75",
      "% Aggregate": "0.12",
      "$ Average": "93286.75",
      "% Interest Rate": "5.05",
    },
    {
      City: "Portsmouth",
      Count: 3,
      "$ Aggregate": "234345.29",
      "% Aggregate": "0.30",
      "$ Average": "78115.10",
      "% Interest Rate": "9.02",
    },
    {
      City: "Lansing",
      Count: 1,
      "$ Aggregate": "265714.89",
      "% Aggregate": "0.34",
      "$ Average": "265714.89",
      "% Interest Rate": "5.68",
    },
    {
      City: "Oak Park",
      Count: 1,
      "$ Aggregate": "125463.50",
      "% Aggregate": "0.16",
      "$ Average": "125463.50",
      "% Interest Rate": "9.25",
    },
    {
      City: "Irvington",
      Count: 2,
      "$ Aggregate": "245357.50",
      "% Aggregate": "0.31",
      "$ Average": "122678.75",
      "% Interest Rate": "8.66",
    },
    {
      City: "Euclid",
      Count: 1,
      "$ Aggregate": "179975.28",
      "% Aggregate": "0.23",
      "$ Average": "179975.28",
      "% Interest Rate": "5.15",
    },
    {
      City: "Stockbridge",
      Count: 1,
      "$ Aggregate": "85848.24",
      "% Aggregate": "0.11",
      "$ Average": "85848.24",
      "% Interest Rate": "5.90",
    },
    {
      City: "Sunman",
      Count: 1,
      "$ Aggregate": "-6573.04",
      "% Aggregate": "-0.01",
      "$ Average": "-6573.04",
      "% Interest Rate": "8.25",
    },
    {
      City: "Monrovia",
      Count: 1,
      "$ Aggregate": "125685.00",
      "% Aggregate": "0.16",
      "$ Average": "125685.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Cape Coral",
      Count: 2,
      "$ Aggregate": "37210.74",
      "% Aggregate": "0.05",
      "$ Average": "18605.37",
      "% Interest Rate": "9.00",
    },
    {
      City: "Baton Rouge",
      Count: 2,
      "$ Aggregate": "141588.75",
      "% Aggregate": "0.18",
      "$ Average": "70794.38",
      "% Interest Rate": "11.27",
    },
    {
      City: "N Charleston",
      Count: 1,
      "$ Aggregate": "172250.00",
      "% Aggregate": "0.22",
      "$ Average": "172250.00",
      "% Interest Rate": "8.73",
    },
    {
      City: "Excelsior Springs",
      Count: 1,
      "$ Aggregate": "64446.73",
      "% Aggregate": "0.08",
      "$ Average": "64446.73",
      "% Interest Rate": "5.08",
    },
    {
      City: "Evansville",
      Count: 1,
      "$ Aggregate": "135882.25",
      "% Aggregate": "0.17",
      "$ Average": "135882.25",
      "% Interest Rate": "6.05",
    },
    {
      City: "Lincoln Park",
      Count: 1,
      "$ Aggregate": "252000.00",
      "% Aggregate": "0.32",
      "$ Average": "252000.00",
      "% Interest Rate": "7.88",
    },
    {
      City: "Louisville",
      Count: 3,
      "$ Aggregate": "261646.16",
      "% Aggregate": "0.33",
      "$ Average": "87215.39",
      "% Interest Rate": "8.34",
    },
    {
      City: "Cedar Lake",
      Count: 1,
      "$ Aggregate": "422646.35",
      "% Aggregate": "0.53",
      "$ Average": "422646.35",
      "% Interest Rate": "5.10",
    },
    {
      City: "Milwaukee",
      Count: 3,
      "$ Aggregate": "212433.01",
      "% Aggregate": "0.27",
      "$ Average": "70811.00",
      "% Interest Rate": "6.71",
    },
    {
      City: "Madison Heights",
      Count: 2,
      "$ Aggregate": "217172.02",
      "% Aggregate": "0.27",
      "$ Average": "108586.01",
      "% Interest Rate": "8.75",
    },
    {
      City: "Mesquite",
      Count: 1,
      "$ Aggregate": "109952.48",
      "% Aggregate": "0.14",
      "$ Average": "109952.48",
      "% Interest Rate": "4.37",
    },
    {
      City: "Gloucester City",
      Count: 1,
      "$ Aggregate": "77400.00",
      "% Aggregate": "0.10",
      "$ Average": "77400.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Pennsauken",
      Count: 1,
      "$ Aggregate": "128945.00",
      "% Aggregate": "0.16",
      "$ Average": "128945.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "North Augusta",
      Count: 1,
      "$ Aggregate": "133616.82",
      "% Aggregate": "0.17",
      "$ Average": "133616.82",
      "% Interest Rate": "5.73",
    },
    {
      City: "Liverpool",
      Count: 1,
      "$ Aggregate": "98977.50",
      "% Aggregate": "0.13",
      "$ Average": "98977.50",
      "% Interest Rate": "10.00",
    },
    {
      City: "Fayetteville",
      Count: 3,
      "$ Aggregate": "291250.69",
      "% Aggregate": "0.37",
      "$ Average": "97083.56",
      "% Interest Rate": "5.58",
    },
    {
      City: "Marietta",
      Count: 1,
      "$ Aggregate": "317536.20",
      "% Aggregate": "0.40",
      "$ Average": "317536.20",
      "% Interest Rate": "11.50",
    },
    {
      City: "Cincinnati",
      Count: 3,
      "$ Aggregate": "387453.80",
      "% Aggregate": "0.49",
      "$ Average": "129151.27",
      "% Interest Rate": "11.49",
    },
    {
      City: "Cleveland Heights",
      Count: 1,
      "$ Aggregate": "131629.50",
      "% Aggregate": "0.17",
      "$ Average": "131629.50",
      "% Interest Rate": "10.00",
    },
    {
      City: "Port Jervis",
      Count: 1,
      "$ Aggregate": "196000.00",
      "% Aggregate": "0.25",
      "$ Average": "196000.00",
      "% Interest Rate": "7.68",
    },
    {
      City: "Picatinny Arsenal",
      Count: 1,
      "$ Aggregate": "775050.00",
      "% Aggregate": "0.98",
      "$ Average": "775050.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "Holt",
      Count: 1,
      "$ Aggregate": "113400.00",
      "% Aggregate": "0.14",
      "$ Average": "113400.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Dallas",
      Count: 2,
      "$ Aggregate": "668219.61",
      "% Aggregate": "0.85",
      "$ Average": "334109.80",
      "% Interest Rate": "7.92",
    },
    {
      City: "Mount Holly",
      Count: 2,
      "$ Aggregate": "199212.04",
      "% Aggregate": "0.25",
      "$ Average": "99606.02",
      "% Interest Rate": "6.05",
    },
    {
      City: "Santa Rosa",
      Count: 1,
      "$ Aggregate": "453690.00",
      "% Aggregate": "0.57",
      "$ Average": "453690.00",
      "% Interest Rate": "7.25",
    },
    {
      City: "Cordova",
      Count: 2,
      "$ Aggregate": "121821.55",
      "% Aggregate": "0.15",
      "$ Average": "60910.78",
      "% Interest Rate": "7.46",
    },
    {
      City: "Tucker",
      Count: 1,
      "$ Aggregate": "189937.97",
      "% Aggregate": "0.24",
      "$ Average": "189937.97",
      "% Interest Rate": "8.75",
    },
    {
      City: "Deltona",
      Count: 2,
      "$ Aggregate": "136074.44",
      "% Aggregate": "0.17",
      "$ Average": "68037.22",
      "% Interest Rate": "6.80",
    },
    {
      City: "Sevierville",
      Count: 1,
      "$ Aggregate": "38673.61",
      "% Aggregate": "0.05",
      "$ Average": "38673.61",
      "% Interest Rate": "9.25",
    },
    {
      City: "Rock Hill",
      Count: 1,
      "$ Aggregate": "118984.26",
      "% Aggregate": "0.15",
      "$ Average": "118984.26",
      "% Interest Rate": "11.25",
    },
    {
      City: "Anderson",
      Count: 2,
      "$ Aggregate": "189419.37",
      "% Aggregate": "0.24",
      "$ Average": "94709.68",
      "% Interest Rate": "9.77",
    },
    {
      City: "Dayton",
      Count: 2,
      "$ Aggregate": "235303.72",
      "% Aggregate": "0.30",
      "$ Average": "117651.86",
      "% Interest Rate": "5.29",
    },
    {
      City: "Debary",
      Count: 1,
      "$ Aggregate": "188227.21",
      "% Aggregate": "0.24",
      "$ Average": "188227.21",
      "% Interest Rate": "5.40",
    },
    {
      City: "Killeen",
      Count: 3,
      "$ Aggregate": "332073.46",
      "% Aggregate": "0.42",
      "$ Average": "110691.15",
      "% Interest Rate": "7.75",
    },
    {
      City: "Allentown",
      Count: 1,
      "$ Aggregate": "411010.54",
      "% Aggregate": "0.52",
      "$ Average": "411010.54",
      "% Interest Rate": "7.20",
    },
    {
      City: "Trussville",
      Count: 1,
      "$ Aggregate": "470781.22",
      "% Aggregate": "0.60",
      "$ Average": "470781.22",
      "% Interest Rate": "9.49",
    },
    {
      City: "Midwest City",
      Count: 1,
      "$ Aggregate": "93798.63",
      "% Aggregate": "0.12",
      "$ Average": "93798.63",
      "% Interest Rate": "10.00",
    },
    {
      City: "Phoenix",
      Count: 1,
      "$ Aggregate": "125589.29",
      "% Aggregate": "0.16",
      "$ Average": "125589.29",
      "% Interest Rate": "5.45",
    },
    {
      City: "East Point",
      Count: 2,
      "$ Aggregate": "88090.00",
      "% Aggregate": "0.11",
      "$ Average": "44045.00",
      "% Interest Rate": "7.75",
    },
    {
      City: "Manahawkin",
      Count: 3,
      "$ Aggregate": "236554.00",
      "% Aggregate": "0.30",
      "$ Average": "78851.33",
      "% Interest Rate": "10.64",
    },
    {
      City: "Belton",
      Count: 1,
      "$ Aggregate": "61098.49",
      "% Aggregate": "0.08",
      "$ Average": "61098.49",
      "% Interest Rate": "5.80",
    },
    {
      City: "Jamesville",
      Count: 1,
      "$ Aggregate": "115941.38",
      "% Aggregate": "0.15",
      "$ Average": "115941.38",
      "% Interest Rate": "9.25",
    },
    {
      City: "Washington",
      Count: 2,
      "$ Aggregate": "1544425.00",
      "% Aggregate": "1.95",
      "$ Average": "772212.50",
      "% Interest Rate": "6.86",
    },
    {
      City: "Destin",
      Count: 1,
      "$ Aggregate": "167200.00",
      "% Aggregate": "0.21",
      "$ Average": "167200.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Las Vegas",
      Count: 1,
      "$ Aggregate": "1717918.82",
      "% Aggregate": "2.17",
      "$ Average": "1717918.82",
      "% Interest Rate": "6.70",
    },
    {
      City: "Toledo",
      Count: 1,
      "$ Aggregate": "63513.07",
      "% Aggregate": "0.08",
      "$ Average": "63513.07",
      "% Interest Rate": "5.90",
    },
    {
      City: "Rochester",
      Count: 2,
      "$ Aggregate": "120792.96",
      "% Aggregate": "0.15",
      "$ Average": "60396.48",
      "% Interest Rate": "10.20",
    },
    {
      City: "St. Paul",
      Count: 1,
      "$ Aggregate": "253963.39",
      "% Aggregate": "0.32",
      "$ Average": "253963.39",
      "% Interest Rate": "6.58",
    },
    {
      City: "Roswell",
      Count: 1,
      "$ Aggregate": "297060.00",
      "% Aggregate": "0.38",
      "$ Average": "297060.00",
      "% Interest Rate": "9.49",
    },
    {
      City: "Saint Louis",
      Count: 3,
      "$ Aggregate": "227978.69",
      "% Aggregate": "0.29",
      "$ Average": "75992.90",
      "% Interest Rate": "8.03",
    },
    {
      City: "Conley",
      Count: 1,
      "$ Aggregate": "576632.45",
      "% Aggregate": "0.73",
      "$ Average": "576632.45",
      "% Interest Rate": "5.08",
    },
    {
      City: "Lake Wales",
      Count: 1,
      "$ Aggregate": "104547.82",
      "% Aggregate": "0.13",
      "$ Average": "104547.82",
      "% Interest Rate": "5.13",
    },
    {
      City: "Live Oak",
      Count: 1,
      "$ Aggregate": "133209.47",
      "% Aggregate": "0.17",
      "$ Average": "133209.47",
      "% Interest Rate": "4.63",
    },
    {
      City: "Seattle",
      Count: 1,
      "$ Aggregate": "2040500.00",
      "% Aggregate": "2.58",
      "$ Average": "2040500.00",
      "% Interest Rate": "8.75",
    },
    {
      City: "Angleton",
      Count: 1,
      "$ Aggregate": "142787.99",
      "% Aggregate": "0.18",
      "$ Average": "142787.99",
      "% Interest Rate": "10.00",
    },
    {
      City: "Palmetto",
      Count: 1,
      "$ Aggregate": "129498.43",
      "% Aggregate": "0.16",
      "$ Average": "129498.43",
      "% Interest Rate": "10.00",
    },
    {
      City: "Orange",
      Count: 1,
      "$ Aggregate": "105570.00",
      "% Aggregate": "0.13",
      "$ Average": "105570.00",
      "% Interest Rate": "11.50",
    },
    {
      City: "Memphis",
      Count: 3,
      "$ Aggregate": "1573368.46",
      "% Aggregate": "1.99",
      "$ Average": "524456.15",
      "% Interest Rate": "5.60",
    },
    {
      City: "Owings Mills",
      Count: 1,
      "$ Aggregate": "120800.00",
      "% Aggregate": "0.15",
      "$ Average": "120800.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Poinciana",
      Count: 2,
      "$ Aggregate": "153900.01",
      "% Aggregate": "0.19",
      "$ Average": "76950.01",
      "% Interest Rate": "7.50",
    },
    {
      City: "Camden",
      Count: 1,
      "$ Aggregate": "86654.06",
      "% Aggregate": "0.11",
      "$ Average": "86654.06",
      "% Interest Rate": "5.53",
    },
    {
      City: "Akron",
      Count: 2,
      "$ Aggregate": "313977.52",
      "% Aggregate": "0.40",
      "$ Average": "156988.76",
      "% Interest Rate": "9.03",
    },
    {
      City: "Cayce",
      Count: 1,
      "$ Aggregate": "87345.00",
      "% Aggregate": "0.11",
      "$ Average": "87345.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Youngstown",
      Count: 1,
      "$ Aggregate": "106818.23",
      "% Aggregate": "0.14",
      "$ Average": "106818.23",
      "% Interest Rate": "5.48",
    },
    {
      City: "Houston",
      Count: 2,
      "$ Aggregate": "421000.65",
      "% Aggregate": "0.53",
      "$ Average": "210500.33",
      "% Interest Rate": "9.43",
    },
    {
      City: "Hamilton",
      Count: 1,
      "$ Aggregate": "105036.94",
      "% Aggregate": "0.13",
      "$ Average": "105036.94",
      "% Interest Rate": "4.52",
    },
    {
      City: "Pompano Beach",
      Count: 1,
      "$ Aggregate": "157774.64",
      "% Aggregate": "0.20",
      "$ Average": "157774.64",
      "% Interest Rate": "6.75",
    },
    {
      City: "Oklahoma City",
      Count: 2,
      "$ Aggregate": "205988.55",
      "% Aggregate": "0.26",
      "$ Average": "102994.27",
      "% Interest Rate": "10.12",
    },
    {
      City: "Chatsworth",
      Count: 1,
      "$ Aggregate": "397800.00",
      "% Aggregate": "0.50",
      "$ Average": "397800.00",
      "% Interest Rate": "9.50",
    },
    {
      City: "San Jose",
      Count: 1,
      "$ Aggregate": "1012000.00",
      "% Aggregate": "1.28",
      "$ Average": "1012000.00",
      "% Interest Rate": "6.50",
    },
    {
      City: "Woodbury",
      Count: 1,
      "$ Aggregate": "66162.28",
      "% Aggregate": "0.08",
      "$ Average": "66162.28",
      "% Interest Rate": "5.43",
    },
    {
      City: "Irwin",
      Count: 1,
      "$ Aggregate": "122496.87",
      "% Aggregate": "0.16",
      "$ Average": "122496.87",
      "% Interest Rate": "10.00",
    },
    {
      City: "Peekskill",
      Count: 1,
      "$ Aggregate": "267538.58",
      "% Aggregate": "0.34",
      "$ Average": "267538.58",
      "% Interest Rate": "6.38",
    },
    {
      City: "Yucca Valley",
      Count: 1,
      "$ Aggregate": "52149.18",
      "% Aggregate": "0.07",
      "$ Average": "52149.18",
      "% Interest Rate": "5.80",
    },
    {
      City: "Scottdale",
      Count: 2,
      "$ Aggregate": "-24485.20",
      "% Aggregate": "-0.03",
      "$ Average": "-12242.60",
      "% Interest Rate": "11.16",
    },
    {
      City: "Huntington",
      Count: 1,
      "$ Aggregate": "67121.70",
      "% Aggregate": "0.08",
      "$ Average": "67121.70",
      "% Interest Rate": "10.50",
    },
    {
      City: "Dunedin",
      Count: 1,
      "$ Aggregate": "335700.00",
      "% Aggregate": "0.42",
      "$ Average": "335700.00",
      "% Interest Rate": "11.49",
    },
    {
      City: "Abilene",
      Count: 1,
      "$ Aggregate": "87646.77",
      "% Aggregate": "0.11",
      "$ Average": "87646.77",
      "% Interest Rate": "6.68",
    },
    {
      City: "Lake Charles",
      Count: 2,
      "$ Aggregate": "171506.12",
      "% Aggregate": "0.22",
      "$ Average": "85753.06",
      "% Interest Rate": "8.49",
    },
    {
      City: "Phoneix",
      Count: 1,
      "$ Aggregate": "208800.00",
      "% Aggregate": "0.26",
      "$ Average": "208800.00",
      "% Interest Rate": "7.53",
    },
    {
      City: "Conover",
      Count: 1,
      "$ Aggregate": "95922.51",
      "% Aggregate": "0.12",
      "$ Average": "95922.51",
      "% Interest Rate": "7.75",
    },
    {
      City: "Phillipsburg",
      Count: 1,
      "$ Aggregate": "115405.64",
      "% Aggregate": "0.15",
      "$ Average": "115405.64",
      "% Interest Rate": "5.40",
    },
    {
      City: "Mishawaka",
      Count: 2,
      "$ Aggregate": "161980.24",
      "% Aggregate": "0.21",
      "$ Average": "80990.12",
      "% Interest Rate": "6.65",
    },
    {
      City: "Leesburg",
      Count: 1,
      "$ Aggregate": "620331.38",
      "% Aggregate": "0.79",
      "$ Average": "620331.38",
      "% Interest Rate": "4.68",
    },
    {
      City: "Alpharetta",
      Count: 2,
      "$ Aggregate": "1155773.86",
      "% Aggregate": "1.46",
      "$ Average": "577886.93",
      "% Interest Rate": "9.82",
    },
    {
      City: "Carnegie",
      Count: 1,
      "$ Aggregate": "67581.15",
      "% Aggregate": "0.09",
      "$ Average": "67581.15",
      "% Interest Rate": "6.38",
    },
    {
      City: "North Charleston",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "New Kensington",
      Count: 1,
      "$ Aggregate": "136921.64",
      "% Aggregate": "0.17",
      "$ Average": "136921.64",
      "% Interest Rate": "5.25",
    },
    {
      City: "Orange City",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Jackson",
      Count: 1,
      "$ Aggregate": "82058.96",
      "% Aggregate": "0.10",
      "$ Average": "82058.96",
      "% Interest Rate": "6.95",
    },
    {
      City: "Ellenwood",
      Count: 1,
      "$ Aggregate": "156960.00",
      "% Aggregate": "0.20",
      "$ Average": "156960.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "Tinley Park",
      Count: 1,
      "$ Aggregate": "52317.90",
      "% Aggregate": "0.07",
      "$ Average": "52317.90",
      "% Interest Rate": "7.40",
    },
    {
      City: "EASTPOINTE",
      Count: 1,
      "$ Aggregate": "370177.59",
      "% Aggregate": "0.47",
      "$ Average": "370177.59",
      "% Interest Rate": "6.53",
    },
    {
      City: "Tampa",
      Count: 1,
      "$ Aggregate": "278370.00",
      "% Aggregate": "0.35",
      "$ Average": "278370.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Madison",
      Count: 2,
      "$ Aggregate": "276707.22",
      "% Aggregate": "0.35",
      "$ Average": "138353.61",
      "% Interest Rate": "5.26",
    },
    {
      City: "Spring Hill",
      Count: 1,
      "$ Aggregate": "54992.56",
      "% Aggregate": "0.07",
      "$ Average": "54992.56",
      "% Interest Rate": "5.88",
    },
    {
      City: "Fairburn",
      Count: 1,
      "$ Aggregate": "1649161.15",
      "% Aggregate": "2.09",
      "$ Average": "1649161.15",
      "% Interest Rate": "4.45",
    },
    {
      City: "Garfield Heights",
      Count: 1,
      "$ Aggregate": "66600.00",
      "% Aggregate": "0.08",
      "$ Average": "66600.00",
      "% Interest Rate": "11.00",
    },
    {
      City: "Columbia",
      Count: 1,
      "$ Aggregate": "310771.54",
      "% Aggregate": "0.39",
      "$ Average": "310771.54",
      "% Interest Rate": "10.00",
    },
    {
      City: "Kissimmee",
      Count: 1,
      "$ Aggregate": "128800.00",
      "% Aggregate": "0.16",
      "$ Average": "128800.00",
      "% Interest Rate": "8.08",
    },
    {
      City: "Marcus Hook",
      Count: 1,
      "$ Aggregate": "91513.35",
      "% Aggregate": "0.12",
      "$ Average": "91513.35",
      "% Interest Rate": "5.60",
    },
    {
      City: "Brookhaven",
      Count: 1,
      "$ Aggregate": "399000.00",
      "% Aggregate": "0.50",
      "$ Average": "399000.00",
      "% Interest Rate": "7.40",
    },
    {
      City: "Bremerton",
      Count: 1,
      "$ Aggregate": "242861.38",
      "% Aggregate": "0.31",
      "$ Average": "242861.38",
      "% Interest Rate": "6.00",
    },
    {
      City: "Port St. Lucie",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Front Royal",
      Count: 2,
      "$ Aggregate": "221600.00",
      "% Aggregate": "0.28",
      "$ Average": "110800.00",
      "% Interest Rate": "7.50",
    },
    {
      City: "Loves Park",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "Grandview",
      Count: 1,
      "$ Aggregate": "285688.31",
      "% Aggregate": "0.36",
      "$ Average": "285688.31",
      "% Interest Rate": "5.98",
    },
    {
      City: "Markham",
      Count: 1,
      "$ Aggregate": "42075.00",
      "% Aggregate": "0.05",
      "$ Average": "42075.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "College Park",
      Count: 2,
      "$ Aggregate": "910815.01",
      "% Aggregate": "1.15",
      "$ Average": "455407.51",
      "% Interest Rate": "7.29",
    },
    {
      City: "Mableton",
      Count: 1,
      "$ Aggregate": "115014.16",
      "% Aggregate": "0.15",
      "$ Average": "115014.16",
      "% Interest Rate": "5.25",
    },
    {
      City: "High Point",
      Count: 1,
      "$ Aggregate": "52644.80",
      "% Aggregate": "0.07",
      "$ Average": "52644.80",
      "% Interest Rate": "11.00",
    },
    {
      City: "Montgomery",
      Count: 1,
      "$ Aggregate": "77355.00",
      "% Aggregate": "0.10",
      "$ Average": "77355.00",
      "% Interest Rate": "8.25",
    },
    {
      City: "McDonough",
      Count: 1,
      "$ Aggregate": "83699.65",
      "% Aggregate": "0.11",
      "$ Average": "83699.65",
      "% Interest Rate": "6.10",
    },
    {
      City: "Waynesville",
      Count: 1,
      "$ Aggregate": "48994.00",
      "% Aggregate": "0.06",
      "$ Average": "48994.00",
      "% Interest Rate": "8.75",
    },
    {
      City: "Radford",
      Count: 1,
      "$ Aggregate": "92530.79",
      "% Aggregate": "0.12",
      "$ Average": "92530.79",
      "% Interest Rate": "10.25",
    },
    {
      City: "West Allis",
      Count: 1,
      "$ Aggregate": "126690.87",
      "% Aggregate": "0.16",
      "$ Average": "126690.87",
      "% Interest Rate": "10.50",
    },
    {
      City: "Prairie View",
      Count: 1,
      "$ Aggregate": "96787.15",
      "% Aggregate": "0.12",
      "$ Average": "96787.15",
      "% Interest Rate": "5.25",
    },
    {
      City: "Goose Creek",
      Count: 1,
      "$ Aggregate": "161850.00",
      "% Aggregate": "0.20",
      "$ Average": "161850.00",
      "% Interest Rate": "7.53",
    },
    {
      City: "Muskegon",
      Count: 1,
      "$ Aggregate": "88189.64",
      "% Aggregate": "0.11",
      "$ Average": "88189.64",
      "% Interest Rate": "6.20",
    },
    {
      City: "Pendleton",
      Count: 1,
      "$ Aggregate": "138040.00",
      "% Aggregate": "0.17",
      "$ Average": "138040.00",
      "% Interest Rate": "11.50",
    },
    {
      City: "Kingwood",
      Count: 1,
      "$ Aggregate": "228868.94",
      "% Aggregate": "0.29",
      "$ Average": "228868.94",
      "% Interest Rate": "10.00",
    },
    {
      City: "Augusta",
      Count: 1,
      "$ Aggregate": "255116.31",
      "% Aggregate": "0.32",
      "$ Average": "255116.31",
      "% Interest Rate": "4.75",
    },
    {
      City: "Miami",
      Count: 1,
      "$ Aggregate": "247000.00",
      "% Aggregate": "0.31",
      "$ Average": "247000.00",
      "% Interest Rate": "7.73",
    },
    {
      City: "Newburgh Heights",
      Count: 1,
      "$ Aggregate": "111432.95",
      "% Aggregate": "0.14",
      "$ Average": "111432.95",
      "% Interest Rate": "5.80",
    },
    {
      City: "Los Angeles",
      Count: 1,
      "$ Aggregate": "446041.90",
      "% Aggregate": "0.56",
      "$ Average": "446041.90",
      "% Interest Rate": "8.50",
    },
    {
      City: "Jersey City",
      Count: 1,
      "$ Aggregate": "361529.78",
      "% Aggregate": "0.46",
      "$ Average": "361529.78",
      "% Interest Rate": "5.45",
    },
    {
      City: "Northbrook",
      Count: 1,
      "$ Aggregate": "587370.00",
      "% Aggregate": "0.74",
      "$ Average": "587370.00",
      "% Interest Rate": "8.50",
    },
    {
      City: "Center Point",
      Count: 1,
      "$ Aggregate": "57420.00",
      "% Aggregate": "0.07",
      "$ Average": "57420.00",
      "% Interest Rate": "10.00",
    },
    {
      City: "Birmingham",
      Count: 1,
      "$ Aggregate": "54720.00",
      "% Aggregate": "0.07",
      "$ Average": "54720.00",
      "% Interest Rate": "9.00",
    },
    {
      City: "Saint Petersburg",
      Count: 1,
      "$ Aggregate": "220002.41",
      "% Aggregate": "0.28",
      "$ Average": "220002.41",
      "% Interest Rate": "9.75",
    },
    {
      City: "Spartanburg",
      Count: 1,
      "$ Aggregate": "95614.84",
      "% Aggregate": "0.12",
      "$ Average": "95614.84",
      "% Interest Rate": "5.50",
    },
    {
      City: "Penns Grove",
      Count: 1,
      "$ Aggregate": "58530.13",
      "% Aggregate": "0.07",
      "$ Average": "58530.13",
      "% Interest Rate": "5.90",
    },
    {
      City: "Jonesboro",
      Count: 1,
      "$ Aggregate": "0.00",
      "% Aggregate": "0.00",
      "$ Average": "0.00",
      "% Interest Rate": "0.00",
    },
    {
      City: "St Petersburg",
      Count: 1,
      "$ Aggregate": "83745.77",
      "% Aggregate": "0.11",
      "$ Average": "83745.77",
      "% Interest Rate": "6.95",
    },
    {
      City: "Orlando",
      Count: 1,
      "$ Aggregate": "447920.88",
      "% Aggregate": "0.57",
      "$ Average": "447920.88",
      "% Interest Rate": "9.50",
    },
    {
      City: "Wyoming",
      Count: 1,
      "$ Aggregate": "102368.12",
      "% Aggregate": "0.13",
      "$ Average": "102368.12",
      "% Interest Rate": "5.90",
    },
    {
      City: "Somers Point",
      Count: 1,
      "$ Aggregate": "91100.00",
      "% Aggregate": "0.12",
      "$ Average": "91100.00",
      "% Interest Rate": "7.95",
    },
    {
      City: "Hartford",
      Count: 1,
      "$ Aggregate": "94491.97",
      "% Aggregate": "0.12",
      "$ Average": "94491.97",
      "% Interest Rate": "11.00",
    },
    {
      City: "Total:",
      Count: "439",
      "$ Aggregate": "79014194.61",
      "% Aggregate": "100.00",
      "$ Average": "46765935.13",
      "% Interest Rate": "1764.85",
    },
  ],
  tableData: [
    {
      item0: "Loan Type",
      item1: "Subcategory",
      item2: "Summary",
      item3: "Property Type",
      item4: "New Construction",
    },
    {
      item0: "10/1 R30",
      item1: "Rental",
      item2: "10/1 Hybrid Arm",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "5/1 R30",
      item1: "Rental",
      item2: "5/1 Hybrid Arm",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "Bridge + PO",
      item1: "FNF",
      item2: "Interest Only Bridge Loan with No construction holdback",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "Commit NF",
      item1: "FNF",
      item2: "Interest only Bridge loan with a committed construction holdback",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "Commit MF",
      item1: "Multifamily",
      item2: "Interest only Bridge loan with a committed construction holdback",
      item3: "5+ Units",
      item4: "No",
    },
    {
      item0: "Commit NC NF",
      item1: "FNF",
      item2: "Interest only Bridge loan with a committed construction holdback",
      item3: "1-4 Family",
      item4: "Yes",
    },
    {
      item0: "CRL Fixed R30",
      item1: "Rental",
      item2: "30 Year fixed rate loan originated through correspondent channel",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "F2R 10/1 R30",
      item1: "Rental",
      item2: "10/1 Hybrid Arm,refinanced out of a L1C Bridge loan",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "F2R Commit NF",
      item1: "FNF",
      item2:
        "Interest only Bridge loan with a committed construction holdback, option to refinance into a new rental Loan",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "F2R Fixed R30",
      item1: "Rental",
      item2: "30 Year fixed Rate Loan, refinanced out of a L1C Bridge Loan ",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "Fixed R30",
      item1: "Rental",
      item2: "30 Year fixed Rate Loan ",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "Fixed R30 Premier",
      item1: "Rental",
      item2: "30 Year fixed rate loan - Premier Sponsor",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "FNF Cash-Out",
      item1: "FNF",
      item2:
        "Interest Only Bridge, on property owned free and clear,no construction holdback",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "Fully Escrow",
      item1: "FNF",
      item2:
        "Interest only Bridge loan with a funded escrow account for construction holdback",
      item3: "1-4 Family",
      item4: "No",
    },
    {
      item0: "Fully Escrow NC",
      item1: "FNF",
      item2:
        "Interest only Bridge loan with a funded escrow account for construction holdback",
      item3: "1-4 Family",
      item4: "Yes",
    },
  ],
};

export const InvestorDummyData = {
  DealContactInformation: {
    dealid: "L1C 2020-1, LLC",
    distributiondate: "9/25/2020",
    reporttype: "Monthly Paying Agent",
    relationshipmanager: "Devon Almeida",
    address: "500 Delaware Ave, 11th Floor, Wilmington, DE 19801",
    email: "dalmeida@wsfsbank.com",
    websitereporting: "www.wsfsabsreporting.com",
    addtionalfirst: " ",
    additionallast: " ",
  },
  Date: [
    {
      ClosingDate: "6/22/2020",
      "1stPaymentDate": "7/27/2020",
      OriginalRevolvingPeriodEndDate: "6/25/2021",
      MaturityDate: "8/25/2051  ",
      PreviousPaymentDate: "8/27/2020",
      NextPaymentDate: "9/27/2020",
      "Number of Days in Accrual Period": "30",
    },
  ],
  PaymentSummary: [
    {
      Class: "A Notes",
      BeginningBalance: "65000000.00",
      InterestPaid: "286541.67",
      PrincipalPaid: "0.00",
      TotalPaid: "286541.67",
      EndingBalance: "65000000.00",
    },
    {
      Class: "Retained Certificates",
      BeginningBalance: "35000000.00",
      InterestPaid: "",
      PrincipalPaid: "",
      TotalPaid: "1682800.03",
      EndingBalance: "35000000.00",
    },
    {
      Class: "Total:",
      BeginningBalance: "100000000.00",
      InterestPaid: "286541.67",
      PrincipalPaid: "0.00",
      TotalPaid: "1969341.70",
      EndingBalance: "100000000.00",
    },
  ],
  PrincipalPayments: [
    {
      Class: "A Notes",
      OriginalBalance: "65000000",
      BeginningBalance: "65000000.00",
      PrincipalOwed: "0.00",
      PrincipalPaid: "0.00",
      EndingBalance: "65000000.00",
      EndingFactor: "1.00",
    },
    {
      Class: "Total:",
      OriginalBalance: "65000000",
      BeginningBalance: "65000000.00",
      PrincipalOwed: "0.00",
      PrincipalPaid: "0.00",
      EndingBalance: "65000000.00",
      EndingFactor: "",
    },
  ],
  InterestPayments: [
    {
      Class: "A Notes",
      InterestRate: "5.29",
      BeginningBalance: "65000000.00",
      InterestOwed: "286541.67",
      InterestShortfall: "0.00",
      InterestPaid: "286541.67",
      InterestUnpaid: "0.00",
    },
    {
      Class: "Total:",
      InterestRate: "",
      BeginningBalance: "65000000.00",
      InterestOwed: "286541.67",
      InterestShortfall: "0.00",
      InterestPaid: "286541.67",
      InterestUnpaid: "0.00",
    },
  ],
  ClassFactors: [
    {
      Notes: "A Notes",
      CUSIP: "50213DAA7",
      OriginalBalance: "65000000",
      PriorBalance: "1000.0000000",
      InterestDistribution: "4.4083333",
      PrincipalDistribution: "0.0000000",
      TotalDistribution: "4.4083333",
      EndingBalance: "1000.0000000",
    },
  ],
  AccountStatements: [
    [
      { key: "COLLATERAL BALANCE", value1: "", value2: "" },
      {
        key: "",
        value1: "Number of Loans",
        value2: "Unpaid Principal Balance",
      },
      {
        key: "Beginning Balance As Of Determination Date",
        value1: "453.00",
        value2: "81888420.74",
      },
      { key: "Additions", value1: "", value2: "" },
      {
        key: "Funding of Construction Draws",
        value1: "",
        value2: "1072592.32",
      },
      {
        key: "Purchase of Additional Mortgage Loans",
        value1: "",
        value2: "0.00",
      },
      { key: "Other Principal", value1: "", value2: "0.00" },
      { key: "Total:", value1: "0.00", value2: "1072592.32" },
      { key: "Subtractions", value1: "", value2: "" },
      { key: "Principal Payments", value1: "", value2: "39396.52" },
      { key: "Paid In Full", value1: "", value2: "3907421.93" },
      { key: "Realized Losses", value1: "", value2: "0.00" },
      { key: "Total:", value1: "36.00", value2: "3946818.45" },
      {
        key: "Ending Balance From Waterfall Distribution",
        value1: "417.00",
        value2: "79014194.61",
      },
    ],
    {
      "CONSTRUCTION DRAW ACCOUNT": "",
      BeginningBalanceAsOfDeterminationDate: "3697176.03",
      Deposits: "",
      FromPrincipalCollections: "0.00",
      TransferFromAdditionalLoanAccount: "0.00",
      "FromPriorityOfPayments (PriorPaymentDate)": "500000.00",
      "Total:": "500000.00",
      Withdrawals: "",
      ReimbursementOfServicingAdvances: "0.00",
      FundingOfConstructionDraws: "859676.11",
      TransferToAdditionalLoanAccount: "0.00",
      TransferToTheTransactionAccount: "0.00",
      "Total: ": "859676.11",
      EndingBalanceFromWaterfallDistribution: "3337499.92",
    },
    {
      "ADDITIONAL LOAN ACCOUNT": "",
      BeginningBalanceAsOfDeterminationDate: "14041878.00",
      Deposits: "",
      FromPrincipalCollections: "0.00",
      TransferFromConstructionDrawAccount: "0.00",
      "FromPriorityOfPayments (PriorPaymentDate)": "2410127.95",
      "Total:": "2410127.95",
      Withdrawals: "",
      ReimbursementOfServicingAdvances: "0.00",
      PurchaseOfAdditionalMortgageLoans: "3672597.24",
      TransferToConstructionDrawAccount: "0.00",
      TransferToTheTransactionAccount: "0.00",
      "Total: ": "3672597.24",
      EndingBalanceFromWaterfallDistribution: "12779408.71",
    },
    {
      "TRANSACTION ACCOUNT": "",
      BeginningBalanceAsOfDeterminationDate: "4033756.87",
      Deposits: "",
      FromPrincipalCollections: "3946818.45",
      RegularInterest: "468680.13",
      OtherInterest: "38581.74",
      FromConstructionDrawAccount: "0.00",
      FromAdditionalLoanAccount: "0.00",
      "Total:": "4454080.32",
      Withdrawals: "",
      "ToPriorityOfPayments (PriorPaymentDate)": "4033756.87",
      PurchasedInterest: "0.00",
      "Total: ": "4033756.87",
      EndingBalanceFromWaterfallDistribution: "4454080.32",
    },
  ],
  CollateralSummary: [
    {
      Activity: "Paid In Full",
      CountRelatedPeriod: "36.00",
      AmountRelatedPeriod: "3907421.93",
      CountSinceCutoff: "95.00",
      AmountSinceCutoff: "11723631.65",
    },
    {
      Activity: "Purchase of Additional Mortgage Loans",
      CountRelatedPeriod: "14.00",
      AmountRelatedPeriod: "0.00",
      CountSinceCutoff: "14.00",
      AmountSinceCutoff: "0.00",
    },
    {
      Activity: "Funding of Construction Draws",
      CountRelatedPeriod: "36.00",
      AmountRelatedPeriod: "1072592.32",
      CountSinceCutoff: "144.00",
      AmountSinceCutoff: "4816043.71",
    },
    {
      Activity: "Principal Payments",
      CountRelatedPeriod: "0.00",
      AmountRelatedPeriod: "39396.52",
      CountSinceCutoff: "345.00",
      AmountSinceCutoff: "120148.92",
    },
    {
      Activity: "Realized Losses",
      CountRelatedPeriod: "0.00",
      AmountRelatedPeriod: "0.00",
      CountSinceCutoff: "0.00",
      AmountSinceCutoff: "0.00",
    },
  ],
  AcquisitionCriteria: [
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "Pool Balance",
      Current: "99077921.69",
      Criteria: "PASS",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Mortgage Loans not conforming to Underwriting Guidelines",
      Current: "0.00",
      Criteria: "10.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "% Rental Mortgage Loans by Pool Balance",
      Current: "38.0",
      Criteria: "40.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Unpaid Principal Balance",
      Current: "PASS",
      Criteria: "300000.00 max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "  per Single Family Mortgaged Property",
      Current: "PASS",
      Criteria: "2500000.00 max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "  Single Family",
      Current: "PASS",
      Criteria: "5000000.00 max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "   Multi-Family",
      Current: "PASS",
      Criteria: "5000000.00 max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Average Unpaid Principal Balance of 1-4 Family Mortgage Loans",
      Current: "171642.74",
      Criteria: "300000.00 max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Average Unpaid Principal Balance of Multifamily Mortgage Loans",
      Current: "1392653.15",
      Criteria: "2500000.00 max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "% Aggregate Unpaid Principal Balance of Multifamily Mortgage Loans",
      Current: "4.2",
      Criteria: "15.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "Credit Score",
      Current: "PASS",
      Criteria: "600 min",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "  Weighted Average",
      Current: "726",
      Criteria: "700 min",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "  Score between 600-625",
      Current: "1.2",
      Criteria: "2.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Mortgage Interest Rate",
      Current: "PASS",
      Criteria: "4.5% min",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "  Weighted Average",
      Current: "7.8",
      Criteria: "7.5% min",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Loans to Foreign Nationals or Legal Entities Without FICO Scores",
      Current: "0.0",
      Criteria: "2.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Exposure to single Primary Guarantor",
      Current: "3.4",
      Criteria: "5.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Adjustable-Rate Loans",
      Current: "Not calculated",
      Criteria: "30.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Hybrid Adjustable-Rate Mortgage Loans",
      Current: "Not calculated",
      Criteria: "Not shorter than 5/1",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "Cash-Out Refinance",
      Current: "Not calculated",
      Criteria: "45.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Maximum Single Geographic Concentration",
      Current: "9.5",
      Criteria: "15.0% max",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)": "30+ Days Delinquent",
      Current: "PASS",
      Criteria: "Not Permitted",
    },
    {
      "Additional Mortgage Loan Criteria (Entire Pool)":
        "Interest Coverage Test",
      Current: "1.64",
      Criteria: "1.25 min",
    },
  ],
  AcquisitionCriteria1: [
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "Renovation Balance",
      Current: "58468745.23",
      Criteria: "PASS",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "Undrawn Construction Advance Amount Held in Escrow",
      Current: "21.24",
      Criteria: "40.0% max",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "Loan to As Repaired Value",
      Current: "PASS",
      Criteria: "80% max",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "  Weighted Average",
      Current: "6338.58",
      Criteria: "70.0% max",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "Loan to Cost Ratio",
      Current: "PASS",
      Criteria: "95% max",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)": "  > 90% LTC",
      Current: "2.61",
      Criteria: "3.5% max",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "  Weighted Average",
      Current: "85.21",
      Criteria: "87.0% max",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "Exposure to New Customers",
      Current: "16.95",
      Criteria: "25.0% max",
    },
    {
      "Mortgage Loan Criteria (Renovation Mortgage Loans)":
        "Ground-Up Construction Loans",
      Current: "16.29",
      Criteria: "25.0% max",
    },
  ],
  AcquisitionCriteria2: [
    {
      "Mortgage Loan Criteria (Rental Mortgage Loans)": "Rental Balance",
      Current: "37858133.75",
      Criteria: "PASS",
    },
    {
      "Mortgage Loan Criteria (Rental Mortgage Loans)": "DSCR",
      Current: "1.57",
      Criteria: "1.25 min",
    },
    {
      "Mortgage Loan Criteria (Rental Mortgage Loans)": "Lease Status",
      Current: "24.30",
      Criteria: "30.0% max",
    },
    {
      "Mortgage Loan Criteria (Rental Mortgage Loans)": "Loan to Value Ratio",
      Current: "PASS",
      Criteria: "80.0% max",
    },
    {
      "Mortgage Loan Criteria (Rental Mortgage Loans)": "  Weighted Average",
      Current: "69.91",
      Criteria: "75.0% max",
    },
  ],
  PrePaymentsAndDefaultRates: [
    {
      "Constant Prepayments Rates (CPR)": "Current(%)",
      June: "47.94",
      July: "0.00",
      August: "0.00",
      September: "",
      October: "",
      November: "",
      December: "",
      January: "",
      February: "",
      March: "",
      April: "",
      May: "",
    },
    {
      "Constant Prepayments Rates (CPR)": "Last 3-Month(%)",
      June: "",
      July: "",
      August: "0.96",
      September: "",
      October: "",
      November: "",
      December: "",
      January: "",
      February: "",
      March: "",
      April: "",
      May: "",
    },
    {
      "Constant Prepayments Rates (CPR)": "Since Cut-Off(%)",
      June: "47.94",
      July: "27.52",
      August: "19.24",
      September: "",
      October: "",
      November: "",
      December: "",
      January: "",
      February: "",
      March: "",
      April: "",
      May: "",
    },
    {
      "Constant Default Rates (CDR)*": "Current(%)",
      June: "0.00",
      July: "0.00",
      August: "0.00",
      September: "",
      October: "",
      November: "",
      December: "",
      January: "",
      February: "",
      March: "",
      April: "",
      May: "",
    },
    {
      "Constant Default Rates (CDR)*": "Last 3-Month(%)",
      June: "",
      July: "",
      August: "0.00",
      September: "",
      October: "",
      November: "",
      December: "",
      January: "",
      February: "",
      March: "",
      April: "",
      May: "",
    },
    {
      "Constant Default Rates (CDR)*": "Since Cut-Off(%)",
      June: "0.00",
      July: "0.00",
      August: "0.00",
      September: "",
      October: "",
      November: "",
      December: "",
      January: "",
      February: "",
      March: "",
      April: "",
      May: "",
    },
  ],
  DealFeesAndExpenses: [
    {
      Type: "Servicing Fee",
      BeginningUnpaid: "0.00",
      CurrentDue: "34120.18",
      TotalDue: "34120.18",
      RemainingCap: "",
      AmountPayable: "",
      TotalPaid: "34120.18",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Backup Servicing Fee",
      BeginningUnpaid: "0.00",
      CurrentDue: "1000.00",
      TotalDue: "1000.00",
      RemainingCap: "",
      AmountPayable: "",
      TotalPaid: "1000.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Document Custodian Fee",
      BeginningUnpaid: "0.00",
      CurrentDue: "300.00",
      TotalDue: "300.00",
      RemainingCap: "",
      AmountPayable: "",
      TotalPaid: "300.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Paying Agent Fee",
      BeginningUnpaid: "0.00",
      CurrentDue: "2500.00",
      TotalDue: "2500.00",
      RemainingCap: "",
      AmountPayable: "",
      TotalPaid: "2500.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Indenture Trustee Fee",
      BeginningUnpaid: "0.00",
      CurrentDue: "0.00",
      TotalDue: "0.00",
      RemainingCap: "",
      AmountPayable: "",
      TotalPaid: "0.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Total:",
      BeginningUnpaid: "0.00",
      CurrentDue: "37920.18",
      TotalDue: "37920.18",
      RemainingCap: "",
      AmountPayable: "",
      TotalPaid: "37920.18",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Servicing expenses:",
      BeginningUnpaid: "0.00",
      CurrentDue: "0.00",
      TotalDue: "0.00",
      RemainingCap: "75000.00",
      AmountPayable: "0.00",
      TotalPaid: "0.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Backup Servicing expenses:",
      BeginningUnpaid: "0.00",
      CurrentDue: "0.00",
      TotalDue: "0.00",
      RemainingCap: "5000.00",
      AmountPayable: "0.00",
      TotalPaid: "0.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Document Custodian expenses:",
      BeginningUnpaid: "0.00",
      CurrentDue: "0.00",
      TotalDue: "0.00",
      RemainingCap: "50000.00",
      AmountPayable: "0.00",
      TotalPaid: "0.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Paying Agent & Indenture Trustee expenses:",
      BeginningUnpaid: "0.00",
      CurrentDue: "0.00",
      TotalDue: "0.00",
      RemainingCap: "150000.00",
      AmountPayable: "0.00",
      TotalPaid: "0.00",
      EndingUnpaid: "0.00",
    },
    {
      Type: "Total:",
      BeginningUnpaid: "0.00",
      CurrentDue: "0.00",
      TotalDue: "0.00",
      RemainingCap: "280000.00",
      AmountPayable: "0.00",
      TotalPaid: "0.00",
      EndingUnpaid: "0.00",
    },
  ],
  DealEvents: [
    { key: "DEAL EVENTS", value1: "", value2: "", value3: "" },
    { key: "Is Revolving Period?", value1: "TRUE", value2: "", value3: "" },
    { key: "Is Amortization Event?", value1: "FALSE", value2: "", value3: "" },
    { key: "Is Trigger Event?", value1: "FALSE", value2: "", value3: "" },
    {
      key: "",
      value1: "Current Month",
      value2: "Previous Month",
      value3: "Previous 2 Months",
    },
    {
      key: "Current Trigger",
      value1: "FALSE",
      value2: "FALSE",
      value3: "FALSE",
    },
    { key: "Is Event of Default?", value1: "FALSE", value2: "", value3: "" },
    { key: "Is Servicer Default?", value1: "FALSE", value2: "", value3: "" },
    {
      key: "Deficiency Cure Payment Amount",
      value1: "0.00",
      value2: "",
      value3: "",
    },
    {
      key: "Effective Advance Rate",
      value1: "65.60%",
      value2: "Pass",
      value3: "",
    },
    { key: "Numerator", value1: "65000000.00", value2: "", value3: "" },
    { key: "Denominator", value1: "99077921.69", value2: "", value3: "" },
    { key: "Maximum Trigger Level", value1: "70.0%", value2: "", value3: "" },
    {
      key: "UPB < 60 days delinquent excluding non-extension modifications",
      value1: "79014194.61",
      value2: "",
      value3: "",
    },
  ],
  PriorityOfPayments: [
    {
      Key: "Beginning Transaction Account Balance",
      AvailableFunds: "4454080.32",
      AmountOwed: "",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "",
    },
    {
      Key: "(1) to the  transaction parties (pro rata)",
      AvailableFunds: "",
      AmountOwed: "",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "",
    },
    {
      Key: "(i) the Indenture Trustee Fee, the Paying Agent Fee, the Document Custodian Fee and the Back-Up Servicing Fee",
      AvailableFunds: "4454080.32",
      AmountOwed: "3800.00",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "3800.00",
    },
    {
      Key: "(ii) any related expenses and indemnification amounts reimbursed to such parties (subject to cap)",
      AvailableFunds: "4450280.32",
      AmountOwed: "0.00",
      Adjustment: "205000.00",
      AdjustmentType: "CAP",
      AmountPaid: "0.00",
    },
    {
      Key: "(2) to the Servicer,",
      AvailableFunds: "",
      AmountOwed: "",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "",
    },
    {
      Key: "(i) unreimbursed Protection Advances",
      AvailableFunds: "4450280.32",
      AmountOwed: "0.00",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "0.00",
    },
    {
      Key: "(ii) any other expenses, costs and liabilities on behalf of, and reimbursable to, the Servicer (subject to cap)",
      AvailableFunds: "4450280.32",
      AmountOwed: "0.00",
      Adjustment: "75000.00",
      AdjustmentType: "CAP",
      AmountPaid: "0.00",
    },
    {
      Key: "(3) to the Servicer, the Servicer Fee",
      AvailableFunds: "4450280.32",
      AmountOwed: "34120.18",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "34120.18",
    },
    {
      Key: "(4) to the Noteholders, (i) the Note Interest Payment Amount and (ii) any prior interest shortfalls and interest thereon",
      AvailableFunds: "4416160.14",
      AmountOwed: "286541.67",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "286541.67",
    },
    {
      Key: "(5) to the Construction Draw Account (while no Amortization Event; discretionary amount subject to cap)",
      AvailableFunds: "4129618.48",
      AmountOwed: "0.00",
      Adjustment: "4740851.68",
      AdjustmentType: "CAP",
      AmountPaid: "0.00",
    },
    {
      Key: "(6) to pay principal on the Notes (subject to cure cap during the Revolving Period)",
      AvailableFunds: "4129618.48",
      AmountOwed: "0.00",
      Adjustment: "0.00",
      AdjustmentType: "CURE",
      AmountPaid: "0.00",
    },
    {
      Key: "(7) to the Additional Loan Account (during the Revolving Period subject to floor)",
      AvailableFunds: "4129618.48",
      AmountOwed: "2446818.45",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "2446818.45",
    },
    {
      Key: "(8) to the Servicer, any unreimbursed Optional Advances, and ",
      AvailableFunds: "1682800.03",
      AmountOwed: "0.00",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "0.00",
    },
    {
      Key: "(9) pro rata, any amounts not reimbursed under (1)(ii) as a result of the cap or, in the case of the Servicer, priority second above",
      AvailableFunds: "1682800.03",
      AmountOwed: "0.00",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "0.00",
    },
    {
      Key: "(10) any remaining amounts will be distributed at the direction of the Issuer",
      AvailableFunds: "1682800.03",
      AmountOwed: "1682800.03",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "1682800.03",
    },
    {
      Key: "Ending Transaction Account Balance",
      AvailableFunds: "0.00",
      AmountOwed: "",
      Adjustment: "",
      AdjustmentType: "",
      AmountPaid: "0.00",
    },
  ],
  CurrentPeriodModificationDetails: [
    {
      "Loan ID": "102366",
      "Unpaid Principal Balance": "244985.89",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102303",
      "Unpaid Principal Balance": "310771.54",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "102274",
      "Unpaid Principal Balance": "92250",
      "Pre-Modification": "01/09/2020",
      "Post-Modification": "01/12/2020",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "104722",
      "Unpaid Principal Balance": "107599.35",
      "Pre-Modification": "01/02/2021",
      "Post-Modification": "01/06/2050",
      "Modification Type": "Extension",
    },
    {
      "Loan ID": "105297",
      "Unpaid Principal Balance": "91971",
      "Pre-Modification": "01/02/2021",
      "Post-Modification": "01/06/2050",
      "Modification Type": "Extension",
    },
  ],
  I_CPR: 0,
  O_CPR: 5.294348740844824,
  ServicingExpenses: "0.00",
  BackupServicingExpenses: "0.00",
  DocumentCustodianExpenses: "0.00",
  PayingAgentIndentureTrusteeExpenses: "0",
};

export const SaludaLoanStratReport = {
  DealContactInformation: {
    dealid: "saludagrade2",
    distributiondate: "10/26/2020",
    reporttype: "Monthly Paying Agent",
    relationshipmanager: "Devon Almeida",
    address: "500 Delaware Ave, 11th Floor, Wilmington, DE 19801",
    email: "dalmeida@wsfsbank.com",
    websitereporting: "www.wsfsabsreporting.com",
    addtionalfirst: " ",
    additionallast: " ",
  },
  borrower_state: [
    {
      "Borrower State": "CA",
      Count: 1115,
      "$ Aggregate": "61035361.52",
      "% Aggregate": "20.77",
      "$ Average": "54740.23",
      "% Mortgage Net Interest Rate": "7.78",
      "% Initial Combined Loan-to-Value Ratio": "66.04",
      "% DTI": "32.02",
      "Credit Score": "740",
      "Remaining Term": "198",
    },
    {
      "Borrower State": "FL",
      Count: 559,
      "$ Aggregate": "23210195.01",
      "% Aggregate": "7.90",
      "$ Average": "41520.92",
      "% Mortgage Net Interest Rate": "8.92",
      "% Initial Combined Loan-to-Value Ratio": "64.22",
      "% DTI": "32.44",
      "Credit Score": "721",
      "Remaining Term": "182",
    },
    {
      "Borrower State": "GA",
      Count: 367,
      "$ Aggregate": "16223547.22",
      "% Aggregate": "5.52",
      "$ Average": "44205.85",
      "% Mortgage Net Interest Rate": "8.67",
      "% Initial Combined Loan-to-Value Ratio": "70.39",
      "% DTI": "30.74",
      "Credit Score": "718",
      "Remaining Term": "175",
    },
    {
      "Borrower State": "WA",
      Count: 329,
      "$ Aggregate": "15096903.89",
      "% Aggregate": "5.14",
      "$ Average": "45887.25",
      "% Mortgage Net Interest Rate": "8.17",
      "% Initial Combined Loan-to-Value Ratio": "67.15",
      "% DTI": "33.07",
      "Credit Score": "732",
      "Remaining Term": "189",
    },
    {
      "Borrower State": "NC",
      Count: 340,
      "$ Aggregate": "13678985.97",
      "% Aggregate": "4.66",
      "$ Average": "40232.31",
      "% Mortgage Net Interest Rate": "8.76",
      "% Initial Combined Loan-to-Value Ratio": "70.93",
      "% DTI": "31.86",
      "Credit Score": "721",
      "Remaining Term": "175",
    },
    {
      "Borrower State": "CO",
      Count: 280,
      "$ Aggregate": "12768266.57",
      "% Aggregate": "4.35",
      "$ Average": "45600.95",
      "% Mortgage Net Interest Rate": "8.32",
      "% Initial Combined Loan-to-Value Ratio": "65.70",
      "% DTI": "31.43",
      "Credit Score": "721",
      "Remaining Term": "191",
    },
    {
      "Borrower State": "Others",
      Count: 224,
      "$ Aggregate": "11542186.53",
      "% Aggregate": "3.93",
      "$ Average": "51527.62",
      "% Mortgage Net Interest Rate": "8.20",
      "% Initial Combined Loan-to-Value Ratio": "64.66",
      "% DTI": "31.84",
      "Credit Score": "740",
      "Remaining Term": "205",
    },
    {
      "Borrower State": "AZ",
      Count: 265,
      "$ Aggregate": "11026282.98",
      "% Aggregate": "3.75",
      "$ Average": "41608.62",
      "% Mortgage Net Interest Rate": "8.51",
      "% Initial Combined Loan-to-Value Ratio": "71.29",
      "% DTI": "33.01",
      "Credit Score": "728",
      "Remaining Term": "183",
    },
    {
      "Borrower State": "MA",
      Count: 197,
      "$ Aggregate": "9481696.12",
      "% Aggregate": "3.23",
      "$ Average": "48130.44",
      "% Mortgage Net Interest Rate": "8.16",
      "% Initial Combined Loan-to-Value Ratio": "65.17",
      "% DTI": "32.65",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
    {
      "Borrower State": "VA",
      Count: 192,
      "$ Aggregate": "9302104.60",
      "% Aggregate": "3.17",
      "$ Average": "48448.46",
      "% Mortgage Net Interest Rate": "8.60",
      "% Initial Combined Loan-to-Value Ratio": "74.74",
      "% DTI": "32.58",
      "Credit Score": "728",
      "Remaining Term": "152",
    },
    {
      "Borrower State": "NJ",
      Count: 197,
      "$ Aggregate": "9210039.97",
      "% Aggregate": "3.13",
      "$ Average": "46751.47",
      "% Mortgage Net Interest Rate": "8.88",
      "% Initial Combined Loan-to-Value Ratio": "64.18",
      "% DTI": "30.17",
      "Credit Score": "726",
      "Remaining Term": "185",
    },
    {
      "Borrower State": "OH",
      Count: 241,
      "$ Aggregate": "8921744.76",
      "% Aggregate": "3.04",
      "$ Average": "37019.69",
      "% Mortgage Net Interest Rate": "9.28",
      "% Initial Combined Loan-to-Value Ratio": "69.76",
      "% DTI": "32.65",
      "Credit Score": "720",
      "Remaining Term": "182",
    },
    {
      "Borrower State": "PA",
      Count: 202,
      "$ Aggregate": "8792740.89",
      "% Aggregate": "2.99",
      "$ Average": "43528.42",
      "% Mortgage Net Interest Rate": "9.04",
      "% Initial Combined Loan-to-Value Ratio": "70.18",
      "% DTI": "32.30",
      "Credit Score": "722",
      "Remaining Term": "160",
    },
    {
      "Borrower State": "IL",
      Count: 203,
      "$ Aggregate": "8461850.27",
      "% Aggregate": "2.88",
      "$ Average": "41683.99",
      "% Mortgage Net Interest Rate": "8.67",
      "% Initial Combined Loan-to-Value Ratio": "68.29",
      "% DTI": "31.57",
      "Credit Score": "731",
      "Remaining Term": "163",
    },
    {
      "Borrower State": "MI",
      Count: 226,
      "$ Aggregate": "8329245.82",
      "% Aggregate": "2.83",
      "$ Average": "36855.07",
      "% Mortgage Net Interest Rate": "9.28",
      "% Initial Combined Loan-to-Value Ratio": "67.38",
      "% DTI": "31.48",
      "Credit Score": "710",
      "Remaining Term": "191",
    },
    {
      "Borrower State": "TN",
      Count: 222,
      "$ Aggregate": "8312252.98",
      "% Aggregate": "2.83",
      "$ Average": "37442.58",
      "% Mortgage Net Interest Rate": "8.56",
      "% Initial Combined Loan-to-Value Ratio": "68.48",
      "% DTI": "31.71",
      "Credit Score": "721",
      "Remaining Term": "170",
    },
    {
      "Borrower State": "OR",
      Count: 180,
      "$ Aggregate": "8230068.97",
      "% Aggregate": "2.80",
      "$ Average": "45722.61",
      "% Mortgage Net Interest Rate": "8.41",
      "% Initial Combined Loan-to-Value Ratio": "68.82",
      "% DTI": "33.27",
      "Credit Score": "725",
      "Remaining Term": "199",
    },
    {
      "Borrower State": "NV",
      Count: 181,
      "$ Aggregate": "7668381.58",
      "% Aggregate": "2.61",
      "$ Average": "42366.75",
      "% Mortgage Net Interest Rate": "8.31",
      "% Initial Combined Loan-to-Value Ratio": "68.08",
      "% DTI": "31.63",
      "Credit Score": "733",
      "Remaining Term": "174",
    },
    {
      "Borrower State": "MN",
      Count: 153,
      "$ Aggregate": "6009030.29",
      "% Aggregate": "2.05",
      "$ Average": "39274.71",
      "% Mortgage Net Interest Rate": "8.81",
      "% Initial Combined Loan-to-Value Ratio": "72.07",
      "% DTI": "32.81",
      "Credit Score": "727",
      "Remaining Term": "176",
    },
    {
      "Borrower State": "IN",
      Count: 172,
      "$ Aggregate": "5587044.06",
      "% Aggregate": "1.90",
      "$ Average": "32482.81",
      "% Mortgage Net Interest Rate": "9.16",
      "% Initial Combined Loan-to-Value Ratio": "67.61",
      "% DTI": "32.51",
      "Credit Score": "712",
      "Remaining Term": "166",
    },
    {
      "Borrower State": "MO",
      Count: 126,
      "$ Aggregate": "5223063.54",
      "% Aggregate": "1.78",
      "$ Average": "41452.89",
      "% Mortgage Net Interest Rate": "8.97",
      "% Initial Combined Loan-to-Value Ratio": "71.69",
      "% DTI": "32.43",
      "Credit Score": "718",
      "Remaining Term": "188",
    },
    {
      "Borrower State": "WI",
      Count: 107,
      "$ Aggregate": "3887562.41",
      "% Aggregate": "1.32",
      "$ Average": "36332.36",
      "% Mortgage Net Interest Rate": "9.07",
      "% Initial Combined Loan-to-Value Ratio": "66.30",
      "% DTI": "32.09",
      "Credit Score": "715",
      "Remaining Term": "172",
    },
    {
      "Borrower State": "AL",
      Count: 87,
      "$ Aggregate": "3390020.05",
      "% Aggregate": "1.15",
      "$ Average": "38965.75",
      "% Mortgage Net Interest Rate": "8.51",
      "% Initial Combined Loan-to-Value Ratio": "65.15",
      "% DTI": "30.72",
      "Credit Score": "724",
      "Remaining Term": "202",
    },
    {
      "Borrower State": "NH",
      Count: 58,
      "$ Aggregate": "2778319.95",
      "% Aggregate": "0.95",
      "$ Average": "47902.07",
      "% Mortgage Net Interest Rate": "9.04",
      "% Initial Combined Loan-to-Value Ratio": "74.89",
      "% DTI": "33.56",
      "Credit Score": "738",
      "Remaining Term": "194",
    },
    {
      "Borrower State": "CT",
      Count: 59,
      "$ Aggregate": "2481352.91",
      "% Aggregate": "0.84",
      "$ Average": "42056.83",
      "% Mortgage Net Interest Rate": "8.64",
      "% Initial Combined Loan-to-Value Ratio": "61.22",
      "% DTI": "30.15",
      "Credit Score": "721",
      "Remaining Term": "157",
    },
    {
      "Borrower State": "OK",
      Count: 62,
      "$ Aggregate": "2152365.64",
      "% Aggregate": "0.73",
      "$ Average": "34715.57",
      "% Mortgage Net Interest Rate": "8.41",
      "% Initial Combined Loan-to-Value Ratio": "66.95",
      "% DTI": "29.59",
      "Credit Score": "723",
      "Remaining Term": "174",
    },
    {
      "Borrower State": "LA",
      Count: 53,
      "$ Aggregate": "1780339.17",
      "% Aggregate": "0.61",
      "$ Average": "33591.31",
      "% Mortgage Net Interest Rate": "8.74",
      "% Initial Combined Loan-to-Value Ratio": "63.53",
      "% DTI": "30.06",
      "Credit Score": "721",
      "Remaining Term": "206",
    },
    {
      "Borrower State": "AR",
      Count: 45,
      "$ Aggregate": "1597652.19",
      "% Aggregate": "0.54",
      "$ Average": "35503.38",
      "% Mortgage Net Interest Rate": "8.57",
      "% Initial Combined Loan-to-Value Ratio": "62.89",
      "% DTI": "30.76",
      "Credit Score": "712",
      "Remaining Term": "169",
    },
    {
      "Borrower State": "NE",
      Count: 32,
      "$ Aggregate": "1367299.41",
      "% Aggregate": "0.47",
      "$ Average": "42728.11",
      "% Mortgage Net Interest Rate": "9.28",
      "% Initial Combined Loan-to-Value Ratio": "72.60",
      "% DTI": "33.29",
      "Credit Score": "703",
      "Remaining Term": "177",
    },
    {
      "Borrower State": "NM",
      Count: 23,
      "$ Aggregate": "1029283.81",
      "% Aggregate": "0.35",
      "$ Average": "44751.47",
      "% Mortgage Net Interest Rate": "8.09",
      "% Initial Combined Loan-to-Value Ratio": "60.27",
      "% DTI": "31.33",
      "Credit Score": "743",
      "Remaining Term": "168",
    },
    {
      "Borrower State": "DC",
      Count: 14,
      "$ Aggregate": "829783.95",
      "% Aggregate": "0.28",
      "$ Average": "59270.28",
      "% Mortgage Net Interest Rate": "7.34",
      "% Initial Combined Loan-to-Value Ratio": "58.46",
      "% DTI": "28.58",
      "Credit Score": "744",
      "Remaining Term": "219",
    },
    {
      "Borrower State": "WY",
      Count: 15,
      "$ Aggregate": "768341.65",
      "% Aggregate": "0.26",
      "$ Average": "51222.78",
      "% Mortgage Net Interest Rate": "8.70",
      "% Initial Combined Loan-to-Value Ratio": "72.56",
      "% DTI": "38.71",
      "Credit Score": "734",
      "Remaining Term": "200",
    },
    {
      "Borrower State": "MS",
      Count: 21,
      "$ Aggregate": "769689.62",
      "% Aggregate": "0.26",
      "$ Average": "36651.89",
      "% Mortgage Net Interest Rate": "8.74",
      "% Initial Combined Loan-to-Value Ratio": "69.19",
      "% DTI": "30.20",
      "Credit Score": "718",
      "Remaining Term": "180",
    },
    {
      "Borrower State": "KS",
      Count: 15,
      "$ Aggregate": "774732.29",
      "% Aggregate": "0.26",
      "$ Average": "51648.82",
      "% Mortgage Net Interest Rate": "8.56",
      "% Initial Combined Loan-to-Value Ratio": "67.59",
      "% DTI": "32.97",
      "Credit Score": "745",
      "Remaining Term": "195",
    },
    {
      "Borrower State": "ID",
      Count: 12,
      "$ Aggregate": "580366.61",
      "% Aggregate": "0.20",
      "$ Average": "48363.88",
      "% Mortgage Net Interest Rate": "7.89",
      "% Initial Combined Loan-to-Value Ratio": "54.40",
      "% DTI": "38.17",
      "Credit Score": "728",
      "Remaining Term": "252",
    },
    {
      "Borrower State": "MT",
      Count: 9,
      "$ Aggregate": "383848.32",
      "% Aggregate": "0.13",
      "$ Average": "42649.81",
      "% Mortgage Net Interest Rate": "8.06",
      "% Initial Combined Loan-to-Value Ratio": "61.94",
      "% DTI": "33.90",
      "Credit Score": "720",
      "Remaining Term": "203",
    },
    {
      "Borrower State": "RI",
      Count: 12,
      "$ Aggregate": "254488.20",
      "% Aggregate": "0.09",
      "$ Average": "21207.35",
      "% Mortgage Net Interest Rate": "8.55",
      "% Initial Combined Loan-to-Value Ratio": "58.70",
      "% DTI": "33.79",
      "Credit Score": "698",
      "Remaining Term": "156",
    },
    {
      "Borrower State": "ME",
      Count: 6,
      "$ Aggregate": "246830.83",
      "% Aggregate": "0.08",
      "$ Average": "41138.47",
      "% Mortgage Net Interest Rate": "9.92",
      "% Initial Combined Loan-to-Value Ratio": "78.53",
      "% DTI": "37.45",
      "Credit Score": "711",
      "Remaining Term": "194",
    },
    {
      "Borrower State": "ND",
      Count: 5,
      "$ Aggregate": "233513.85",
      "% Aggregate": "0.08",
      "$ Average": "46702.77",
      "% Mortgage Net Interest Rate": "7.79",
      "% Initial Combined Loan-to-Value Ratio": "78.95",
      "% DTI": "32.61",
      "Credit Score": "748",
      "Remaining Term": "121",
    },
    {
      "Borrower State": "TX",
      Count: 4,
      "$ Aggregate": "149163.59",
      "% Aggregate": "0.05",
      "$ Average": "37290.90",
      "% Mortgage Net Interest Rate": "9.50",
      "% Initial Combined Loan-to-Value Ratio": "70.57",
      "% DTI": "24.60",
      "Credit Score": "707",
      "Remaining Term": "269",
    },
    {
      "Borrower State": "NY",
      Count: 2,
      "$ Aggregate": "109444.00",
      "% Aggregate": "0.04",
      "$ Average": "54722.00",
      "% Mortgage Net Interest Rate": "9.85",
      "% Initial Combined Loan-to-Value Ratio": "88.53",
      "% DTI": "27.35",
      "Credit Score": "787",
      "Remaining Term": "200",
    },
    {
      "Borrower State": "SD",
      Count: 4,
      "$ Aggregate": "104751.62",
      "% Aggregate": "0.04",
      "$ Average": "26187.91",
      "% Mortgage Net Interest Rate": "9.54",
      "% Initial Combined Loan-to-Value Ratio": "45.74",
      "% DTI": "38.85",
      "Credit Score": "658",
      "Remaining Term": "115",
    },
    {
      "Borrower State": "HI",
      Count: 1,
      "$ Aggregate": "39697.31",
      "% Aggregate": "0.01",
      "$ Average": "39697.31",
      "% Mortgage Net Interest Rate": "6.24",
      "% Initial Combined Loan-to-Value Ratio": "62.28",
      "% DTI": "39.05",
      "Credit Score": "784",
      "Remaining Term": "114",
    },
    {
      "Borrower State": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  first_lien: [
    {
      "First Lien": "1",
      Count: 504,
      "$ Aggregate": "23859837.73",
      "% Aggregate": "8.12",
      "$ Average": "47340.95",
      "% Mortgage Net Interest Rate": "7.05",
      "% Initial Combined Loan-to-Value Ratio": "26.01",
      "% DTI": "26.76",
      "Credit Score": "718",
      "Remaining Term": "188",
    },
    {
      "First Lien": "2+",
      Count: 6113,
      "$ Aggregate": "269960003.19",
      "% Aggregate": "91.88",
      "$ Average": "44161.62",
      "% Mortgage Net Interest Rate": "8.60",
      "% Initial Combined Loan-to-Value Ratio": "71.22",
      "% DTI": "32.50",
      "Credit Score": "728",
      "Remaining Term": "184",
    },
    {
      "First Lien": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  property_state: [
    {
      "Property State": "CA",
      Count: 1150,
      "$ Aggregate": "63416348.18",
      "% Aggregate": "21.58",
      "$ Average": "55144.65",
      "% Mortgage Net Interest Rate": "7.78",
      "% Initial Combined Loan-to-Value Ratio": "65.91",
      "% DTI": "32.00",
      "Credit Score": "740",
      "Remaining Term": "200",
    },
    {
      "Property State": "FL",
      Count: 594,
      "$ Aggregate": "24918735.96",
      "% Aggregate": "8.48",
      "$ Average": "41950.73",
      "% Mortgage Net Interest Rate": "8.85",
      "% Initial Combined Loan-to-Value Ratio": "64.30",
      "% DTI": "32.42",
      "Credit Score": "723",
      "Remaining Term": "181",
    },
    {
      "Property State": "GA",
      Count: 373,
      "$ Aggregate": "16509836.84",
      "% Aggregate": "5.62",
      "$ Average": "44262.30",
      "% Mortgage Net Interest Rate": "8.71",
      "% Initial Combined Loan-to-Value Ratio": "70.89",
      "% DTI": "30.71",
      "Credit Score": "719",
      "Remaining Term": "175",
    },
    {
      "Property State": "WA",
      Count: 341,
      "$ Aggregate": "15923458.31",
      "% Aggregate": "5.42",
      "$ Average": "46696.36",
      "% Mortgage Net Interest Rate": "8.17",
      "% Initial Combined Loan-to-Value Ratio": "66.84",
      "% DTI": "33.01",
      "Credit Score": "731",
      "Remaining Term": "190",
    },
    {
      "Property State": "NC",
      Count: 367,
      "$ Aggregate": "14708345.23",
      "% Aggregate": "5.01",
      "$ Average": "40077.23",
      "% Mortgage Net Interest Rate": "8.73",
      "% Initial Combined Loan-to-Value Ratio": "70.46",
      "% DTI": "31.86",
      "Credit Score": "722",
      "Remaining Term": "179",
    },
    {
      "Property State": "CO",
      Count: 290,
      "$ Aggregate": "13364207.39",
      "% Aggregate": "4.55",
      "$ Average": "46083.47",
      "% Mortgage Net Interest Rate": "8.29",
      "% Initial Combined Loan-to-Value Ratio": "65.89",
      "% DTI": "31.35",
      "Credit Score": "722",
      "Remaining Term": "189",
    },
    {
      "Property State": "AZ",
      Count: 274,
      "$ Aggregate": "11539575.68",
      "% Aggregate": "3.93",
      "$ Average": "42115.24",
      "% Mortgage Net Interest Rate": "8.56",
      "% Initial Combined Loan-to-Value Ratio": "71.84",
      "% DTI": "32.82",
      "Credit Score": "729",
      "Remaining Term": "183",
    },
    {
      "Property State": "MA",
      Count: 206,
      "$ Aggregate": "9967142.49",
      "% Aggregate": "3.39",
      "$ Average": "48384.19",
      "% Mortgage Net Interest Rate": "8.17",
      "% Initial Combined Loan-to-Value Ratio": "64.04",
      "% DTI": "32.66",
      "Credit Score": "727",
      "Remaining Term": "189",
    },
    {
      "Property State": "NJ",
      Count: 208,
      "$ Aggregate": "9751467.33",
      "% Aggregate": "3.32",
      "$ Average": "46882.05",
      "% Mortgage Net Interest Rate": "8.91",
      "% Initial Combined Loan-to-Value Ratio": "64.21",
      "% DTI": "30.62",
      "Credit Score": "726",
      "Remaining Term": "184",
    },
    {
      "Property State": "VA",
      Count: 201,
      "$ Aggregate": "9733672.79",
      "% Aggregate": "3.31",
      "$ Average": "48426.23",
      "% Mortgage Net Interest Rate": "8.56",
      "% Initial Combined Loan-to-Value Ratio": "74.64",
      "% DTI": "32.95",
      "Credit Score": "730",
      "Remaining Term": "154",
    },
    {
      "Property State": "OH",
      Count: 247,
      "$ Aggregate": "9111415.85",
      "% Aggregate": "3.10",
      "$ Average": "36888.32",
      "% Mortgage Net Interest Rate": "9.29",
      "% Initial Combined Loan-to-Value Ratio": "69.77",
      "% DTI": "32.60",
      "Credit Score": "719",
      "Remaining Term": "181",
    },
    {
      "Property State": "PA",
      Count: 204,
      "$ Aggregate": "8863600.13",
      "% Aggregate": "3.02",
      "$ Average": "43449.02",
      "% Mortgage Net Interest Rate": "9.00",
      "% Initial Combined Loan-to-Value Ratio": "69.76",
      "% DTI": "32.06",
      "Credit Score": "722",
      "Remaining Term": "162",
    },
    {
      "Property State": "TN",
      Count: 233,
      "$ Aggregate": "8859384.85",
      "% Aggregate": "3.02",
      "$ Average": "38023.11",
      "% Mortgage Net Interest Rate": "8.54",
      "% Initial Combined Loan-to-Value Ratio": "68.50",
      "% DTI": "31.63",
      "Credit Score": "724",
      "Remaining Term": "174",
    },
    {
      "Property State": "IL",
      Count: 212,
      "$ Aggregate": "8711875.92",
      "% Aggregate": "2.97",
      "$ Average": "41093.75",
      "% Mortgage Net Interest Rate": "8.57",
      "% Initial Combined Loan-to-Value Ratio": "67.22",
      "% DTI": "31.63",
      "Credit Score": "732",
      "Remaining Term": "166",
    },
    {
      "Property State": "OR",
      Count: 188,
      "$ Aggregate": "8692570.17",
      "% Aggregate": "2.96",
      "$ Average": "46237.08",
      "% Mortgage Net Interest Rate": "8.39",
      "% Initial Combined Loan-to-Value Ratio": "68.12",
      "% DTI": "33.17",
      "Credit Score": "725",
      "Remaining Term": "199",
    },
    {
      "Property State": "MI",
      Count: 229,
      "$ Aggregate": "8534065.11",
      "% Aggregate": "2.90",
      "$ Average": "37266.66",
      "% Mortgage Net Interest Rate": "9.24",
      "% Initial Combined Loan-to-Value Ratio": "67.11",
      "% DTI": "31.44",
      "Credit Score": "711",
      "Remaining Term": "192",
    },
    {
      "Property State": "NV",
      Count: 192,
      "$ Aggregate": "8254220.38",
      "% Aggregate": "2.81",
      "$ Average": "42990.73",
      "% Mortgage Net Interest Rate": "8.37",
      "% Initial Combined Loan-to-Value Ratio": "68.85",
      "% DTI": "31.49",
      "Credit Score": "734",
      "Remaining Term": "172",
    },
    {
      "Property State": "MN",
      Count: 155,
      "$ Aggregate": "6130318.35",
      "% Aggregate": "2.09",
      "$ Average": "39550.44",
      "% Mortgage Net Interest Rate": "8.78",
      "% Initial Combined Loan-to-Value Ratio": "72.16",
      "% DTI": "32.52",
      "Credit Score": "728",
      "Remaining Term": "175",
    },
    {
      "Property State": "IN",
      Count: 177,
      "$ Aggregate": "5797975.98",
      "% Aggregate": "1.97",
      "$ Average": "32756.93",
      "% Mortgage Net Interest Rate": "9.16",
      "% Initial Combined Loan-to-Value Ratio": "67.38",
      "% DTI": "32.58",
      "Credit Score": "712",
      "Remaining Term": "170",
    },
    {
      "Property State": "MO",
      Count: 127,
      "$ Aggregate": "5321830.74",
      "% Aggregate": "1.81",
      "$ Average": "41904.18",
      "% Mortgage Net Interest Rate": "8.99",
      "% Initial Combined Loan-to-Value Ratio": "71.72",
      "% DTI": "32.31",
      "Credit Score": "718",
      "Remaining Term": "192",
    },
    {
      "Property State": "WI",
      Count: 108,
      "$ Aggregate": "3934784.56",
      "% Aggregate": "1.34",
      "$ Average": "36433.19",
      "% Mortgage Net Interest Rate": "9.06",
      "% Initial Combined Loan-to-Value Ratio": "66.02",
      "% DTI": "32.01",
      "Credit Score": "714",
      "Remaining Term": "171",
    },
    {
      "Property State": "AL",
      Count: 87,
      "$ Aggregate": "3390020.05",
      "% Aggregate": "1.15",
      "$ Average": "38965.75",
      "% Mortgage Net Interest Rate": "8.51",
      "% Initial Combined Loan-to-Value Ratio": "65.15",
      "% DTI": "30.72",
      "Credit Score": "724",
      "Remaining Term": "202",
    },
    {
      "Property State": "NH",
      Count: 59,
      "$ Aggregate": "2847087.97",
      "% Aggregate": "0.97",
      "$ Average": "48255.73",
      "% Mortgage Net Interest Rate": "9.06",
      "% Initial Combined Loan-to-Value Ratio": "74.26",
      "% DTI": "33.59",
      "Credit Score": "737",
      "Remaining Term": "195",
    },
    {
      "Property State": "CT",
      Count: 60,
      "$ Aggregate": "2458226.67",
      "% Aggregate": "0.84",
      "$ Average": "40970.44",
      "% Mortgage Net Interest Rate": "8.62",
      "% Initial Combined Loan-to-Value Ratio": "60.63",
      "% DTI": "30.18",
      "Credit Score": "720",
      "Remaining Term": "157",
    },
    {
      "Property State": "OK",
      Count: 63,
      "$ Aggregate": "2187491.07",
      "% Aggregate": "0.74",
      "$ Average": "34722.08",
      "% Mortgage Net Interest Rate": "8.39",
      "% Initial Combined Loan-to-Value Ratio": "66.76",
      "% DTI": "29.79",
      "Credit Score": "722",
      "Remaining Term": "173",
    },
    {
      "Property State": "LA",
      Count: 56,
      "$ Aggregate": "1963158.74",
      "% Aggregate": "0.67",
      "$ Average": "35056.41",
      "% Mortgage Net Interest Rate": "8.93",
      "% Initial Combined Loan-to-Value Ratio": "64.86",
      "% DTI": "29.98",
      "Credit Score": "719",
      "Remaining Term": "203",
    },
    {
      "Property State": "AR",
      Count: 48,
      "$ Aggregate": "1647389.59",
      "% Aggregate": "0.56",
      "$ Average": "34320.62",
      "% Mortgage Net Interest Rate": "8.62",
      "% Initial Combined Loan-to-Value Ratio": "62.77",
      "% DTI": "30.57",
      "Credit Score": "710",
      "Remaining Term": "168",
    },
    {
      "Property State": "NE",
      Count: 32,
      "$ Aggregate": "1367299.41",
      "% Aggregate": "0.47",
      "$ Average": "42728.11",
      "% Mortgage Net Interest Rate": "9.28",
      "% Initial Combined Loan-to-Value Ratio": "72.60",
      "% DTI": "33.29",
      "Credit Score": "703",
      "Remaining Term": "177",
    },
    {
      "Property State": "NM",
      Count: 23,
      "$ Aggregate": "1044971.51",
      "% Aggregate": "0.36",
      "$ Average": "45433.54",
      "% Mortgage Net Interest Rate": "7.88",
      "% Initial Combined Loan-to-Value Ratio": "58.78",
      "% DTI": "32.24",
      "Credit Score": "746",
      "Remaining Term": "156",
    },
    {
      "Property State": "WY",
      Count: 17,
      "$ Aggregate": "878722.04",
      "% Aggregate": "0.30",
      "$ Average": "51689.53",
      "% Mortgage Net Interest Rate": "8.65",
      "% Initial Combined Loan-to-Value Ratio": "72.26",
      "% DTI": "38.66",
      "Credit Score": "739",
      "Remaining Term": "196",
    },
    {
      "Property State": "MS",
      Count: 21,
      "$ Aggregate": "769689.62",
      "% Aggregate": "0.26",
      "$ Average": "36651.89",
      "% Mortgage Net Interest Rate": "8.74",
      "% Initial Combined Loan-to-Value Ratio": "69.19",
      "% DTI": "30.20",
      "Credit Score": "718",
      "Remaining Term": "180",
    },
    {
      "Property State": "KS",
      Count: 16,
      "$ Aggregate": "774732.29",
      "% Aggregate": "0.26",
      "$ Average": "48420.77",
      "% Mortgage Net Interest Rate": "8.56",
      "% Initial Combined Loan-to-Value Ratio": "67.59",
      "% DTI": "32.97",
      "Credit Score": "745",
      "Remaining Term": "195",
    },
    {
      "Property State": "DC",
      Count: 13,
      "$ Aggregate": "688208.53",
      "% Aggregate": "0.23",
      "$ Average": "52939.12",
      "% Mortgage Net Interest Rate": "7.07",
      "% Initial Combined Loan-to-Value Ratio": "60.28",
      "% DTI": "28.04",
      "Credit Score": "749",
      "Remaining Term": "212",
    },
    {
      "Property State": "ID",
      Count: 12,
      "$ Aggregate": "580366.61",
      "% Aggregate": "0.20",
      "$ Average": "48363.88",
      "% Mortgage Net Interest Rate": "7.89",
      "% Initial Combined Loan-to-Value Ratio": "54.40",
      "% DTI": "38.17",
      "Credit Score": "728",
      "Remaining Term": "252",
    },
    {
      "Property State": "MT",
      Count: 9,
      "$ Aggregate": "383848.32",
      "% Aggregate": "0.13",
      "$ Average": "42649.81",
      "% Mortgage Net Interest Rate": "8.06",
      "% Initial Combined Loan-to-Value Ratio": "61.94",
      "% DTI": "33.90",
      "Credit Score": "720",
      "Remaining Term": "203",
    },
    {
      "Property State": "ND",
      Count: 5,
      "$ Aggregate": "233513.85",
      "% Aggregate": "0.08",
      "$ Average": "46702.77",
      "% Mortgage Net Interest Rate": "7.79",
      "% Initial Combined Loan-to-Value Ratio": "78.95",
      "% DTI": "32.61",
      "Credit Score": "748",
      "Remaining Term": "121",
    },
    {
      "Property State": "ME",
      Count: 5,
      "$ Aggregate": "231725.68",
      "% Aggregate": "0.08",
      "$ Average": "46345.14",
      "% Mortgage Net Interest Rate": "9.91",
      "% Initial Combined Loan-to-Value Ratio": "78.19",
      "% DTI": "38.73",
      "Credit Score": "710",
      "Remaining Term": "195",
    },
    {
      "Property State": "RI",
      Count: 11,
      "$ Aggregate": "223805.11",
      "% Aggregate": "0.08",
      "$ Average": "20345.92",
      "% Mortgage Net Interest Rate": "9.00",
      "% Initial Combined Loan-to-Value Ratio": "60.29",
      "% DTI": "31.57",
      "Credit Score": "681",
      "Remaining Term": "129",
    },
    {
      "Property State": "SD",
      Count: 4,
      "$ Aggregate": "104751.62",
      "% Aggregate": "0.04",
      "$ Average": "26187.91",
      "% Mortgage Net Interest Rate": "9.54",
      "% Initial Combined Loan-to-Value Ratio": "45.74",
      "% DTI": "38.85",
      "Credit Score": "658",
      "Remaining Term": "115",
    },
    {
      "Property State": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  loan_purpose: [
    {
      "Loan Purpose": "Primary Residence",
      Count: 6254,
      "$ Aggregate": "276387525.47",
      "% Aggregate": "94.07",
      "$ Average": "44193.72",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.59",
      "% DTI": "32.07",
      "Credit Score": "727",
      "Remaining Term": "184",
    },
    {
      "Loan Purpose": "Second Home",
      Count: 117,
      "$ Aggregate": "5823378.33",
      "% Aggregate": "1.98",
      "$ Average": "49772.46",
      "% Mortgage Net Interest Rate": "8.79",
      "% Initial Combined Loan-to-Value Ratio": "68.45",
      "% DTI": "30.22",
      "Credit Score": "732",
      "Remaining Term": "191",
    },
    {
      "Loan Purpose": "Investment Property",
      Count: 246,
      "$ Aggregate": "11608937.12",
      "% Aggregate": "3.95",
      "$ Average": "47190.80",
      "% Mortgage Net Interest Rate": "8.30",
      "% Initial Combined Loan-to-Value Ratio": "66.25",
      "% DTI": "32.09",
      "Credit Score": "741",
      "Remaining Term": "202",
    },
    {
      "Loan Purpose": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  current_rate: [
    {
      "% Current Rate": "< 5.00",
      Count: 115,
      "$ Aggregate": "3613564.43",
      "% Aggregate": "1.23",
      "$ Average": "31422.30",
      "% Mortgage Net Interest Rate": "4.46",
      "% Initial Combined Loan-to-Value Ratio": "38.51",
      "% DTI": "29.35",
      "Credit Score": "803",
      "Remaining Term": "70",
    },
    {
      "% Current Rate": "5.00 - 5.50",
      Count: 252,
      "$ Aggregate": "12114957.87",
      "% Aggregate": "4.12",
      "$ Average": "48075.23",
      "% Mortgage Net Interest Rate": "5.00",
      "% Initial Combined Loan-to-Value Ratio": "48.86",
      "% DTI": "32.78",
      "Credit Score": "793",
      "Remaining Term": "221",
    },
    {
      "% Current Rate": "5.51 - 6.00",
      Count: 299,
      "$ Aggregate": "14043633.10",
      "% Aggregate": "4.78",
      "$ Average": "46968.67",
      "% Mortgage Net Interest Rate": "5.56",
      "% Initial Combined Loan-to-Value Ratio": "52.20",
      "% DTI": "29.88",
      "Credit Score": "787",
      "Remaining Term": "176",
    },
    {
      "% Current Rate": "6.01 - 6.50",
      Count: 409,
      "$ Aggregate": "18123925.95",
      "% Aggregate": "6.17",
      "$ Average": "44312.78",
      "% Mortgage Net Interest Rate": "6.03",
      "% Initial Combined Loan-to-Value Ratio": "58.32",
      "% DTI": "31.68",
      "Credit Score": "772",
      "Remaining Term": "155",
    },
    {
      "% Current Rate": "6.51 - 7.00",
      Count: 371,
      "$ Aggregate": "18153248.49",
      "% Aggregate": "6.18",
      "$ Average": "48930.59",
      "% Mortgage Net Interest Rate": "6.51",
      "% Initial Combined Loan-to-Value Ratio": "55.77",
      "% DTI": "31.49",
      "Credit Score": "752",
      "Remaining Term": "189",
    },
    {
      "% Current Rate": "7.01 - 7.50",
      Count: 403,
      "$ Aggregate": "19023600.81",
      "% Aggregate": "6.47",
      "$ Average": "47204.96",
      "% Mortgage Net Interest Rate": "7.01",
      "% Initial Combined Loan-to-Value Ratio": "54.33",
      "% DTI": "31.09",
      "Credit Score": "736",
      "Remaining Term": "178",
    },
    {
      "% Current Rate": "7.51 - 8.00",
      Count: 461,
      "$ Aggregate": "21530138.86",
      "% Aggregate": "7.33",
      "$ Average": "46703.12",
      "% Mortgage Net Interest Rate": "7.51",
      "% Initial Combined Loan-to-Value Ratio": "62.07",
      "% DTI": "31.30",
      "Credit Score": "732",
      "Remaining Term": "178",
    },
    {
      "% Current Rate": "8.01 - 8.50",
      Count: 466,
      "$ Aggregate": "21694956.61",
      "% Aggregate": "7.38",
      "$ Average": "46555.70",
      "% Mortgage Net Interest Rate": "8.03",
      "% Initial Combined Loan-to-Value Ratio": "63.01",
      "% DTI": "31.86",
      "Credit Score": "730",
      "Remaining Term": "222",
    },
    {
      "% Current Rate": "8.51 - 9.00",
      Count: 550,
      "$ Aggregate": "26453316.69",
      "% Aggregate": "9.00",
      "$ Average": "48096.94",
      "% Mortgage Net Interest Rate": "8.51",
      "% Initial Combined Loan-to-Value Ratio": "68.58",
      "% DTI": "32.39",
      "Credit Score": "728",
      "Remaining Term": "196",
    },
    {
      "% Current Rate": "9.01 - 9.50",
      Count: 475,
      "$ Aggregate": "21519758.58",
      "% Aggregate": "7.32",
      "$ Average": "45304.75",
      "% Mortgage Net Interest Rate": "9.03",
      "% Initial Combined Loan-to-Value Ratio": "72.82",
      "% DTI": "32.06",
      "Credit Score": "718",
      "Remaining Term": "191",
    },
    {
      "% Current Rate": "9.51 - 10.00",
      Count: 589,
      "$ Aggregate": "25757995.16",
      "% Aggregate": "8.77",
      "$ Average": "43731.74",
      "% Mortgage Net Interest Rate": "9.50",
      "% Initial Combined Loan-to-Value Ratio": "73.80",
      "% DTI": "32.63",
      "Credit Score": "719",
      "Remaining Term": "176",
    },
    {
      "% Current Rate": "10.01 +",
      Count: 2227,
      "$ Aggregate": "91790744.37",
      "% Aggregate": "31.24",
      "$ Average": "41217.22",
      "% Mortgage Net Interest Rate": "10.63",
      "% Initial Combined Loan-to-Value Ratio": "79.47",
      "% DTI": "32.70",
      "Credit Score": "694",
      "Remaining Term": "182",
    },
    {
      "% Current Rate": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  principal_balance: [
    {
      "$ Principal Balance": "< 25000",
      Count: 1847,
      "$ Aggregate": "25404043.21",
      "% Aggregate": "8.65",
      "$ Average": "13754.22",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "61.24",
      "% DTI": "31.10",
      "Credit Score": "712",
      "Remaining Term": "140",
    },
    {
      "$ Principal Balance": "25000 - 50000",
      Count: 2737,
      "$ Aggregate": "105037195.08",
      "% Aggregate": "35.75",
      "$ Average": "38376.76",
      "% Mortgage Net Interest Rate": "8.98",
      "% Initial Combined Loan-to-Value Ratio": "65.62",
      "% DTI": "31.87",
      "Credit Score": "707",
      "Remaining Term": "173",
    },
    {
      "$ Principal Balance": "50000 - 75000",
      Count: 1000,
      "$ Aggregate": "60719357.21",
      "% Aggregate": "20.67",
      "$ Average": "60719.36",
      "% Mortgage Net Interest Rate": "8.49",
      "% Initial Combined Loan-to-Value Ratio": "72.10",
      "% DTI": "33.37",
      "Credit Score": "737",
      "Remaining Term": "189",
    },
    {
      "$ Principal Balance": "75000 - 100000",
      Count: 809,
      "$ Aggregate": "73411148.38",
      "% Aggregate": "24.99",
      "$ Average": "90743.08",
      "% Mortgage Net Interest Rate": "8.38",
      "% Initial Combined Loan-to-Value Ratio": "68.41",
      "% DTI": "31.87",
      "Credit Score": "728",
      "Remaining Term": "197",
    },
    {
      "$ Principal Balance": "100000 - 125000",
      Count: 85,
      "$ Aggregate": "9444469.04",
      "% Aggregate": "3.21",
      "$ Average": "111111.40",
      "% Mortgage Net Interest Rate": "7.15",
      "% Initial Combined Loan-to-Value Ratio": "71.94",
      "% DTI": "31.28",
      "Credit Score": "794",
      "Remaining Term": "217",
    },
    {
      "$ Principal Balance": "125000 - 150000",
      Count: 139,
      "$ Aggregate": "19803628.00",
      "% Aggregate": "6.74",
      "$ Average": "142472.14",
      "% Mortgage Net Interest Rate": "6.71",
      "% Initial Combined Loan-to-Value Ratio": "66.67",
      "% DTI": "31.04",
      "Credit Score": "794",
      "Remaining Term": "225",
    },
    {
      "$ Principal Balance": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  post_loan_cltv: [
    {
      "% Post Loan CLTV": "0.00 - 10.00",
      Count: 115,
      "$ Aggregate": "2931715.63",
      "% Aggregate": "1.00",
      "$ Average": "25493.18",
      "% Mortgage Net Interest Rate": "7.24",
      "% Initial Combined Loan-to-Value Ratio": "7.54",
      "% DTI": "24.46",
      "Credit Score": "706",
      "Remaining Term": "162",
    },
    {
      "% Post Loan CLTV": "10.01 - 20.00",
      Count: 267,
      "$ Aggregate": "10108446.67",
      "% Aggregate": "3.44",
      "$ Average": "37859.35",
      "% Mortgage Net Interest Rate": "7.31",
      "% Initial Combined Loan-to-Value Ratio": "15.14",
      "% DTI": "28.60",
      "Credit Score": "709",
      "Remaining Term": "191",
    },
    {
      "% Post Loan CLTV": "20.01 - 30.00",
      Count: 286,
      "$ Aggregate": "11518418.85",
      "% Aggregate": "3.92",
      "$ Average": "40274.19",
      "% Mortgage Net Interest Rate": "7.14",
      "% Initial Combined Loan-to-Value Ratio": "25.06",
      "% DTI": "28.40",
      "Credit Score": "719",
      "Remaining Term": "178",
    },
    {
      "% Post Loan CLTV": "30.01 - 40.00",
      Count: 304,
      "$ Aggregate": "13921467.28",
      "% Aggregate": "4.74",
      "$ Average": "45794.30",
      "% Mortgage Net Interest Rate": "7.18",
      "% Initial Combined Loan-to-Value Ratio": "35.02",
      "% DTI": "30.37",
      "Credit Score": "716",
      "Remaining Term": "188",
    },
    {
      "% Post Loan CLTV": "40.01 - 50.00",
      Count: 480,
      "$ Aggregate": "21645641.63",
      "% Aggregate": "7.37",
      "$ Average": "45095.09",
      "% Mortgage Net Interest Rate": "7.03",
      "% Initial Combined Loan-to-Value Ratio": "45.58",
      "% DTI": "30.91",
      "Credit Score": "727",
      "Remaining Term": "194",
    },
    {
      "% Post Loan CLTV": "50.01 - 60.00",
      Count: 634,
      "$ Aggregate": "27081193.49",
      "% Aggregate": "9.22",
      "$ Average": "42714.82",
      "% Mortgage Net Interest Rate": "7.39",
      "% Initial Combined Loan-to-Value Ratio": "55.77",
      "% DTI": "32.01",
      "Credit Score": "723",
      "Remaining Term": "203",
    },
    {
      "% Post Loan CLTV": "60.01 - 70.00",
      Count: 1016,
      "$ Aggregate": "41869466.22",
      "% Aggregate": "14.25",
      "$ Average": "41210.10",
      "% Mortgage Net Interest Rate": "8.17",
      "% Initial Combined Loan-to-Value Ratio": "65.51",
      "% DTI": "31.87",
      "Credit Score": "719",
      "Remaining Term": "195",
    },
    {
      "% Post Loan CLTV": "70.01 - 80.00",
      Count: 1542,
      "$ Aggregate": "69400989.42",
      "% Aggregate": "23.62",
      "$ Average": "45007.13",
      "% Mortgage Net Interest Rate": "8.39",
      "% Initial Combined Loan-to-Value Ratio": "75.71",
      "% DTI": "32.16",
      "Credit Score": "729",
      "Remaining Term": "203",
    },
    {
      "% Post Loan CLTV": "80.01 - 90.00",
      Count: 1502,
      "$ Aggregate": "70027779.38",
      "% Aggregate": "23.83",
      "$ Average": "46623.02",
      "% Mortgage Net Interest Rate": "9.80",
      "% Initial Combined Loan-to-Value Ratio": "85.96",
      "% DTI": "33.36",
      "Credit Score": "730",
      "Remaining Term": "157",
    },
    {
      "% Post Loan CLTV": "90.01 - 100.00",
      Count: 471,
      "$ Aggregate": "25314722.35",
      "% Aggregate": "8.62",
      "$ Average": "53746.76",
      "% Mortgage Net Interest Rate": "9.90",
      "% Initial Combined Loan-to-Value Ratio": "94.13",
      "% DTI": "34.14",
      "Credit Score": "758",
      "Remaining Term": "166",
    },
    {
      "% Post Loan CLTV": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  remaining_term_months: [
    {
      "Remaining Term Months": "0 - 100",
      Count: 1262,
      "$ Aggregate": "32198683.61",
      "% Aggregate": "10.96",
      "$ Average": "25514.01",
      "% Mortgage Net Interest Rate": "7.54",
      "% Initial Combined Loan-to-Value Ratio": "64.26",
      "% DTI": "31.61",
      "Credit Score": "732",
      "Remaining Term": "51",
    },
    {
      "Remaining Term Months": "101 - 200",
      Count: 4125,
      "$ Aggregate": "193279406.42",
      "% Aggregate": "65.78",
      "$ Average": "46855.61",
      "% Mortgage Net Interest Rate": "8.71",
      "% Initial Combined Loan-to-Value Ratio": "70.10",
      "% DTI": "32.06",
      "Credit Score": "725",
      "Remaining Term": "148",
    },
    {
      "Remaining Term Months": "201 - 300",
      Count: 9,
      "$ Aggregate": "417992.54",
      "% Aggregate": "0.14",
      "$ Average": "46443.62",
      "% Mortgage Net Interest Rate": "9.92",
      "% Initial Combined Loan-to-Value Ratio": "32.31",
      "% DTI": "28.92",
      "Credit Score": "701",
      "Remaining Term": "223",
    },
    {
      "Remaining Term Months": "301 - 400",
      Count: 1221,
      "$ Aggregate": "67923758.35",
      "% Aggregate": "23.12",
      "$ Average": "55629.61",
      "% Mortgage Net Interest Rate": "8.26",
      "% Initial Combined Loan-to-Value Ratio": "62.06",
      "% DTI": "32.19",
      "Credit Score": "733",
      "Remaining Term": "352",
    },
    {
      "Remaining Term Months": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  post_loan_dti: [
    {
      "% Post Loan DTI": "0.00 - 10.00",
      Count: 197,
      "$ Aggregate": "8471559.25",
      "% Aggregate": "2.88",
      "$ Average": "43002.84",
      "% Mortgage Net Interest Rate": "8.06",
      "% Initial Combined Loan-to-Value Ratio": "57.17",
      "% DTI": "7.24",
      "Credit Score": "729",
      "Remaining Term": "219",
    },
    {
      "% Post Loan DTI": "10.01 - 20.00",
      Count: 797,
      "$ Aggregate": "34060857.03",
      "% Aggregate": "11.59",
      "$ Average": "42736.33",
      "% Mortgage Net Interest Rate": "8.23",
      "% Initial Combined Loan-to-Value Ratio": "61.89",
      "% DTI": "15.92",
      "Credit Score": "727",
      "Remaining Term": "190",
    },
    {
      "% Post Loan DTI": "20.01 - 30.00",
      Count: 1670,
      "$ Aggregate": "73602659.48",
      "% Aggregate": "25.05",
      "$ Average": "44073.45",
      "% Mortgage Net Interest Rate": "8.34",
      "% Initial Combined Loan-to-Value Ratio": "66.41",
      "% DTI": "25.41",
      "Credit Score": "729",
      "Remaining Term": "178",
    },
    {
      "% Post Loan DTI": "30.01 - 40.00",
      Count: 2178,
      "$ Aggregate": "98779033.83",
      "% Aggregate": "33.62",
      "$ Average": "45353.09",
      "% Mortgage Net Interest Rate": "8.63",
      "% Initial Combined Loan-to-Value Ratio": "69.52",
      "% DTI": "35.15",
      "Credit Score": "723",
      "Remaining Term": "172",
    },
    {
      "% Post Loan DTI": "40.01 - 50.00",
      Count: 1775,
      "$ Aggregate": "78905731.33",
      "% Aggregate": "26.86",
      "$ Average": "44453.93",
      "% Mortgage Net Interest Rate": "8.57",
      "% Initial Combined Loan-to-Value Ratio": "69.71",
      "% DTI": "43.94",
      "Credit Score": "731",
      "Remaining Term": "200",
    },
    {
      "% Post Loan DTI": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
  fico_score: [
    {
      "FICO Score": "576 - 600",
      Count: 1,
      "$ Aggregate": "25660.12",
      "% Aggregate": "0.01",
      "$ Average": "25660.12",
      "% Mortgage Net Interest Rate": "9.27",
      "% Initial Combined Loan-to-Value Ratio": "22.28",
      "% DTI": "24.11",
      "Credit Score": "600",
      "Remaining Term": "170",
    },
    {
      "FICO Score": "601 - 625",
      Count: 154,
      "$ Aggregate": "4417281.41",
      "% Aggregate": "1.50",
      "$ Average": "28683.65",
      "% Mortgage Net Interest Rate": "10.59",
      "% Initial Combined Loan-to-Value Ratio": "47.78",
      "% DTI": "28.82",
      "Credit Score": "616",
      "Remaining Term": "169",
    },
    {
      "FICO Score": "626 - 650",
      Count: 502,
      "$ Aggregate": "14890900.58",
      "% Aggregate": "5.07",
      "$ Average": "29663.15",
      "% Mortgage Net Interest Rate": "10.44",
      "% Initial Combined Loan-to-Value Ratio": "52.55",
      "% DTI": "29.41",
      "Credit Score": "640",
      "Remaining Term": "187",
    },
    {
      "FICO Score": "651 - 675",
      Count: 577,
      "$ Aggregate": "18465271.87",
      "% Aggregate": "6.28",
      "$ Average": "32002.20",
      "% Mortgage Net Interest Rate": "10.07",
      "% Initial Combined Loan-to-Value Ratio": "57.30",
      "% DTI": "31.13",
      "Credit Score": "663",
      "Remaining Term": "187",
    },
    {
      "FICO Score": "676 - 700",
      Count: 1460,
      "$ Aggregate": "65170880.19",
      "% Aggregate": "22.18",
      "$ Average": "44637.59",
      "% Mortgage Net Interest Rate": "9.35",
      "% Initial Combined Loan-to-Value Ratio": "67.96",
      "% DTI": "31.83",
      "Credit Score": "689",
      "Remaining Term": "184",
    },
    {
      "FICO Score": "701 - 725",
      Count: 1156,
      "$ Aggregate": "51421580.51",
      "% Aggregate": "17.50",
      "$ Average": "44482.34",
      "% Mortgage Net Interest Rate": "8.78",
      "% Initial Combined Loan-to-Value Ratio": "68.71",
      "% DTI": "32.26",
      "Credit Score": "713",
      "Remaining Term": "178",
    },
    {
      "FICO Score": "726 - 750",
      Count: 952,
      "$ Aggregate": "43804862.81",
      "% Aggregate": "14.91",
      "$ Average": "46013.51",
      "% Mortgage Net Interest Rate": "8.27",
      "% Initial Combined Loan-to-Value Ratio": "71.53",
      "% DTI": "32.61",
      "Credit Score": "738",
      "Remaining Term": "173",
    },
    {
      "FICO Score": "751 - 775",
      Count: 729,
      "$ Aggregate": "36853535.01",
      "% Aggregate": "12.54",
      "$ Average": "50553.55",
      "% Mortgage Net Interest Rate": "7.44",
      "% Initial Combined Loan-to-Value Ratio": "72.13",
      "% DTI": "33.49",
      "Credit Score": "763",
      "Remaining Term": "187",
    },
    {
      "FICO Score": "776 - 800",
      Count: 589,
      "$ Aggregate": "31132397.38",
      "% Aggregate": "10.60",
      "$ Average": "52856.36",
      "% Mortgage Net Interest Rate": "7.19",
      "% Initial Combined Loan-to-Value Ratio": "71.24",
      "% DTI": "33.40",
      "Credit Score": "787",
      "Remaining Term": "196",
    },
    {
      "FICO Score": "801 - 825",
      Count: 300,
      "$ Aggregate": "17034518.92",
      "% Aggregate": "5.80",
      "$ Average": "56781.73",
      "% Mortgage Net Interest Rate": "6.65",
      "% Initial Combined Loan-to-Value Ratio": "67.31",
      "% DTI": "29.63",
      "Credit Score": "812",
      "Remaining Term": "200",
    },
    {
      "FICO Score": "826 - 850",
      Count: 197,
      "$ Aggregate": "10602952.12",
      "% Aggregate": "3.61",
      "$ Average": "53822.09",
      "% Mortgage Net Interest Rate": "6.36",
      "% Initial Combined Loan-to-Value Ratio": "63.81",
      "% DTI": "31.33",
      "Credit Score": "839",
      "Remaining Term": "197",
    },
    {
      "FICO Score": "Total:",
      Count: "6617",
      "$ Aggregate": "293819840.92",
      "% Aggregate": "100.00",
      "$ Average": "44403.78",
      "% Mortgage Net Interest Rate": "8.48",
      "% Initial Combined Loan-to-Value Ratio": "67.55",
      "% DTI": "32.04",
      "Credit Score": "727",
      "Remaining Term": "185",
    },
  ],
};
